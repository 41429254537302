import { values } from 'ramda';
import ooeConstants from '../constants';

/* eslint-disable import/prefer-default-export */
export const debounce = (fn, time) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function (...args) {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const camelToTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const maskEmail = (email) => {
  const mask = '*****';
  try {
    const idx = email.indexOf('@');
    if (idx < 0) {
      return mask;
    }
    if (idx < 4) {
      return `${mask}${email.substr(idx)}`;
    }
    return `${email.substr(0, 1)}${mask}${email.substr(idx - 1, 1)}${email.substr(idx)}`;
  } catch (e) {
    return undefined;
  }
};

export const stripNonPOSSpecialCharacters = (value) => value && value.replace(ooeConstants.POS_INVALID_CHARS_REGEX, '');

// eslint-disable-next-line prefer-template
export const roundNumber = (num, decimalPlaces = 2) => +(Math.round(num + `e+${[decimalPlaces]}`) + `e-${decimalPlaces}`);

export const specialInstrHasPaperGoods = (specialInstructions = '') => ooeConstants.PAPER_GOODS_MESSAGES.some(option => specialInstructions?.includes(option));

export const isObjWithEmptyStrings = (obj = {}) => values(obj).every(value => value === '');
