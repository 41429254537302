import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Input } from '@cfacorp/cowponents';

function ValidatedField(props) {
  const {
    type,
    placeholder,
    meta,
    onKeyUp,
    input,
    maxLength,
    pattern,
    disabled,
    requiredWithoutTouch,
  } = props;
  let inputClassName = '';
  const { touched, error } = meta;

  if ((touched && error) || (requiredWithoutTouch && error)) {
    inputClassName = 'error-input';
  } else if (disabled) {
    inputClassName = 'disabled-input';
  } else {
    inputClassName = 'valid-input';
  }
  return (
    <StyledValidatedField>
      <Input
        {...input}
        className={inputClassName}
        disabled={disabled}
        maxLength={maxLength}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        type={type}
        pattern={pattern}
        autoComplete="nope"
      />
      {(touched || requiredWithoutTouch) && (error && <span className="error-message">{error}</span>)}
    </StyledValidatedField>
  );
}

const StyledValidatedField = styled.div`
  & input {
    width: calc(100% - 14px);
    box-shadow: inset 0 0 0px 9999px ${(props) => props.theme.secondary};
  }
  & input::placeholder {
    color: ${(props) => props.theme.outline};
    -webkit-text-fill-color: ${(props) => props.theme.outline};
  }
  & input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.text};
  }
  & .disabled-input {
    color: ${(props) => props.theme.outline};
    box-shadow: inset 0 0 0px 9999px ${(props) => props.theme.background};
  }
  & .disabled-input:-webkit-autofill {
   -webkit-text-fill-color: ${(props) => props.theme.outline};
  }
  & .error-input {
    border: 2px solid ${(props) => props.theme.error};
  }
  & .error-message {
    color: ${(props) => props.theme.error};
    font-size: 12px;
    font-weight: bold;
    float: left;
    margin-left: 12px;
  } 
`;

ValidatedField.propTypes = {
  input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func,
  maxLength: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  requiredWithoutTouch: PropTypes.bool,
};

ValidatedField.defaultProps = {
  onKeyUp: () => {},
  maxLength: null,
  pattern: '',
  onChange: () => {},
  value: '',
  disabled: false,
  requiredWithoutTouch: false,
};

export default ValidatedField;
