/* eslint-disable react/forbid-prop-types */
import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Field, reduxForm } from 'redux-form';
import {
  Box, Button, Spinner, SectionHeader, Flex,
} from '@cfacorp/cowponents';
import constants from '../../constants';
// eslint-disable-next-line import/no-cycle
import { Radio, PastOrders, Icon } from '../index';

export class SuggestedCustomers extends Component {
  constructor() {
    super();
    this.updateGuestInfo = this.updateGuestInfo.bind(this);
  }

  updateGuestInfo(guest) {
    const { change, guestSelected } = this.props;
    change('phone', guest.phone);
    change('email', guest.email);
    change('first', guest.firstName);
    change('last', guest.lastName);
    guestSelected(guest);
  }

  unselect() {
    const { destroy, masqueradeGuestUnselected } = this.props;
    destroy();
    masqueradeGuestUnselected();
  }

  render() {
    const {
      customer,
      cfaOneGuest,
      index,
      pastAndFavoritesLoading,
      addToFavorites,
      pastAndFavoriteOrders,
      removeFromFavorites,
      favoritesLoading,
      favoritesError,
      resetFavoriteActionErrors,
      updateFavoriteName,
      favoriteActionSuccessful,
      pastOrFavoriteError,
      initiateEditOrder,
      suggestedFavoriteName,
      currentFavoriteNames,
      loadingMore,
      loadMorePastOrders,
      noMorePastOrdersToLoad,
    } = this.props;
    const {
      phone,
      firstName,
      lastName,
      email,
      cfaId,
    } = customer;
    const guestMatch = (cfaOneGuest === cfaId);
    const hasPastOrFavoriteOrders = (pastAndFavoriteOrders.length > 0);

    const renderUnselectButton = () => (
      <>
        <Button p="0.5em 1em" m="0.5em" fontSize={2} onClick={() => this.unselect()}>Unselect</Button>
        {!pastAndFavoritesLoading && !hasPastOrFavoriteOrders && (
        <div className="no-orders-disclaimer">
          *No Past Catering Orders
        </div>
        )}
      </>
    );

    const renderLoadMore = () => {
      let loadMore = null;
      if (loadingMore) {
        loadMore = <Spinner />;
      } else if (noMorePastOrdersToLoad) {
        loadMore = <div className="no-more">No more past orders to load</div>;
      } else if (!noMorePastOrdersToLoad && hasPastOrFavoriteOrders) {
        loadMore = <button className="load-more" onClick={loadMorePastOrders}>Load More</button>;
      }
      return loadMore;
    };

    const renderOrders = () => {
      if (pastAndFavoritesLoading) {
        return (
          <>
            <div className="past-orders-title">{`Loading ${firstName} ${lastName}'s Past Catering Orders`}</div>
            <Spinner />
          </>
        );
      }
      if (!pastAndFavoritesLoading && pastOrFavoriteError) {
        return (
          <div className="error-wrapper">
            <Icon>error</Icon>
            <div className="error-disclaimer">Uh oh! There was a problem retrieving Past and Favorite Orders. Please un-select the guest and try again, or contact support.</div>
          </div>
        );
      }
      return (
        <>
          <PastOrders
            addToFavorites={addToFavorites}
            pastAndFavoriteOrders={pastAndFavoriteOrders}
            removeFromFavorites={removeFromFavorites}
            updateFavoriteName={updateFavoriteName}
            favoritesLoading={favoritesLoading}
            favoritesError={favoritesError}
            favoriteActionSuccessful={favoriteActionSuccessful}
            resetFavoriteActionErrors={resetFavoriteActionErrors}
            initiateEditOrder={initiateEditOrder}
            customer={customer}
            suggestedFavoriteName={suggestedFavoriteName}
            currentFavoriteNames={currentFavoriteNames}
          />
          <Flex justifyContent="center">{renderLoadMore()}</Flex>
          {hasPastOrFavoriteOrders && <div className="past-orders-disclaimer">*Showing favorite and most recent orders</div>}
        </>
      );
    };

    return (
      <>
        <StyledSuggestedCustomers>
          {/* eslint-disable-next-line */}
          <label htmlFor={cfaId} style={{ position: 'relative' }}>
            <div className="results-group">
              <Field
                className="radio"
                name="cfaId"
                component={Radio}
                id={cfaId}
                label=""
                checked={guestMatch}
                onChange={() => this.updateGuestInfo(customer)}
              />
              <div className="results">
                <div className="name" data-cy={`suggested-${firstName.toLowerCase()}-${lastName.toLowerCase()}-${index}`}>{`${firstName} ${lastName}`}</div>
                <div className="email">{email}</div>
                <div className="phone">{phone}</div>
              </div>
            </div>
            <div className="unselect">{ guestMatch && renderUnselectButton() }</div>
          </label>
          { guestMatch && renderOrders() }
          <SectionHeader className="divider" />
        </StyledSuggestedCustomers>
      </>
    );
  }
}

/* istanbul ignore next */
const StyledSuggestedCustomers = styled(Box)`
  & label {
    display: flex;
    align-items: center;
    height: 75px;
  }
  & .results-group {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  & .radio {
    flex: 1;
    text-align: center;
  }
  & .results {
    flex: 6;
    display: flex;
  }
  & .name, .phone {
    flex: 2;
  }
  & .email  {
    flex: 3;
    padding: 0 15px;
  }
  & label:hover {
    cursor: pointer;
  }
  & .unselect {
    width: 110px;
  }
  & .divider {
    color: red;
    margin: 0;
    padding: 0;
  }
  & .past-orders-title {
    text-align: center;
    font: ${(props) => props.theme.regularBoldFont};
    margin: 20px;
  }
  & .past-orders-disclaimer {
    margin: 15px;
    text-align: right;
    font: ${(props) => props.theme.smallTextFont};
  }
  & .load-more {
    color: ${(props) => props.theme.primary};
    font: ${(props) => props.theme.regularBoldFont};
    background: none;
    border: none;
  }
  & .no-more {
    font: ${(props) => props.theme.regularBoldFont};
  }
  & .load-more:hover {
    cursor: pointer;
    filter: brightness(150%);
  }
  & .error-disclaimer {
   font: ${(props) => props.theme.smallTextFont};
  }
  & .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  & .error-wrapper svg {
    margin: 0 5px 3px 0;
    max-height: 18px;
    max-width: 18px;
  }
  & .no-orders-disclaimer {
    font: ${(props) => props.theme.smallTextFont};
    width: 140px;
    text-align: left;
    margin-left: -40px;
  }
  @media (max-width: 650px) {
    & label {
      height: 85px;
    }
    & .radio {
      margin: 0 20px;
    }
    & .results {
      display: block;
    }
    & .email {
      padding: 0;
    }
  }
`;

SuggestedCustomers.propTypes = {
  customer: PropTypes.objectOf(PropTypes.string),
  change: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  guestSelected: PropTypes.func.isRequired,
  cfaOneGuest: PropTypes.string,
  masqueradeGuestUnselected: PropTypes.func,
  index: PropTypes.number.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  updateFavoriteName: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  favoriteActionSuccessful: PropTypes.bool,
  initiateEditOrder: PropTypes.func.isRequired,
  pastAndFavoriteOrders: PropTypes.arrayOf(
    PropTypes.object,
  ),
  pastAndFavoritesLoading: PropTypes.bool,
  loadMorePastOrders: PropTypes.func,
  loadingMore: PropTypes.bool,
  noMorePastOrdersToLoad: PropTypes.bool,
  pastOrFavoriteError: PropTypes.bool,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
  resetFavoriteActionErrors: PropTypes.func,
};

SuggestedCustomers.defaultProps = {
  masqueradeGuestUnselected: () => {},
  resetFavoriteActionErrors: () => {},
  customer: {},
  cfaOneGuest: '',
  pastAndFavoriteOrders: [],
  favoritesLoading: false,
  favoritesError: null,
  favoriteActionSuccessful: false,
  pastAndFavoritesLoading: false,
  pastOrFavoriteError: false,
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
  loadMorePastOrders: () => {},
  loadingMore: false,
  noMorePastOrdersToLoad: false,
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.GUEST,
  destroyOnUnmount: false,
})(SuggestedCustomers);
