/* eslint-disable no-unused-expressions */
import moment from 'moment';
import ooeConstants from '../constants';
import ReduxStore from '../util/reduxStore';
import { logAmplitudeEvent, setAmplitudeUser } from './amplitude';
import {
  selectOrderForAPI,
  selectDeliveryMinNotMet,
  selectPickupMinNotMet,
  selectExceedsMaxOrderTotal,
  selectExceedsSameDayMaxOrderTotal,
  selectSubtotalSubmitWarning,
  selectLeadTimeWarning,
  selectPromoFreeItemsValue,
} from '../reducers';
import {
  selectUser,
  selectBypassBusinessRules,
  isAdminUser,
  isVcaUser,
  selectUserCfaGuid,
  selectLocationNumber,
  selectCmtPerm,
  selectLocationTimezone,
  selectUserHasAgreedToEula,
  selectIsUserLoggedIn,
  doesLocationNumberExist,
  isDeveloperAudience,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectOrderMaxFutureDayCountDelivery,
  selectOrderMaxFutureDayCountPickup,
  selectDeliveryRange,
  selectDeliveryLeadTimeStoreInfo,
  selectPickupLeadTimeStoreInfo,
  selectInStateDeliveryOnly,
  selectCmtPerms,
  selectUserName,
  selectUserEmail,
} from '../reducers/user';
import {
  selectPromoFreeItemCount,
} from '../reducers/cart';
import {
  selectGuest,
  selectIsCfaOneGuestSelected,
  selectFavoriteOrders,
  selectPastOrdersLength,
  selectCfaOneGuestId,
  selectVaultedCards,
} from '../reducers/guest';
import {
  selectEditMode,
  selectDestination,
  selectDeliveryAddressValid,
  selectDestinationValid,
  selectEditOrderId,
  selectDeliveryRangeWarning,
  selectReorder,
  selectTaxAmount,
  selectSubTotalAmount,
  selectDeliveryTipAmount,
  selectSelectedPercentageTipValue,
} from '../reducers/order';
import {
  selectNewPaymentMethod,
  selectPaymentForm,
  selectDateIsToday,
} from '../reducers/form';
import { notifyBugsnag } from './bugsnag';
import { roundNumber } from '../util/utils';

export const createSubmitOrderAnalytics = (state) => {
  try {
    const now = moment.now();
    const order = selectOrderForAPI(state);
    const user = selectUser(state);
    const paymentForm = selectPaymentForm(state);
    const guest = selectGuest(state);

    const analytics = {
      // top level selectors
      deliveryMinNotMet: selectDeliveryMinNotMet(state),
      pickupMinNotMet: selectPickupMinNotMet(state),
      exceedsMaxOrderTotal: selectExceedsMaxOrderTotal(state),
      exceedsSameDayMaxOrderTotal: selectExceedsSameDayMaxOrderTotal(state),
      subtotalSubmitWarning: selectSubtotalSubmitWarning(state),
      leadTimeWarning: selectLeadTimeWarning(state),

      // order
      requestingNewPaymentMethod: selectNewPaymentMethod(state),
      isCfaOneGuestSelected: selectIsCfaOneGuestSelected(state),
      isEditingOrder: selectEditMode(state),
      editOrderId: selectEditOrderId(state),
      orderId: order.id,
      companyName: order.companyName,
      destination: selectDestination(state),
      deliveryAddressValid: selectDeliveryAddressValid(state),
      destinationValid: selectDestinationValid(state),
      taxAmount: selectTaxAmount(state),
      subTotalAmount: selectSubTotalAmount(state),
      totalAmount: roundNumber(selectTaxAmount(state) + selectSubTotalAmount(state) + selectDeliveryTipAmount(state)),
      deliveryRangeWarning: selectDeliveryRangeWarning(state),
      reorder: selectReorder(state),
      promiseDateTime: order.promiseDateTime,
      hoursUntilPromisedDateTime: roundNumber(moment.duration(moment(order.promiseDateTime).diff(now)).asHours()),
      daysUntilPromisedDateTime: roundNumber(moment.duration(moment(order.promiseDateTime).diff(now)).asDays()),
      paperGoods: order.paperGoods,
      specialInstructions: order.specialInstructions,
      guestCount: order.guestCount,
      businessDate: order.businessDate,
      orderLocationNumber: order.locationNumber,
      bypassBusinessRulesList: order.bypassBusinessRulesList,
      orderType: order.orderType,
      deliveryTipAmount: selectDeliveryTipAmount(state),
      deliveryTipPercentOfSale: selectSelectedPercentageTipValue(state),

      //cart
      promoFreeItemCount: selectPromoFreeItemCount(state),
      promoFreeItemsValue: selectPromoFreeItemsValue(state),

      // user
      locationNumber: selectLocationNumber(state),
      bypassBusinessRules: selectBypassBusinessRules(state),
      cmtPerm: selectCmtPerm(state),
      perms: selectCmtPerms(state),
      userCfaGuid: selectUserCfaGuid(state),
      audience: user.cfa_aud,
      isAdmin: isAdminUser(state),
      isVca: isVcaUser(state),
      isDeveloper: isDeveloperAudience(user.cfa_aud),
      isUserLoggedIn: selectIsUserLoggedIn(state),
      locationTimezone: selectLocationTimezone(state),
      userHasAgreedToEula: selectUserHasAgreedToEula(state),
      locationNumberExists: doesLocationNumberExist(state, order.locationNumber),
      locationAcceptsDelivery: selectLocationAcceptsDelivery(state),
      locationAcceptsPickup: selectLocationAcceptsPickup(state),
      minDeliveryAmount: selectMinDeliveryAmount(state) / 100,
      minPickupAmount: selectMinPickupAmount(state) / 100,
      maxDeliveryAmount: selectMaxDeliveryAmount(state) / 100,
      maxPickupAmount: selectMaxPickupAmount(state) / 100,
      maxSameDayOrderTotalAmountCents: selectMaxSameDayOrderTotalAmountCents(state) / 100,
      orderMaxFutureDayCountDelivery: selectOrderMaxFutureDayCountDelivery(state),
      orderMaxFutureDayCountPickup: selectOrderMaxFutureDayCountPickup(state),
      deliveryRange: selectDeliveryRange(state),
      deliveryLeadTimeMinutes: selectDeliveryLeadTimeStoreInfo(state),
      pickupLeadTimeMinutes: selectPickupLeadTimeStoreInfo(state),
      inStateDeliveryOnly: selectInStateDeliveryOnly(state),

      //form
      orderedSameDay: selectDateIsToday(state),
      paymentMethod: paymentForm?.values?.selectedMethod,

      //guest
      favoriteOrdersCount: selectFavoriteOrders(state)?.length,
      pastOrdersCount: selectPastOrdersLength(state),
      cfaOneGuestId: selectCfaOneGuestId(state),
      vaultedCardsCount: selectVaultedCards(state)?.length || 0,
      guestSessionActive: !!guest.guestSession?.accessToken,
      masqueradeSessionActive: !!guest.masqueradeSession?.accessToken,
    };

    return analytics;
  } catch (error) {
    notifyBugsnag('Create Submit Order Analytics Failed', {
      context: 'Order Analytics',
      info: error,
    });
    return {
      error: `Unable to create order analytics -  ${error}`,
    };
  }
};

const getUserInfo = () => {
  const { state } = ReduxStore;
  return {
    isVca: isVcaUser(state) ?? false,
    isAdmin: isAdminUser(state) ?? false,
    userCfaGuid: selectUserCfaGuid(state) ?? '',
    cmtPerms: selectCmtPerm(state) ?? '',
    name: selectUserName(state) ?? '',
    email: selectUserEmail(state) ?? '',
    bypassBusinessRules: selectBypassBusinessRules(state) ?? '',
    locationNumber: selectLocationNumber(state) ?? '',
    appVersion: ooeConstants.APP_VERSION,
  };
};

export function logEvent(event, payload = {}) {
  const userInfo = getUserInfo();
  logAmplitudeEvent(event, { ...payload, ...userInfo });
}

export function simplifyPage(page = '') {
  if (page?.startsWith('more/')) {
    return page.substring(4);
  }
  return page;
}
export function logPageView(page) {
  const userInfo = getUserInfo();
  const simplifiedPage = simplifyPage(page);
  logAmplitudeEvent(`[View Page] ${simplifiedPage}`, { page: simplifiedPage, ...userInfo });
}

export function setUserLocation(locationNumber) {
  const userInfo = getUserInfo();
  logAmplitudeEvent('[Set User Location]', { locationNumber, ...userInfo });
}

export function setUser(cfaGuid) {
  const userInfo = getUserInfo();
  setAmplitudeUser(cfaGuid, userInfo);
}
