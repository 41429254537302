/*eslint-disable*/
import PropTypes from 'prop-types';
import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
import ooe from '../../constants';
import { isEmpty } from 'ramda';
import {
  formatEventSummaryDate,
  formatEventSummaryTime,
  formatCity,
  formatField,
  formatGuests,
  formatEmail,
  formatPhone,
  formatPaper,
} from '../../util/format';
import { formatPhoneNumber } from '../../reducers/form'
import Tooltip from '../Tooltip/Tooltip';
import { specialInstrHasPaperGoods } from '../../util/utils';

function EventSummary({
  className,
  destination,
  guestDetails,
  eventDetails,
  deliveryAddress,
  eventSummaryValid,
  addressValidated,
  restaurantContactDetails,
  isPOSOrder,
  transId,
  cateringReason,
  isInvoicePage,
  secondaryContactDetails,
  specialInstructionMessage,
  paperGoodsRequired,
  showDefaultPaperGoodsMessage,
}) {
  const {
    first,
    last,
    email,
    phone,
  } = guestDetails;
  const {
    time,
    guestCount,
    paperGoods,
    specialInstructions,
    date,
  } = eventDetails;
  const {
    companyName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
  } = deliveryAddress;
  const {
    restaurantName,
    restaurantAddress1,
    restaurantAddress2,
    restaurantPhone,
  } = restaurantContactDetails;
  const {
    firstName,
    lastName,
    phoneNumber
  } = secondaryContactDetails;
  const { DELIVERY, PICKUP } = ooe;
  const specialInstructionFromAPI = specialInstrHasPaperGoods(specialInstructions) ? specialInstructions : `${formatPaper(paperGoods)}${specialInstructions || ''}`

  const specialInstructionBox = () => {
    if (isPOSOrder) {
      return (
        <Box className="specialInstructionsSummary">
          {specialInstructions || formatPaper(paperGoods)}
        </Box>
      );
    } else if (showDefaultPaperGoodsMessage) {
      return (
        <Box className="specialInstructionsSummary">
          {formatPaper(paperGoods)}{specialInstructions}
        </Box>
      );
    } else {
      return (
        <Box className="specialInstructionsSummary">
          {specialInstructionMessage || specialInstructionFromAPI}
          {paperGoodsRequired && <div className="required">PaperGoods*</div>}
        </Box>
      );
    }
  }

  return (
    <StyledEventSummary className={className}>
      <Flex lineHeight="27px">
        <Flex flexDirection="column" className="f-d-column" width={[1 / 2, 5 / 12]}>
          <Box fontWeight="bold" lineHeight="27px">{destination} Time</Box>
          <Box data-cy="paymentDate">{formatEventSummaryDate(date)}</Box>
          <Box data-cy="paymentTime">{formatEventSummaryTime(time)}</Box>
          <br />
          {(destination === DELIVERY && !isPOSOrder) &&
          <div>
            <Box fontWeight="bold" lineHeight="27px">Delivery Address</Box>
            <Box data-cy="guestAddressName">{companyName}</Box>
            <Box data-cy="guestAddressStreet">{formatField(addressLine1, 'Delivery Address')}</Box>
            <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
            <Box data-cy="guestAddressCityState">{formatCity(city)}{formatField(state, 'State')} {formatField(zip, 'Zip')}</Box>
            { !addressValidated && <Box className="required">Delivery Address Invalid*</Box> }
          </div>}
          {(destination === DELIVERY && isPOSOrder) &&
          <div>
            <Box className="bold">Delivery Address</Box>
            <div>{isEmpty(deliveryAddress) ? '' : deliveryAddress}</div>
          </div>}
          {((destination === PICKUP || isInvoicePage) && restaurantContactDetails) &&
          <div className='retaurant-details'>
            <Box fontWeight="bold" lineHeight="27px">{!isInvoicePage ? 'Pickup Restaurant' : 'Restaurant Details'}</Box>
            <Box data-cy="restaurantName">{restaurantName}</Box>
            <Box data-cy="restaurantAddress">{restaurantAddress1}</Box>
            <Box data-cy="restaurantCityStateZip">{restaurantAddress2}</Box>
            <Box data-cy="restaurantPhone">{restaurantPhone}</Box>
          </div>}
          {transId && 
            <div>
                <Box fontWeight="bold" lineHeight="27px">TransactionID</Box>
                <Tooltip
                  className="transId-tooltip" 
                  tooltipContent={(
                    <div>
                      <span className='title'>Transaction ID:</span><span> Year + Month + Day + Store Num + Transaction Number</span>
                    </div>
                )}>
                  <Box data-cy="trans-id" className="trans-id">{transId}</Box>
                </Tooltip>
            </div>
          }
          {Object.keys(secondaryContactDetails).length > 0 && (
            <div className='secondary-contact-details'>
              <br />
              <Box fontWeight="bold" lineHeight="27px">{`Secondary ${destination} POC:`}</Box>
              <Box lineHeight="27px" className="first-and-last-name">{firstName} {lastName}</Box>
              <Box className="guestPhone secondary-contact-phone">{formatPhoneNumber(phoneNumber)}</Box>
            </div>
          )}
        </Flex>
        <Flex justifyContent={['space-between', 'initial']} flexDirection="column" className="f-d-column" width={[1 / 2, 7 / 12]}>
          <Box fontWeight="bold" lineHeight="27px">Primary Contact</Box>
          <Box lineHeight="27px" data-cy="guestSummaryFullName">{formatField(first, 'First Name')} {formatField(last, 'Last Name')}</Box>
          {!isPOSOrder && <Box data-cy="guestSummaryEmail" className="email">{formatEmail(email, 'Email Address')}</Box>}
          <Box data-cy="guestSummaryPhone" className="guestPhone">{isPOSOrder ? phone: formatPhone(phone, 'Phone Number')}</Box>
          <br />
          <Box lineHeight="27px" data-cy="guestCountSummary">{formatGuests(guestCount)}</Box>
          <Box fontWeight="bold" lineHeight="27px">Special Instructions:</Box>
          {specialInstructionBox()}
          <br />
          {cateringReason !== '' &&
            <div className='catering-reason'>
              <Box fontWeight="bold" lineHeight="27px">Catering Occasion / Reason:</Box>
              <Box>{cateringReason}</Box>
            </div>}
        </Flex>
      </Flex>
      {!eventSummaryValid &&
        <Box className="required required-field">*Required Field</Box> }
    </StyledEventSummary>
  );
}

const StyledEventSummary = styled.div`
  font: ${props => props.theme.regularTextFont};
  & .clearfix {
    clear: both;
  }
  & .required {
    font: ${props => props.theme.regularBoldFont};
    color: ${props => props.theme.error};
    line-height: 27px;
  }
  & .required-field {
    text-align: left;
    margin-top: 25px;
  }
  & .bold {
    font: ${props => props.theme.regularBoldFont};
  }  
  & .tooltip-wrapper {
    justify-content: flex-start;
    & .tooltip {
      top: 28px;
      width: max-content;
      max-width: 80vw;
      .title {
        font-weight: bold;
      }
    }
  }

`;

EventSummary.propTypes = {
  className: PropTypes.string,
  eventDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  destination: PropTypes.string,
  guestDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  secondaryContactDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  deliveryAddress: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  eventSummaryValid: PropTypes.bool,
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  addressValidated: PropTypes.bool,
  isPOSOrder: PropTypes.bool,
  cateringReason: PropTypes.string,
  isInvoicePage: PropTypes.bool,
  specialInstructionMessage: PropTypes.string,
  eventDetailsData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  isEditMode: PropTypes.bool,
  paperGoodsRequired: PropTypes.bool,
  showDefaultPaperGoodsMessage: PropTypes.bool,
};

EventSummary.defaultProps = {
  className: '',
  eventDetails: {},
  deliveryAddress: {},
  guestDetails: {},
  eventSummaryValid: false,
  addressValidated: false,
  isPOSOrder: false,
  destination: '',
  restaurantContactDetails: {},
  cateringReason: '',
  secondaryContactDetails: {},
  isInvoicePage: false,
  specialInstructionMessage: '',
  eventDetailsData: {},
  isEditMode: false,
  paperGoodsRequired: false,
  showDefaultPaperGoodsMessage: false,
};

export default EventSummary;
