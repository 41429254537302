/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import { CartLink, UserLocation } from '../index';

function RightDesktop({
  icon,
  cartItemCount,
  total,
  loading,
  userName,
  locations,
  updateLocation,
  selectedLocation,
  logoutUser,
  toggleBypass,
  shouldBypassBusinessRules,
  isAdminUser,
  locationDropdownDisabled,
  lookupLocation,
  allStoreNamesAndNumbers,
  defaultLocation,
}) {
  return (
    <StyledRightDesktop>
      <div className="user-location">
        <div className="user">
          {`${userName} `}
          (
          <button className="sign-out" onClick={logoutUser}>sign out</button>
          )
        </div>
        {
          isAdminUser
          && (
          <div className="bbr">
            Bypass Business Rules:
            {' '}
            <button onClick={toggleBypass}>{shouldBypassBusinessRules ? 'On' : 'Off'}</button>
          </div>
          )
        }
        <UserLocation
          locations={locations}
          updateLocation={updateLocation}
          selectedLocation={selectedLocation}
          locationDropdownDisabled={locationDropdownDisabled}
          lookupLocation={lookupLocation}
          allStoreNamesAndNumbers={allStoreNamesAndNumbers}
          defaultLocation={defaultLocation}
        />
      </div>
      <CartLink
        icon={icon}
        cartItemCount={cartItemCount}
        total={total}
        loading={loading}
        dataCy="cart-badge"
      />
    </StyledRightDesktop>
  );
}

const StyledRightDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
  
  & .user-location {
    font: ${(props) => props.theme.smallTextFont};
    color: ${(props) => props.theme.text};
  }
  & .user {
    padding: 0 7px;
  }  
  & .bbr {
    padding: 0 7px;
    button {
      cursor: pointer;
      font: ${(props) => props.theme.regularBoldFont};
      color: ${(props) => props.theme.primary};
    }
  }
  & .sign-out {
    color: ${(props) => props.theme.primary};
    border: none;
    background-color: ${(props) => props.theme.background};
    padding: 2px;
  }
  & .sign-out:hover {
    cursor: pointer;
  }
`;

RightDesktop.propTypes = {
  icon: PropTypes.string.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  total: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
  selectedLocation: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  toggleBypass: PropTypes.func.isRequired,
  shouldBypassBusinessRules: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  locationDropdownDisabled: PropTypes.bool,
  allStoreNamesAndNumbers: PropTypes.arrayOf(PropTypes.object),
  defaultLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RightDesktop.defaultProps = {
  updateLocation: () => {},
  lookupLocation: () => {},
  selectedLocation: '',
  locationDropdownDisabled: false,
  allStoreNamesAndNumbers: [],
  defaultLocation: false,
};

export default RightDesktop;
