import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import { Icon } from '../index';

const POSWarning = () => (
  <StyledPOSWarning>
    <Icon className="icon">error</Icon>
    <div>This order was placed on the Restaurant Point of Sale (POS).</div>
    <div>
      Please refer to the Deferred Order Queue on the POS to view and make changes to this order.
    </div>
  </StyledPOSWarning>
);

const StyledPOSWarning = styled.div`
  font: ${props => props.theme.regularTextFont};
  color: ${props => props.theme.text};
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  
  & .icon {
    width: 25px;
  }
`;

export default POSWarning;
