import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { Button, Section } from '@cfacorp/cowponents';
import { destroy } from 'redux-form';
import PropTypes from 'prop-types';
import { Icon, FavoriteOrder, Tooltip } from '../components';
import { formatLongDate, formatPrice } from '../util/format';
import {
  actions,
  selectCurrentFavoriteNames,
  selectFavoriteActionSuccessful,
  selectFavoritesError,
  selectFavoritesLoading,
  selectFavoriteSuggestedName,
} from '../reducers/guest';
import constants from '../constants';
import { selectTotalAmount, selectTransactionId } from '../reducers/order';
import { notifyBugsnag } from '../services/bugsnag';

export class Submit extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false, isFavorite: false };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.sentBugsnagNotify = false;
  }

  componentDidMount() {
    const { destroy: destroyForm } = this.props;
    destroyForm(
      constants.GET_FORM_TYPES.GUEST,
      constants.GET_FORM_TYPES.DETAILS,
      constants.GET_FORM_TYPES.PAYMENT_METHOD,
      constants.GET_FORM_TYPES.SECONDARY_CONTACT,
    );
  }

  componentWillUnmount() {
    const { clearGuestState } = this.props;
    clearGuestState();
  }

  openModal() { this.setState({ modalIsOpen: true }); }

  closeModal({ favoriteActionSuccessful }) {
    const { resetFavoriteActionErrors } = this.props;
    this.setState({ modalIsOpen: false, isFavorite: favoriteActionSuccessful });
    // Reset action errors so user can try again when modal is re-opened
    resetFavoriteActionErrors();
  }

  render() {
    const {
      className,
      location,
      history,
      addToFavorites,
      favoritesLoading,
      favoritesError,
      addToFavoritesSuccessful,
      suggestedFavoriteName,
      currentFavoriteNames,
      transactionId,
    } = this.props;
    const { modalIsOpen, isFavorite } = this.state;
    const { state: locationState } = location;
    // istanbul ignore if
    if (!locationState) {
      history.push('/');
      return null;
    }
    const { order, guestName, isCfaOneGuest } = locationState;
    const editOrder = pathOr('', ['error', 'editOrder'], order);

    // on rare occasions we have seen order not exist in the incoming state
    // which caused exception/crash (consequences unknown)
    // to better diagnose this, we submit empty data for display and notify bugsnag
    const {
      subTotalAmount,
      taxAmount,
      destination,
      promiseDateTime,
      id,
      totalAmount,
    } = order ?? {
      subTotalAmount: 0,
      taxAmount: 0,
      destination: '',
      promiseDateTime: 0,
      id: '',
      totalAmount: 0,
    };

    if (!order && !this.sentBugsnagNotify) {
      notifyBugsnag('Missing order details on Submit view', {
        context: 'Submit View Missing Order Details',
        info: {
          order,
        },
      });
      this.sentBugsnagNotify = true;
    }

    return (
      <div className={className}>
        <Section>
          <Icon>celebrate</Icon>
          <h3>{`Order ${editOrder ? 'Updated' : 'Placed'} Successfully`}</h3>
        </Section>
        <Section className="summary">
          <p>
            {`${destination} Order #`}
            <span data-cy="order-id">{id}</span>
            {` for ${guestName}`}
          </p>
          <p data-cy="promise-date">{`Promise date: ${formatLongDate(promiseDateTime)}`}</p>
          <p data-cy="final-total">{`Total: ${totalAmount !== 0 ? formatPrice(totalAmount) : formatPrice(subTotalAmount + taxAmount)}`}</p>
          {transactionId && (
            <Tooltip
              className="transId-tooltip"
              tooltipContent={(
                <div>
                  <span className="title">Transaction ID:</span>
                  <span> Year + Month + Day + Store Num + Transaction Number</span>
                </div>
              )}
            >
              <p data-cy="trans-id">{`TransactionID: ${transactionId}`}</p>
            </Tooltip>
          )}
        </Section>
        <Section>
          {isFavorite && (
            <>
              <Icon width="20px" height="20px" margin="5px">favorite</Icon>
              <div className="favorite-message">{`This order has been added to ${guestName}'s Favorites`}</div>
            </>
          )}
          <Button
            fontSize={2}
            p="0.5em 1em"
            m="0.5em"
            onClick={() => { history.push('/'); }}
          >
            Start New Order
          </Button>
          {
            isCfaOneGuest
            && !isFavorite
            && id
            && (
            <Button
              fontSize={2}
              p="0.5em 1em"
              m="0.5em"
              onClick={this.openModal}
            >
              Add to Favorites
            </Button>
            )
          }
        </Section>
        { modalIsOpen
        && isCfaOneGuest
        && id
        && (
          <FavoriteOrder
            close={this.closeModal}
            isOpen={modalIsOpen}
            isFavorite={isFavorite}
            guestName={guestName}
            addToFavorites={(favoriteName) => addToFavorites(id, favoriteName)}
            favoritesLoading={favoritesLoading}
            favoritesError={favoritesError}
            favoriteActionSuccessful={addToFavoritesSuccessful}
            suggestedFavoriteName={suggestedFavoriteName}
            currentFavoriteNames={currentFavoriteNames}
          />
        )}
      </div>
    );
  }
}

export const StyledSubmit = styled(Submit)`
  color: ${(props) => props.theme.primary};
  text-align: center;
  & svg {
    fill: ${(props) => props.theme.primary};
  }
  & .summary p {
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: initial;
  }
  & .favorite-message {
    margin: 0 0 10px;
  }
  & .tooltip {
    top: 22px !important;
    left: unset !important;
    max-width: 80vw;
    .title {
      font-weight: bold;
    }
  }
`;

Submit.propTypes = {
  className: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.func,
  ])).isRequired,
  destroy: PropTypes.func.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  resetFavoriteActionErrors: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  addToFavoritesSuccessful: PropTypes.bool,
  clearGuestState: PropTypes.func.isRequired,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
  transactionId: PropTypes.string,
};

Submit.defaultProps = {
  favoritesLoading: false,
  favoritesError: null,
  addToFavoritesSuccessful: false,
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
  transactionId: '',
};

function mapStateToProps(state) {
  return {
    favoritesLoading: selectFavoritesLoading(state),
    favoritesError: selectFavoritesError(state),
    addToFavoritesSuccessful: selectFavoriteActionSuccessful(state),
    suggestedFavoriteName: selectFavoriteSuggestedName(state),
    currentFavoriteNames: selectCurrentFavoriteNames(state),
    transactionId: selectTransactionId(state),
    totalAmountFromApi: selectTotalAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ destroy, ...actions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StyledSubmit);
