/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Flex,
   SegmentButton, SegmentGroup,
} from '@cfacorp/cowponents';
import PaperGoodsOptions from './PaperGoodsOptions';
import PaperGoodsBadges from './PaperGoodsBadges';
import Icon from '../Icon';
import Tooltip from '../Tooltip/Tooltip';

const PaperGoods = ({
  setPaperGoodsOptions,
  paperGoodsOptions,
  paperGoodsYesOrNo,
  isEditMode,
  isCMTOrder,
  paperGoodsBool,
}) => {
  let selectPaperGoodsYesOrNo = paperGoodsYesOrNo;
  const [paperGoodsOptionsExpanded, setExpandPaperGoods] = useState(false);
  const onPaperGoodsHandler = (event) => {
    if (event.target.value === 'yes') {
      setExpandPaperGoods(prev => !prev);
    } else {
      setPaperGoodsOptions('', false, true);
      setExpandPaperGoods(false);
    }
  };
  if (isEditMode && !isCMTOrder) {
    selectPaperGoodsYesOrNo = paperGoodsBool ? 'yes' : 'no';
  }
  const onCollapsehandler = () => !isEditMode && setExpandPaperGoods(prev => !prev);
  const paperGoodsSegmentButtons = (
    (isEditMode && isCMTOrder)
    ? (
      <Tooltip
        className="tooltip-box"
        tooltipContent={(
          <div>
            <span className="title">Please Edit Paper Goods in Special Instructions Field</span>
          </div>
        )}
      >
        <SegmentGroup style={{ paddingTop: '0' }} onChange={onPaperGoodsHandler}>
          <StyledSegmentButton key="no" disabled={isEditMode} value="no">No</StyledSegmentButton>
          <StyledSegmentButton key="yes" disabled={isEditMode} value="yes">Yes</StyledSegmentButton>
        </SegmentGroup>
      </Tooltip>
    )
    : (
      <SegmentGroup style={{ paddingTop: '0' }} onChange={onPaperGoodsHandler}>
        <StyledSegmentButton key="no" className={selectPaperGoodsYesOrNo === 'no' && 'selected'} value="no">No</StyledSegmentButton>
        <StyledSegmentButton key="yes" className={selectPaperGoodsYesOrNo === 'yes' && 'selected'} value="yes">Yes</StyledSegmentButton>
      </SegmentGroup>
    )
  );
  return (
    <StyledPaperGoods>
      <Flex alignItems="center" className="paper-goods-navigation">
        <div className="button-box">
          {paperGoodsSegmentButtons}
          <Arrow onClick={onCollapsehandler} rotateArrow={paperGoodsOptionsExpanded}>
            <Icon className="arrow">down</Icon>
          </Arrow>
        </div>
        {!paperGoodsOptionsExpanded && <PaperGoodsBadges setPaperGoodsOptions={setPaperGoodsOptions} paperGoodsOptions={paperGoodsOptions} />}
      </Flex>
      {paperGoodsOptionsExpanded
        && <PaperGoodsOptions setPaperGoodsOptions={setPaperGoodsOptions} paperGoodsOptions={paperGoodsOptions} />}
    </StyledPaperGoods>
  );
};

const StyledSegmentButton = styled(SegmentButton)`
  background-color:${(props) => props.theme.secondary};
  color: ${(props) => props.theme.primary};
  &:disabled {
    background-color:  ${(props) => props.theme.disabled};
    border-color:  ${(props) => props.theme.text};
    color:  ${(props) => props.theme.text};
  }
  &.selected {
    background-color: ${(props) => props.theme.primary};
    color:  ${(props) => props.theme.secondary};
  }
`;

const StyledPaperGoods = styled('div')`
  & .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 40px;
    @media(max-width: 600px) {
      margin-right: 0;
    }
  }
  @media(max-width: 600px) {
    & .paper-goods-navigation {
      flex-direction: column;
    }
  }
`;

const Arrow = styled('span')`
  max-width: 20px;
  max-height: 20px;
  margin: 0;
  background-color: ${(props) => props.theme.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  cursor: pointer;
  & svg {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    fill: ${(props) => props.theme.secondary};
    transform: ${(props) => props.rotateArrow && 'rotateX(180deg)'};
  }
`;

PaperGoods.propTypes = {
  setPaperGoodsOptions: PropTypes.func,
  paperGoodsOptions: PropTypes.objectOf(PropTypes.bool),
  paperGoodsYesOrNo: PropTypes.string,
  isEditMode: PropTypes.bool,
  isCMTOrder: PropTypes.bool,
  paperGoodsBool: PropTypes.bool,
};

PaperGoods.defaultProps = {
  setPaperGoodsOptions: () => {},
  paperGoodsOptions: {},
  paperGoodsYesOrNo: '',
  isEditMode: false,
  isCMTOrder: false,
  paperGoodsBool: false,
};

export default PaperGoods;
