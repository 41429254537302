/* eslint-disable react/forbid-prop-types */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button, Flex, RadioGroup, Spinner,
} from '@cfacorp/cowponents';
import PastDeliveryAddressesItem from './PastDeliveryAddressesItem';

const PastDeliveryAddresses = ({
  getPastDeliveryAddresses,
  pastDeliveryAddresses,
  pastDeliveryAddressesLoading,
  noPastDeliveryAddresses,
  updateUserAddress,
  autocompleteAddress,
}) => {
  const renderLoadDeliveryAddresses = () => {
    let buttonLoad = <Button variant="transparent" className="get-past-addresses" color="primary" onClick={() => getPastDeliveryAddresses()}>Get Past Delivery Addresses</Button>;
    if (pastDeliveryAddressesLoading) {
      buttonLoad = <Spinner />;
    } else if (noPastDeliveryAddresses) {
      buttonLoad = <div className="no-addresses">There are no previously used delivery addresses for the selected guest</div>;
    } else if (pastDeliveryAddresses.length >= 1) {
      buttonLoad = (
        <RadioGroup>
          {pastDeliveryAddresses.map(address => {
            const id = `${address?.addressLine1?.split(' ').join('')}-${address.city}-${address.zip}`;
            return (<PastDeliveryAddressesItem key={id} address={address} updateUserAddress={updateUserAddress} autocompleteAddress={autocompleteAddress} />);
          })}
        </RadioGroup>
      );
    }

    return buttonLoad;
  };
  return (
    <StyledPastDeliveryAddresses justifyContent="center" flexDirection="column">
      {renderLoadDeliveryAddresses()}
    </StyledPastDeliveryAddresses>
  );
};

const StyledPastDeliveryAddresses = styled(Flex)`
  margin-top: 1rem;
  padding: 0 1rem;
  & .no-addresses {
    text-align: center;
  }
`;

PastDeliveryAddresses.propTypes = {
  getPastDeliveryAddresses: PropTypes.func,
  updateUserAddress: PropTypes.func,
  autocompleteAddress: PropTypes.func,
  pastDeliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  pastDeliveryAddressesLoading: PropTypes.bool,
  noPastDeliveryAddresses: PropTypes.bool,
};

PastDeliveryAddresses.defaultProps = {
  getPastDeliveryAddresses: () => {},
  updateUserAddress: () => {},
  autocompleteAddress: () => {},
  pastDeliveryAddresses: [],
  pastDeliveryAddressesLoading: false,
  noPastDeliveryAddresses: false,
};

export default PastDeliveryAddresses;
