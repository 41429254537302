/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-else-return */
import { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { path, pathOr } from 'ramda';
import {
  Flex,
  Box,
  Select,
  Input,
} from '@cfacorp/cowponents';
// eslint-disable-next-line import/no-cycle
import {
  DatePicker, ValidatedField, Disclaimer, Textarea, LeadTimeWarning,
} from '../index';
import { validateDetails, validateGuestCount } from '../../util/validate';
import { generateTimeSlots, formatTimePickerTime } from '../../util/format';
import constants from '../../constants';
import wrapComponentFormField from './wrapComponentFormField';
import { stripNonPOSSpecialCharacters } from '../../util/utils';
import SelectCateringOccasion from '../SelectCateringOccasion/SelectCateringOccasion';

let serviceChannelHours;
let timeSlotsArr = [];

const getTimeSlots = () => timeSlotsArr;

function isInBlackoutWindow(slotTime, endSlotTime, blackoutStart = '00:00', blackoutMinutes = 0) {
  const blackoutStartTime = moment(blackoutStart, 'HH:mm');
  const blackoutEndTime = moment(blackoutStart, 'HH:mm').add(blackoutMinutes, 'minutes');
  return (slotTime.isBefore(blackoutEndTime) && endSlotTime.isAfter(blackoutStartTime));
}

function fillTimeSlotsArr(openTime, endTime, blackoutStart = '00:00', blackoutMinutes = 0) {
  let slotTime = moment(openTime, 'HH:mm');
  const endSlotTime = moment(endTime, 'HH:mm');
  const nextSlot = 15;
  timeSlotsArr = [];
  while (slotTime <= endSlotTime) {
    const currentSlotEndTime = moment(slotTime, 'HH:mm').add(nextSlot, 'minutes');
    if (!isInBlackoutWindow(slotTime, currentSlotEndTime, blackoutStart, blackoutMinutes)) {
      timeSlotsArr.push(slotTime.format('HH:mm'));
    }
    slotTime = slotTime.add(nextSlot, 'minutes');
  }
}

function getServiceChannelHours(hoursOfOperation, specialEvents = [], pickedDate) {
  const date = pickedDate.format('YYYY-MM-DD');
  const dayOfWeek = pickedDate.format('dddd').toLocaleLowerCase();
  const operationType = path([dayOfWeek, 'operationType'], hoursOfOperation);
  const operationInterval = path([dayOfWeek, 'operatingInterval'], hoursOfOperation);
  const holidays = path(['holidays'], hoursOfOperation);
  const holidayDay = holidays?.filter(h => h.holidayDate === date);

  const specialEventDay = specialEvents?.find(s => {
    const sd = moment(s.startDate);
    const ed = moment(s.endDate);
    const isSpecialEvent = sd.isSameOrBefore(pickedDate, 'day') && ed.isSameOrAfter(pickedDate, 'day');
    return !!isSpecialEvent;
  });

  if (holidayDay?.length > 0) {
    const [holiday] = holidayDay;
    const { holidayHours } = holiday;
    const { operationType: holidayOperationType } = holidayHours;
    const { operatingInterval: holidayOperationInterval } = holidayHours;

    if (holidayOperationType !== 'closed') {
      const { openTime } = holidayOperationInterval;
      const { durationInMinutes } = holidayOperationInterval;
      const endTime = moment(openTime, [moment.ISO_8601, 'HH:mm'])
        .add(durationInMinutes, 'minutes')
        .format('HH:mm');
      fillTimeSlotsArr(openTime, endTime);
    } else {
      timeSlotsArr = [];
    }
  } else if (specialEventDay) {
    if (specialEventDay.specialEventHours?.operationType === 'standardHours') {
      const openTime = specialEventDay.specialEventHours?.operatingInterval?.openTime;
      const durationInMinutes = specialEventDay.specialEventHours?.operatingInterval?.durationInMinutes;
      const endTime = moment(openTime, [moment.ISO_8601, 'HH:mm'])
        .add(durationInMinutes, 'minutes')
        .format('HH:mm');
      fillTimeSlotsArr(openTime, endTime/* , blackoutHours.openTime, blackoutHours.durationInMinutes */);
    } else {
      timeSlotsArr = [];
    }
  } else if (operationType === 'standardHours') {
    const { openTime } = operationInterval;
    const { durationInMinutes } = operationInterval;
    const endTime = moment(openTime, [moment.ISO_8601, 'HH:mm'])
      .add(durationInMinutes, 'minutes')
      .format('HH:mm');
    const blackoutHours = pathOr({ openTime: '00:00', durationInMinutes: 0 }, [dayOfWeek, 'blackoutHours'], hoursOfOperation);
    fillTimeSlotsArr(openTime, endTime, blackoutHours.openTime, blackoutHours.durationInMinutes);
  } else if (operationType === 'open24Hours') {
    fillTimeSlotsArr('00:00', '23:45');
  } else {
    timeSlotsArr = [];
  }

  return timeSlotsArr.map((t => ({ time: t, available: true })));
}

function renderAvailableTimes(times, bypassBusinessRules, dateIsToday, timeZone) {
  const timeSlots = bypassBusinessRules ? generateTimeSlots(dateIsToday, timeZone) : times;
  return timeSlots?.map((timeSlot) => {
    let formattedTime = formatTimePickerTime(timeSlot.time);
    const timeUnavailable = !timeSlot.available;
    if (timeUnavailable) {
      formattedTime += ' (Time is currently unavailable)';
    }
    return (
      <option
        className="time-option"
        key={formattedTime}
        value={timeSlot.time}
        disabled={timeUnavailable}
      >
        {formattedTime}
      </option>
    );
  });
}

function timesPlaceholder(timeSlotsData, bypassBusinessRules) {
  if (bypassBusinessRules) {
    return 'Select Time';
  }
  if (!timeSlotsData.length) {
    return 'There are no available times for this order date.';
  }
  return 'Select Time';
}

export function DetailsForm({
  availableDates,
  selectedDate,
  change,
  dateChanged,
  timeChanged,
  availableDatesLoading,
  guestCountChanged,
  bypassBusinessRules,
  dateIsToday,
  timeZone,
  leadTimeWarning,
  hoursOfOperation,
  specialEvents,
  deliveryAddress,
  updateUserAddress,
  cateringReason: cateringOccasionValue,
  timeShouldClear,
  resetTimeFormValues,
}) {
  /* Start with why:
     Because redux form is dumb, and dispatches the onChange event before
     the change action creator, we were getting unexpected behavior
     when trying to predict state when the dateChanged or timeChanged
     actions had been dispatched. This makes it much more predictable
     as the state is updated before the action.

     TODO: remove redux-form
   */
 const { companyName } = deliveryAddress;
  useEffect(() => {
    if (timeShouldClear) {
      resetTimeFormValues();
    }
  }, [timeShouldClear]);

  const handleDateChange = (date) => {
    const value = moment(date);
    change('date', value);
    dateChanged(value);
    serviceChannelHours = getServiceChannelHours(hoursOfOperation, specialEvents, value);
  };

  const handleTimeChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    change('time', value);
    timeChanged();
  };

  const handleCateringOccasion = (event) => {
    const { value } = event.target;
    change('cateringReason', value);
  };

  return (
    <StyledDetailsForm>
      { !bypassBusinessRules
      && (
      <Disclaimer>
        Unavailable dates and times are blocked due to settings in DOP
      </Disclaimer>
      ) }
      <Flex display="flex" flexWrap="wrap" m="0 0 20px 0">
        <Box width={[1, 1 / 2]}>
          <Field
            className="date"
            name="date"
            component={DatePicker}
            onChange={handleDateChange}
            availableDates={availableDates}
            loading={bypassBusinessRules ? false : availableDatesLoading}
            bypassBusinessRules={bypassBusinessRules}
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          {(selectedDate || bypassBusinessRules)
          && (
          <Field
            className="time"
            name="time"
            type="time"
            m="10px 10px 0 10px"
            component={wrapComponentFormField(Select)}
            onChange={handleTimeChange}
            placeholder={
              timesPlaceholder(timeSlotsArr, bypassBusinessRules)
            }
            disabled={
              bypassBusinessRules ? false : !timeSlotsArr.length
            }
          >
            {renderAvailableTimes(serviceChannelHours, bypassBusinessRules, dateIsToday, timeZone)}
          </Field>
          )}
          <div className="lead-time-wrapper">
            <LeadTimeWarning message={leadTimeWarning.message} />
          </div>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]} p="0 5px">
          <Field
            label="Guest Count:"
            className="guestCount"
            name="guestCount"
            component={ValidatedField}
            type="number"
            placeholder="Guest Count:"
            onChange={guestCountChanged}
            normalize={validateGuestCount}
            m="0"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <SelectCateringOccasion
            handleCateringOccasion={handleCateringOccasion}
            cateringOccasionValue={cateringOccasionValue}
          />
        </Box>
      </Flex>
      <Flex
        width="calc(100% - 14px)"
        m="10px 10px 0 10px"
        fontSize="12px"
      >
        Any special instructions written here will show on the email
        receipt the customer receives upon order submission.
      </Flex>
      <Field
        className="specials"
        name="specialInstructions"
        component={Textarea}
        type="notes"
        placeholder="Special Instructions"
        maxLength={144}
      />
      <Box width={[1]}>
        <Input
          width="calc(100% - 14px)"
          placeholder="Company / Building Name"
          type="text"
          className="company-name"
          value={companyName == null ? '' : companyName}
          onChange={(e) => updateUserAddress(stripNonPOSSpecialCharacters(e.target.value), 'companyName')}
        />
      </Box>
    </StyledDetailsForm>
  );
}

const StyledDetailsForm = styled(Box)`
  & .specials {
    width: calc(100% - 14px);
  }

  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.outline};
    height: 50px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 14px);
    appearance: none;
  }

  & input::-webkit-inner-spin-button, input::-webkit-outer-spin-button { 
    opacity: 0;
  }

  & input[type=number]:hover::-webkit-inner-spin-button, input[type=number]:hover::-webkit-outer-spin-button,
  input[type=number]:focus::-webkit-inner-spin-button, input[type=number]:focus::-webkit-outer-spin-button { 
    opacity: 1;
  }
  
  & .lead-time-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    @media(max-width: ${props => props.theme.phone}) {
      position: relative;
    }
  }
`;

DetailsForm.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  dateChanged: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  availableDatesLoading: PropTypes.bool,
  guestCountChanged: PropTypes.func.isRequired,
  bypassBusinessRules: PropTypes.bool,
  dateIsToday: PropTypes.bool,
  timeChanged: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  leadTimeWarning: PropTypes.objectOf(PropTypes.string).isRequired,
  hoursOfOperation: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])),
  specialEvents: PropTypes.array,
  deliveryAddress: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  updateUserAddress: PropTypes.func,
  cateringReason: PropTypes.string,
  timeShouldClear: PropTypes.bool,
  resetTimeFormValues: PropTypes.func,
};

DetailsForm.defaultProps = {
  availableDatesLoading: true,
  bypassBusinessRules: false,
  dateIsToday: false,
  hoursOfOperation: {},
  specialEvents: [],
  deliveryAddress: {},
  updateUserAddress: () => {},
  cateringReason: '',
  timeShouldClear: false,
  resetTimeFormValues: () => {},
};

export const exportsForTesting = {
  isInBlackoutWindow,
  getTimeSlots,
  fillTimeSlotsArr,
  getServiceChannelHours,
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.DETAILS,
  destroyOnUnmount: false,
  validate: validateDetails,
  initialValues: { date: '' },
})(DetailsForm);
