import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import { Icon, Disclaimer } from '../index';

function LeadTimeWarning({ message }) {
  return (
    <StyledLeadTimeWarning>
      <Icon width="17px" margin="0 2px 2px 0">warning</Icon>
      <div>{message}</div>
    </StyledLeadTimeWarning>
  );
}

LeadTimeWarning.propTypes = {
  message: PropTypes.string,
};

LeadTimeWarning.defaultProps = {
  message: '',
};

const StyledLeadTimeWarning = styled(Disclaimer)`
  display: flex;
  align-items: center;
  height: 30px;
`;

export default LeadTimeWarning;
