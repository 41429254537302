/* eslint-disable react/forbid-prop-types */
import 'react-dates/initialize';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import { generateDateSlots } from '../../util/format';

class DatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { focused: false };
  }

  render() {
    const {
      input,
      availableDates,
      loading,
      bypassBusinessRules,
    } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const isFocused = this.state.focused; // if we destructure this seems to conflict with onFocusChange below
    const { onChange } = input;
    const value = typeof input.value === 'string' ? null : input.value;

    return (
      <SingleDatePicker
        noBorder
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        disabled={loading}
        placeholder={loading ? 'Retrieving Available Dates...' : 'Select Date'}
        readOnly
        date={value}
        onDateChange={(date) => onChange(date)}
        isDayBlocked={(date) => generateDateSlots(date, availableDates, bypassBusinessRules)}
        focused={isFocused}
        onFocusChange={({ focused }) => this.setState({ focused })}
      />
    );
  }
}

DatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ])).isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  bypassBusinessRules: PropTypes.bool,
};

DatePicker.defaultProps = {
  loading: true,
  bypassBusinessRules: false,
};

export default DatePicker;
