/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

function Toggle({
  input, label, dataCy,
}) {
  const { onChange, value } = input;
  // const value = typeof input.value === 'string' ? false : input.value;
  return (
    <StyledToggle>
      <p className="label-text">{label}</p>
      <label htmlFor="checkbox" className="label">
        <input
          id="checkbox"
          className="checkbox"
          type="checkbox"
          onChange={onChange}
          checked={value}
          name={label}
          data-cy={dataCy}
        />
        <span className="slider" />
      </label>
    </StyledToggle>
  );
}

const StyledToggle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.text};
  margin-left: 15px;
  padding-top: 10px;

  & .label-text {
    margin-right: 10px;
  }
    
  & .label {
    position: relative;
    display: inline-block;
    width: 52px !important;
    height: 26px;
  }

  & .checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .3s;
    border-radius: 34px;
  }
  
  & .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .3s;
    border-radius: 50%;
  }

  & input:checked + .slider {
    background-color: ${props => props.theme.accent};
  }

  & input:focus + .slider {
    box-shadow: 0 0 1px ${props => props.theme.secondary};
  }
  
  & .checkbox:checked + .slider:before {
    transform: translateX(26px);
  }
  
  @media (max-width: 380px) {
    margin: 0px 3px;
    & .label-text {
      margin: 0;
      width: 50%;
    }
  }
  
`;

Toggle.propTypes = {
  input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  dataCy: PropTypes.string,
};

Toggle.defaultProps = {
  input: { onChange: () => {} },
  label: '',
  dataCy: '',
};

export default Toggle;
