import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Disclaimer from '../Disclaimer/Disclaimer';

class Textarea extends PureComponent {
  constructor(props) {
    super(props);
    const { input, maxLength, defaultValue } = this.props;
    const value = defaultValue || input.value || '';
    this.state = { charsRemaining: maxLength - value.length };
    this.updateCharCount = this.updateCharCount.bind(this);
  }

  updateCharCount(event) {
    const { maxLength } = this.props;
    const charCount = event.target.value.length;
    const charsRemaining = maxLength - charCount;
    if (charsRemaining >= 0) {
      this.setState({ charsRemaining });
    }
  }

  render() {
    const {
      placeholder,
      maxLength,
      input,
      onBlur,
      defaultValue,
      className,
    } = this.props;
    const value = defaultValue || input.value || '';
    const blur = input.onBlur || onBlur;
    const { charsRemaining } = this.state;

    return (
      <StyledTextarea>
        <textarea
          className={className}
          placeholder={placeholder}
          onBlur={blur}
          maxLength={maxLength.toString()}
          defaultValue={value}
          onChange={(e) => this.updateCharCount(e)}
        />
        <Disclaimer className="instructions">
          {`${charsRemaining} Characters Remaining`}
        </Disclaimer>
      </StyledTextarea>
    );
  }
}

const StyledTextarea = styled.div`
  & textarea {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.outline};
    border-radius: 0px;
    appearance: none;
    height: 85px;
    padding: 7px 10px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 20px);

    &:focus {
      outline: none;
    }
    
    &::placeholder {
      color: ${(props) => props.theme.outline};
    }
  }
  
  & .instructions {
    margin-top: -5px;
    width: calc(100% - 22px);
    text-align: right !important; 
  }

`;

Textarea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
};

Textarea.defaultProps = {
  className: '',
  maxLength: 1000,
  defaultValue: '',
  onBlur: () => {},
  input: '',
};

export default Textarea;
