/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Section } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import OrderInformation from '../OrderInformation/OrderInformation';

const CreateInvoicePage = React.forwardRef((props, ref) => {
  const {
    eventDetails,
    deliveryAddress,
    guestDetails,
    destination,
    eventSummaryValid,
    addressValidated,
    restaurantContactDetails,

    cartItems,

    subTotal,
    taxAndTotal,
    orderLoading,
    deliveryTip,
    deliveryTipIsDisplayed,
    deliveryTipLoading,
    cateringReason,
    secondaryContactDetails,
    isEditMode,
    promoFreeActive,
    specialInstructionMessage,
    paperGoodsRequired,
    showDefaultPaperGoodsMessage,
    taxAmount,
  } = props;

  const {
    restaurantAddress1,
    restaurantAddress2,
    restaurantPhone,
  } = restaurantContactDetails;

  const fullAddress = `${restaurantAddress1}, ${restaurantAddress2}`;

  return (
    <div ref={ref}>
      <Section style={{ paddingBottom: '0' }}>
        <h2 style={{ marginBottom: '0' }}>Chick-fil-A catering Order Summary</h2>
      </Section>
      <Section style={{ paddingTop: '0', paddingBottom: '0' }}>
        <p>{fullAddress}</p>
        <p>{restaurantPhone}</p>
      </Section>
      <OrderInformation
        eventDetails={eventDetails}
        deliveryAddress={deliveryAddress}
        guestDetails={guestDetails}
        destination={destination}
        eventSummaryValid={eventSummaryValid}
        addressValidated={addressValidated}
        restaurantContactDetails={restaurantContactDetails}
        cartItems={cartItems}
        subTotal={subTotal}
        taxAndTotal={taxAndTotal}
        orderLoading={orderLoading}
        deliveryTip={deliveryTip}
        deliveryTipIsDisplayed={deliveryTipIsDisplayed}
        deliveryTipLoading={deliveryTipLoading}
        isInvoicePage
        cateringReason={cateringReason}
        secondaryContactDetails={secondaryContactDetails}
        promoFreeActive={promoFreeActive}
        specialInstructionMessage={specialInstructionMessage}
        isEditMode={isEditMode}
        paperGoodsRequired={paperGoodsRequired}
        showDefaultPaperGoodsMessage={showDefaultPaperGoodsMessage}
        taxAmount={taxAmount}
      />
    </div>
  );
});

CreateInvoicePage.propTypes = {
  eventDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  deliveryAddress: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  guestDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  destination: PropTypes.string.isRequired,
  eventSummaryValid: PropTypes.bool,
  addressValidated: PropTypes.bool,
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  cartItems: PropTypes.arrayOf(PropTypes.object),
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: PropTypes.bool,
  deliveryTip: PropTypes.objectOf(PropTypes.number),
  deliveryTipIsDisplayed: PropTypes.bool,
  deliveryTipLoading: PropTypes.bool,
  cateringReason: PropTypes.string,
  secondaryContactDetails: PropTypes.objectOf(PropTypes.string),
  isEditMode: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  specialInstructionMessage: PropTypes.string,
  paperGoodsRequired: PropTypes.bool,
  showDefaultPaperGoodsMessage: PropTypes.bool,
  taxAmount: PropTypes.string,
};

CreateInvoicePage.defaultProps = {
  eventDetails: {},
  deliveryAddress: {},
  guestDetails: {},
  eventSummaryValid: false,
  addressValidated: false,
  restaurantContactDetails: {},
  cartItems: [],
  subTotal: '',
  taxAndTotal: '',
  orderLoading: false,
  deliveryTip: {},
  deliveryTipIsDisplayed: false,
  deliveryTipLoading: false,
  cateringReason: '',
  promoFreeActive: false,
  secondaryContactDetails: {},
  isEditMode: false,
  specialInstructionMessage: '',
  paperGoodsRequired: false,
  showDefaultPaperGoodsMessage: false,
  taxAmount: '',
};

export default CreateInvoicePage;
