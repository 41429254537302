import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { combinedActionCreators } from './reducers';
import rootEpic from './epics';
import ooeConstants from './constants';

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  actionCreators: combinedActionCreators,
  actionsBlacklist: ['BLUR', 'FOCUS', 'UNREGISTER_FIELD', 'REGISTER_FIELD'],
  //actionsBlacklist: ['[User] Toggle Bypass', 'redux-form/FOCUS', 'redux-form/BLUR', '[User].Toggle.Bypass'],
  //actionsBlacklist: ['redux-form/'],
  //actionsBlacklist: ['User'],
  //actionsBlacklist: ['[Guest]', '[Menu]', '[Order]'],
  //actionsBlacklist: ['Guest', 'Menu', 'Order', 'redux-form'],
  //actionsBlacklist: ['\['],
});

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== ooeConstants.PRODUCTION) {
    if (module.hot) {
      module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
      module.hot.accept('./epics', () => epicMiddleware.replaceEpic(rootEpic));
    }
  }

  return store;
};

export default configureStore;
