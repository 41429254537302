import { of } from 'rxjs';
import { pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import moment from 'moment';
import {
  filter,
  switchMap,
  tap,
  ignoreElements,
  catchError,
} from 'rxjs/operators';
import history from '../util/history';
import {
  actions,
  doesLocationNumberExist,
  isDeveloperAudience,
  selectAccessToken,
  selectOktaToken,
  selectRefreshToken,
  selectAccessTokenExpirationDate,
  selectUserLocationNumbers,
  selectLocationNumber,
  types,
} from '../reducers/user';
import {
  getOktaToken,
  exchangeOktaToken,
  lookupLocationsByNumber,
  logoutUser,
  checkMessagesOnLoad,
  refreshOktaToken,
  getMessageText,
  addUserToMessageAcknowledgement,
  fetchVcaLocations,
  getStoreNamesAndNumbersFromApi,
} from '../services/userApi';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import ooeConstants from '../constants';
import { types as orderTypes } from '../reducers/order';
import { specialLocationErrorMessages } from '../util/customerErrorMessages';

export const GetTokenFromStorage = (action$) => action$
  .pipe(
    ofType(types.GET_TOKEN_FROM_STORAGE),
    /* istanbul ignore next */
    switchMap(() => {
      const token = JSON.parse(localStorage.getItem(ooeConstants.LOCAL_STORAGE_CFA_KEY));
      return token
        ? of(actions.getTokenFromStorageSuccess(token))
        : of(actions.getOktaToken());
    }),
  );

export const GetTokenFromStorageSuccess = (action$, store) => action$
  .pipe(
    ofType(types.GET_TOKEN_FROM_STORAGE_SUCCESS),
    /* istanbul ignore next */
    switchMap(() => {
      const exp = selectAccessTokenExpirationDate(store.value);
      const isExpired = moment.unix(exp).isBefore();

      if (isExpired) {
        return of(actions.refreshOktaToken());
      }
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
      return of(actions.exchangeOktaTokenSuccess(store.value.user));
    }),
  );

export const AuthUser = (action$) => action$
  .pipe(
    ofType(
      types.GET_OKTA_TOKEN,
      types.REFRESH_TOKEN_FAILURE,
    ),
    switchMap(() => getOktaToken()
      .then(actions.getOktaTokenSuccess)
      .catch(actions.getOktaTokenFailure)),
  );

export const ExchangeToken = (action$, store) => action$
  .pipe(
    ofType(
      types.GET_OKTA_TOKEN_SUCCESS,
      types.EXCHANGE_OKTA_TOKEN,
    ),
    switchMap(() => {
      const state = store.value;
      const oktaToken = selectOktaToken(state);
      return exchangeOktaToken(oktaToken)
        .then(actions.exchangeOktaTokenSuccess)
        .catch(actions.exchangeOktaTokenFailure);
    }),
  );

export /* istanbul ignore next */ const RefreshToken = (action$, store) => action$
  .pipe(
    ofType(types.REFRESH_TOKEN),
    switchMap(() => {
      const state = store.value;
      const refreshTokenToUse = selectRefreshToken(state);
      return refreshOktaToken(refreshTokenToUse)
        .then(actions.refreshTokenSuccess)
        .catch(actions.refreshTokenFailure);
    }),
  );

export const CheckMessagesOnLoad = (action$, store) => action$
  .pipe(
    ofType(
      types.EXCHANGE_OKTA_TOKEN_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ),
    filter(() => !window.Cypress),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      if (localStorage.getItem(ooeConstants.EULA)) {
        return of(actions.addUserToAcknowledgementSuccessStorage(ooeConstants.EULA));
      }
      return epicHelper(
        checkMessagesOnLoad(accessToken, ooeConstants.EULA),
        actions.messageAcknowledgedSuccess(ooeConstants.EULA),
        actions.messageAcknowledgedFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const GetMessageText = (action$, store) => action$
  .pipe(
    ofType(types.MESSAGE_ACKNOWLEDGED_FAILURE),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getMessageText(accessToken, ooeConstants.EULA),
        actions.messageTextSuccess(ooeConstants.EULA),
        actions.messageTextFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const AddUserToMessageAcknowledgement = (
  action$,
  store,
) => action$
  .pipe(
    ofType(types.ADD_USER_TO_ACKNOWLEDGEMENT, orderTypes.DISMISS_ERROR),
    filter(({ key }) => (key === ooeConstants.EULA)),
    switchMap(({ key }) => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        addUserToMessageAcknowledgement(accessToken, key),
        () => actions.addUserToAcknowledgementSuccess(key),
        (error) => actions.messageAcknowledgedFailure(key, error),
        epicOptions(store, action$),
      );
    }),
  );

export const ShowHardcodedMessageAcknowledgement = (action$) => action$
  .pipe(
    ofType(types.MESSAGE_TEXT_FAILURE),
    filter(() => {
      const cmtEula = localStorage.getItem(ooeConstants.EULA);
      return !cmtEula;
    }),
    switchMap(() => of(actions.showHardcodedEulaText())),
  );

export const LookupSingleLocation = (action$, store) => action$
  .pipe(
    ofType(types.LOOKUP_LOCATION),
    filter((action) => {
      const { location } = action;
      const state = store.value;
      return !doesLocationNumberExist(state, location);
    }),
    switchMap((action) => {
      const { location } = action;
      const locations = [location];
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locations, accessToken),
        actions.lookupLocationSuccess,
        actions.lookupLocationFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupUserLocations = (action$, store) => action$
  .pipe(
    ofType(
      types.EXCHANGE_OKTA_TOKEN_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ),
    switchMap((action) => {
      const state = store.value;
      const CMT = pathOr({}, ['user', 'cfa_perms', 'CMT'], action);
      const { user } = action;
      const cfaAud = user.cfa_aud;
      let locationNumbers = selectUserLocationNumbers(state);

      const {
        ADMIN,
        LOGIN,
        VCA,
        VCA_BYPASS,
      } = CMT;

      // istanbul ignore next
      if ((ADMIN || VCA || VCA_BYPASS) && (locationNumbers[0] === '00000')) {
        return fetchVcaLocations()
          .pipe(
            catchError((err) => of(actions.getUserLocationsFailure(err))),
            switchMap((locations) => {
              const storedLoc = localStorage.getItem('storedLocationNumber');
              const isLocalhost = window.location.hostname === 'localhost';
              let locationList = locations;
              if (locationList.error) {
                return of(actions.throwFullScreenError());
              }
              if (storedLoc && !isLocalhost) {
                locationList.unshift(storedLoc);
                locationList = [...new Set(locationList)];
              }

              const accessToken = selectAccessToken(state);
              return epicHelper(
                lookupLocationsByNumber(locationList, accessToken),
                actions.getUserLocationsSuccess,
                actions.getUserLocationsFailure,
                epicOptions(store, action$),
              );
            }),
          );
      }
      // let devs with LOGIN permission access multiple labs :)
      // istanbul ignore next
      if (LOGIN && (locationNumbers[0] === '00000') && (isDeveloperAudience(cfaAud))) {
        locationNumbers = ooeConstants.DEFAULT_STORES;
      }
      // istanbul ignore next
      if (LOGIN && (locationNumbers[0] === '00000')) {
        locationNumbers = [ooeConstants.PROD_PILOT_LAB];
      }
      const locations = locationNumbers;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locations, accessToken),
        actions.getUserLocationsSuccess,
        actions.throwFullScreenError,
        epicOptions(store, action$),
      );
    }),
  );

  export const SetDefaultLocation = (action$) => action$
  .pipe(
    ofType(
      types.EXCHANGE_OKTA_TOKEN_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ),
    filter(() => window.location.hostname !== 'localhost'),
    switchMap(() => {
      const defaultLocation = localStorage.getItem('storedLocationNumber');
      if (defaultLocation) {
        return of(actions.setDefaultLocationSuccess(defaultLocation));
      }
      return of(actions.setDefaultLocationFailure());
    }),
  );

export const SetBBR = (action$) => action$
  .pipe(
    ofType(
      types.EXCHANGE_OKTA_TOKEN_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ),
    switchMap((action) => {
      const CMT = pathOr([], ['user', 'cfa_perms', 'CMT'], action);
      const permissions = Object.keys(CMT);
      const isVca = [ooeConstants.PERMISSIONS.VCA, ooeConstants.PERMISSIONS.VCA_BYPASS].some(item => permissions.includes(item));
      return of(actions.setBBRForVCA(isVca));
    }),
  );

export const LogoutUser = (action$) => action$
  .pipe(
    ofType(types.LOGOUT_USER),
    tap(() => {
      logoutUser();
    }),
    ignoreElements(),
  );

export const fullScreenError = (action$) => action$
  .pipe(
    ofType(types.FULL_SCREEN_ERROR),
    tap(() => history.push({ pathname: '/error' })),
    ignoreElements(),
  );

export const SetSpecialLocationErrorMessage = (action$, store) => action$
  .pipe(
    ofType(
      types.UPDATE_USER_LOCATION,
    ),
    switchMap(() => {
      const state = store.value;
      const locationNumber = selectLocationNumber(state);
      let locationMessage = null;
      if (locationNumber === '01162') {
        const now = moment();
        const mayFirst2023 = moment('2023-05-01');
        if (now.isBefore(mayFirst2023)) {
          locationMessage = specialLocationErrorMessages.LOCATION_01162;
        }
      }
      return of(actions.setSpecialLocationErrorMessage(locationMessage));
    }),
  );

export const GetStoreNumbersAndNames = (action$, store) => action$
  .pipe(
    ofType(
      types.EXCHANGE_OKTA_TOKEN_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getStoreNamesAndNumbersFromApi(accessToken),
        actions.getStoreNumbersAndNamesSuccess,
        actions.getStoreNumbersAndNamesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  AddUserToMessageAcknowledgement,
  AuthUser,
  CheckMessagesOnLoad,
  ExchangeToken,
  fullScreenError,
  GetMessageText,
  GetTokenFromStorage,
  GetTokenFromStorageSuccess,
  LogoutUser,
  LookupSingleLocation,
  LookupUserLocations,
  RefreshToken,
  SetBBR,
  GetStoreNumbersAndNames,
  SetSpecialLocationErrorMessage,
  ShowHardcodedMessageAcknowledgement,
  SetDefaultLocation,
];
