import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, Input, Spinner,
} from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import {
  Icon,
} from '../index';
import Success from '../../assets/success.svg';
import Error from '../../assets/error.svg';
import { isExpired } from '../../util/validate';
import ooeConstants from '../../constants';

class VaultedCards extends Component {
  constructor(props) {
    super(props);
    const { cardSelected } = this.props;
    this.state = {
      cardToValidate: (cardSelected && cardSelected.id) ? cardSelected.generatedId : null,
      zip: '',
    };
    this.toggleValidateMode = this.toggleValidateMode.bind(this);
    this.callValidateZip = this.callValidateZip.bind(this);
    this.renderZipValidation = this.renderZipValidation.bind(this);
  }

  toggleValidateMode(e, card) {
    const { validateZipSuccess } = this.props;
    e.preventDefault();
    if (card.validated) {
      validateZipSuccess([card]);
    }
    this.setState({
      cardToValidate: card.generatedId,
      zip: '',
    });
  }

  callValidateZip(event, card) {
    const { validateZip } = this.props;
    const zip = event.target.value;
    this.setState({ zip });
    if (zip.length >= 5) {
      validateZip(zip, card);
    }
  }

  renderZipValidation(card) {
    const { zipLoading, cardSelected } = this.props;
    const { cardToValidate, zip } = this.state;

    if (cardToValidate === card.generatedId || cardSelected.generatedId === card.generatedId) {
      if (zipLoading) {
        return (<Spinner className="spinner" />);
      }
      if (card.validated) {
        return (<img className="success" src={Success} alt="Success" />);
      }
      return (
        <>
          <Input
            height="37px"
            width="75px"
            m="0.5em"
            value={zip}
            autoFocus
            placeholder="Zip"
            data-cy="card-zip"
            onChange={(e) => this.callValidateZip(e, card)}
          />
          {cardSelected === 'error' && <img src={Error} alt="Error" className="error" />}
        </>
      );
    }
    if (isExpired(card)) {
      return <div>Card Expired</div>;
    }
    return (
      <Button
        width="75px"
        p="0.5em 1em"
        m="0.5em"
        fontSize={2}
        variant="secondary"
        onClick={(e) => this.toggleValidateMode(e, card)}
      >
        Select
      </Button>
    );
  }

  render() {
    const { vaultedCards, cardsLoading } = this.props;
    return (
      <StyledVaultedCards>
        {cardsLoading
          ? (
            <Flex>
              <Spinner />
            </Flex>
          )
          : vaultedCards?.map?.((card) => (
            <Flex key={card.generatedId} alignItems="center" className={isExpired(card) && 'expired'}>
              <Box width={1 / 6}>
                <Icon>{card.cardType?.toLowerCase?.()}</Icon>
              </Box>
              <Flex width={1 / 3} alignItems="center">
                {card.cardType !== ooeConstants.paypal
                && (
                  <>
                    <span className="card-number">
                      &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679;&nbsp;
                    </span>
                    &#9679;&#9679;&#9679;&#9679;
                  </>
                )}
                {card.accountDisplay}
              </Flex>
              <Box width={5 / 12} className="zip-container">
                {this.renderZipValidation(card)}
              </Box>
            </Flex>
          ))}
      </StyledVaultedCards>
    );
  }
}

const StyledVaultedCards = styled.div`
  clear: both;

  & .zip-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }

  & .spinner {
    text-align: right !important;
  }
  & .success {
    width: 28px;
    margin-right: 30px;
  }
  & .error {
    width: 23px;
    position: absolute;
    right: -25px;
    top: 14px;
  }
  & .expired {
    opacity: 0.3;
    pointer-events: none;
  }

  @media(max-width: ${(props) => props.theme.phone}) {
    & .card-number {
      display: none;
    }
  }
`;

VaultedCards.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  cardsLoading: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool.isRequired,
  cardSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  validateZipSuccess: PropTypes.func.isRequired,
};

VaultedCards.defaultProps = {
  vaultedCards: [],
  cardsLoading: false,
};

export default VaultedCards;
