/* eslint-disable react/forbid-prop-types */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Section, SectionHeader, SegmentGroup, SegmentButton,
} from '@cfacorp/cowponents';
import ooe from '../constants';
import {
  actions as orderActions,
  selectDestination, selectAvailableDates,
  selectDeliveryAddress,
  selectDeliveryError, selectDeliveryAddressValid, selectValidateAddressLoading,
  selectDatesLoading, selectShowWarningModal, selectValidateDeliveryRangeLoading, selectAvailableLocations,
} from '../reducers/order';
import {
  actions as formActions, selectCateringOccasion, selectDate, selectDateIsToday, selectSecondaryContactValues, selectTimeShouldClear,
} from '../reducers/form';
import { DestinationForm, DetailsForm } from '../components';
import {
  actions as userActions,
  selectBypassBusinessRules,
  selectLocationTimezone,
  selectDeliveryRange,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
} from '../reducers/user';
import { selectLeadTimeWarning, selectHoursOfOperation, selectSpecialEvents } from '../reducers';
import {
  actions as guestActions, selectNoPastDeliveryAddresses, selectPastDeliveryAddresses, selectPastDeliveryAddressesLoading, selectPastOrders,
} from '../reducers/guest';
import SecondaryContactField from '../components/Forms/SecondaryContactField';

const destinations = ooe.CATERING_METHODS;

export class EventDetails extends PureComponent {
  setDestination = () => {
    const {
      destination,
    } = this.props;
    const { PICKUP, DELIVERY } = ooe;
    return destination === DELIVERY ? DELIVERY : PICKUP;
  }

  isDisabled = (currentDestination) => {
    const {
      deliveryAccepted,
      pickupAccepted,
    } = this.props;
    const { PICKUP, DELIVERY } = ooe;
    return (currentDestination === DELIVERY && !deliveryAccepted)
      || (currentDestination === PICKUP && !pickupAccepted);
  }

  isSelected = (currentDestination) => {
    const {
      destination,
    } = this.props;
    return (currentDestination === destination);
  }

  render() {
    const {
      destination,
      availableDates,
      selectedDate,
      updateUserAddress,
      deliveryAddress,
      dateChanged,
      autocompleteAddress,
      validateError,
      deliveryAddressValid,
      validateAddressIsLoading,
      availableDatesLoading,
      timeChanged,
      guestCountChanged,
      bypassBusinessRules,
      dateIsToday,
      timeShouldClear,
      resetTimeFormValues,
      timeZone,
      leadTimeWarning,
      showWarningModal,
      closeWarningModal,
      deliveryRange,
      hoursOfOperation,
      specialEvents,
      validateDeliveryRangeIsLoading,
      availableLocations,
      updateUserLocation,
      lookupLocation,
      getPastDeliveryAddresses,
      pastOrders,
      pastDeliveryAddresses,
      pastDeliveryAddressesLoading,
      noPastDeliveryAddresses,
      changeDestination,
      cateringReason,
      clearUserAddressFields,
      addSecondaryContact,
      secondaryContactValues,
    } = this.props;
    const { DELIVERY } = ooe;
    const secondaryContactTitle = `Secondary ${destination === DELIVERY ? 'Delivery' : 'Pickup'} POC Information`;
    return (
      <>
        <Section>
          <SegmentGroup
            onChange={(event) => changeDestination(event.target.value)}
            defaultValue={this.setDestination()}
          >
            {destinations.map((dest) => (
              <SegmentButton
                key={dest}
                value={dest}
                selected={this.isSelected(dest)}
                disabled={this.isDisabled(dest)}
              >
                {dest}
              </SegmentButton>
            ))}
          </SegmentGroup>
          <SectionHeader>Event Details</SectionHeader>
          <DetailsForm
            availableDates={availableDates}
            selectedDate={selectedDate}
            dateIsToday={dateIsToday}
            dateChanged={dateChanged}
            availableDatesLoading={availableDatesLoading}
            timeChanged={timeChanged}
            guestCountChanged={guestCountChanged}
            bypassBusinessRules={bypassBusinessRules}
            timeZone={timeZone}
            leadTimeWarning={leadTimeWarning}
            hoursOfOperation={hoursOfOperation}
            specialEvents={specialEvents}
            cateringReason={cateringReason}
            timeShouldClear={timeShouldClear}
            resetTimeFormValues={resetTimeFormValues}
            deliveryAddress={deliveryAddress}
            updateUserAddress={updateUserAddress}
          />
        </Section>
        {destination === DELIVERY && (
          <>
            <Section>
              <SectionHeader>Delivery Location</SectionHeader>
              <DestinationForm
                deliveryAddress={deliveryAddress}
                updateUserAddress={updateUserAddress}
                autocompleteAddress={autocompleteAddress}
                validateError={validateError}
                deliveryAddressValid={deliveryAddressValid}
                validateAddressIsLoading={validateAddressIsLoading}
                showWarningModal={showWarningModal}
                closeWarningModal={closeWarningModal}
                deliveryRange={deliveryRange}
                validateDeliveryRangeIsLoading={validateDeliveryRangeIsLoading}
                availableLocations={availableLocations}
                updateUserLocation={updateUserLocation}
                lookupLocation={lookupLocation}
                getPastDeliveryAddresses={getPastDeliveryAddresses}
                pastOrders={pastOrders}
                pastDeliveryAddresses={pastDeliveryAddresses}
                pastDeliveryAddressesLoading={pastDeliveryAddressesLoading}
                noPastDeliveryAddresses={noPastDeliveryAddresses}
                clearUserAddressFields={clearUserAddressFields}
              />
            </Section>
          </>
        )}
        <Section>
          <SectionHeader>
            {secondaryContactTitle}
            <small> (Optional)</small>
          </SectionHeader>
          <SecondaryContactField
            addSecondaryContact={addSecondaryContact}
            secondaryContactValues={secondaryContactValues}
          />
        </Section>
      </>
    );
  }
}

EventDetails.propTypes = {
  destination: PropTypes.string,
  availableDates: PropTypes.arrayOf(PropTypes.any),
  availableDatesLoading: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  updateUserAddress: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  dateChanged: PropTypes.func.isRequired,
  timeChanged: PropTypes.func.isRequired,
  autocompleteAddress: PropTypes.func,
  validateError: PropTypes.objectOf(PropTypes.string),
  deliveryAddressValid: PropTypes.bool,
  validateAddressIsLoading: PropTypes.bool,
  validateDeliveryRangeIsLoading: PropTypes.bool,
  guestCountChanged: PropTypes.func.isRequired,
  bypassBusinessRules: PropTypes.bool,
  dateIsToday: PropTypes.bool,
  timeShouldClear: PropTypes.bool,
  resetTimeFormValues: PropTypes.func,
  timeZone: PropTypes.string,
  leadTimeWarning: PropTypes.objectOf(PropTypes.string),
  showWarningModal: PropTypes.bool,
  closeWarningModal: PropTypes.func,
  deliveryRange: PropTypes.number,
  hoursOfOperation: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])),
  specialEvents: PropTypes.array,
  availableLocations: PropTypes.arrayOf(PropTypes.any),
  updateUserLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
  getPastDeliveryAddresses: PropTypes.func,
  pastOrders: PropTypes.arrayOf(PropTypes.any),
  pastDeliveryAddresses: PropTypes.arrayOf(PropTypes.any),
  pastDeliveryAddressesLoading: PropTypes.bool,
  noPastDeliveryAddresses: PropTypes.bool,
  changeDestination: PropTypes.func,
  cateringReason: PropTypes.string,
  clearUserAddressFields: PropTypes.func,
  deliveryAccepted: PropTypes.bool,
  pickupAccepted: PropTypes.bool,
  addSecondaryContact: PropTypes.func,
  secondaryContactValues: PropTypes.objectOf(PropTypes.string),
};

EventDetails.defaultProps = {
  destination: '',
  availableDates: [],
  availableDatesLoading: true,
  selectedDate: '',
  deliveryAddress: {},
  validateError: null,
  deliveryAddressValid: false,
  validateAddressIsLoading: false,
  validateDeliveryRangeIsLoading: false,
  bypassBusinessRules: false,
  dateIsToday: false,
  timeShouldClear: false,
  resetTimeFormValues: () => {},
  timeZone: '',
  leadTimeWarning: {},
  showWarningModal: false,
  closeWarningModal: () => {},
  deliveryRange: 0,
  hoursOfOperation: {},
  specialEvents: [],
  availableLocations: [],
  updateUserLocation: () => {},
  lookupLocation: () => {},
  autocompleteAddress: () => {},
  getPastDeliveryAddresses: () => {},
  pastOrders: [],
  pastDeliveryAddresses: [],
  pastDeliveryAddressesLoading: false,
  noPastDeliveryAddresses: false,
  changeDestination: () => {},
  cateringReason: '',
  clearUserAddressFields: () => {},
  deliveryAccepted: false,
  pickupAccepted: false,
  addSecondaryContact: () => {},
  secondaryContactValues: {},
};

function mapStateToProps(state) {
  return {
    destination: selectDestination(state),
    availableDates: selectAvailableDates(state),
    availableDatesLoading: selectDatesLoading(state),
    selectedDate: selectDate(state),
    dateIsToday: selectDateIsToday(state),
    deliveryAddress: selectDeliveryAddress(state),
    validateError: selectDeliveryError(state),
    deliveryAddressValid: selectDeliveryAddressValid(state),
    validateAddressIsLoading: selectValidateAddressLoading(state),
    validateDeliveryRangeIsLoading: selectValidateDeliveryRangeLoading(state),
    bypassBusinessRules: selectBypassBusinessRules(state),
    timeShouldClear: selectTimeShouldClear(state),
    timeZone: selectLocationTimezone(state),
    leadTimeWarning: selectLeadTimeWarning(state),
    showWarningModal: selectShowWarningModal(state),
    deliveryRange: selectDeliveryRange(state),
    hoursOfOperation: selectHoursOfOperation(state),
    specialEvents: selectSpecialEvents(state),
    availableLocations: selectAvailableLocations(state),
    pastOrders: selectPastOrders(state),
    pastDeliveryAddresses: selectPastDeliveryAddresses(state),
    pastDeliveryAddressesLoading: selectPastDeliveryAddressesLoading(state),
    noPastDeliveryAddresses: selectNoPastDeliveryAddresses(state),
    cateringReason: selectCateringOccasion(state),
    deliveryAccepted: selectLocationAcceptsDelivery(state),
    pickupAccepted: selectLocationAcceptsPickup(state),
    secondaryContactValues: selectSecondaryContactValues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...orderActions,
    ...formActions,
    ...userActions,
    ...guestActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
