import { Box, Flex } from '@cfacorp/cowponents';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import normalizePhone from '../../util/normalizePhone';
import ValidatedField from './ValidatedField';
import { stripNonPOSSpecialCharacters } from '../../util/utils';
import constants from '../../constants';
import { validateSecondaryContact } from '../../util/validate';

const SecondaryContactField = ({ addSecondaryContact, secondaryContactValues }) => {
  const {
    firstName,
    lastName,
    phoneNumber,
  } = secondaryContactValues;
  const handleAdd = () => {
    if (
      !isEmpty(secondaryContactValues)
      && firstName !== '' && firstName !== undefined
      && lastName !== '' && lastName !== undefined
      && phoneNumber !== '' && phoneNumber !== undefined
      ) addSecondaryContact();
  };
  return (
    <Box>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 3]}>
          <Field
            name="firstName"
            component={ValidatedField}
            type="text"
            normalize={stripNonPOSSpecialCharacters}
            placeholder="First Name"
            className="first-name"
            onBlur={handleAdd}
            requiredWithoutTouch
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            name="lastName"
            component={ValidatedField}
            type="text"
            normalize={stripNonPOSSpecialCharacters}
            placeholder="Last Name"
            className="last-name"
            onBlur={handleAdd}
            requiredWithoutTouch
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            name="phoneNumber"
            component={ValidatedField}
            type="text"
            placeholder="Phone number"
            normalize={normalizePhone}
            className="phone-number"
            onBlur={handleAdd}
            requiredWithoutTouch
          />
        </Box>
      </Flex>
    </Box>
  );
};

SecondaryContactField.propTypes = {
  addSecondaryContact: PropTypes.func,
  secondaryContactValues: PropTypes.objectOf(PropTypes.string),
};

SecondaryContactField.defaultProps = {
  addSecondaryContact: () => {},
  secondaryContactValues: {},
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.SECONDARY_CONTACT,
  destroyOnUnmount: false,
  validate: validateSecondaryContact,
  initialValues: {},
})(SecondaryContactField);
