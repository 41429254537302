import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Section } from '@cfacorp/cowponents';
// eslint-disable-next-line import/no-cycle
import { Icon } from '../index';

function EmptyCart({ className, message }) {
  return (
    <Section m="100px 50px" textAlign="center" color="textColor" className={className}>
      <Icon>bag</Icon>
      <div className="message">{message}</div>
    </Section>
  );
}

const StyledEmptyCart = styled(EmptyCart)`
  fill: ${(props) => props.theme.text};
  
  & .message {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0;
  }
`;

EmptyCart.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default StyledEmptyCart;
