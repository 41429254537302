/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Section, SectionHeader } from '@cfacorp/cowponents';
import { GuestForm, SuggestedCustomers } from '../components';
import {
  selectSearchResults,
  actions,
  selectSearchIsLoading,
  selectCfaOneGuestCFAId,
  selectPastAndFavoritesLoading,
  selectPastAndFavoriteOrders,
  selectFavoritesLoading,
  selectFavoritesError,
  selectFavoriteActionSuccessful,
  selectPastOrFavoriteError,
  selectFavoriteSuggestedName,
  selectCurrentFavoriteNames,
  selectLoadingMore,
  selectNoMorePastOrdersToLoad,
  selectEmptyCfaOneGuestPhone,
} from '../reducers/guest';
import { selectDisableGuestFields, selectDisableGuestPhone } from '../reducers';
import { selectEditMode, actions as orderActions } from '../reducers/order';
import EmptyCfaPhoneFieldWarningModal from '../components/Modal/EmptyCfaPhoneFieldWarningModal';

export function Guest({
  searchResults,
  guestSearch,
  guestSearchEmail,
  guestSearchPhone,
  guestSelected,
  searchIsLoading,
  cfaOneGuest,
  masqueradeGuestUnselected,
  disableGuestPhone,
  disableGuestFields,
  editMode,
  pastAndFavoritesLoading,
  addToFavorites,
  pastAndFavoriteOrders,
  removeFromFavorites,
  updateFavoriteName,
  favoritesLoading,
  favoritesError,
  resetFavoriteActionErrors,
  favoriteActionSuccessful,
  pastOrFavoriteError,
  initiateEditOrder,
  getMorePastOrders,
  loadingMore,
  noMorePastOrdersToLoad,
  suggestedFavoriteName,
  currentFavoriteNames,
  isEmptyCfaGuestPhoneField,
  closeEmptyCfaPhoneFieldWarning,
}) {
  const resultsLength = searchResults.length;
  const [showingModal, setShowingModal] = useState(false);
  const closeModal = () => {
    setShowingModal(false);
    closeEmptyCfaPhoneFieldWarning();
  };

  useEffect(() => {
    setShowingModal(isEmptyCfaGuestPhoneField);
  }, [isEmptyCfaGuestPhoneField]);
  return (
    <>
      <Section>
        <SectionHeader>Guest Information</SectionHeader>
        <GuestForm
          guestSearch={guestSearch}
          guestSearchEmail={guestSearchEmail}
          guestSearchPhone={guestSearchPhone}
          resultsLength={resultsLength}
          searchIsLoading={searchIsLoading}
          disableGuestPhone={disableGuestPhone}
          disableGuestFields={disableGuestFields}
          editMode={editMode}
        />
      </Section>
      <EmptyCfaPhoneFieldWarningModal isOpen={showingModal} onClose={closeModal} />
      { searchResults.length > 0 && (
      <Section>
        <SectionHeader mb="0">Suggested Customers</SectionHeader>
        { searchResults.map((customer, index) => (
          <SuggestedCustomers
            className="suggested-customers"
            customer={customer}
            /* eslint-disable-next-line */
            key={index}
            index={index}
            guestSelected={guestSelected}
            cfaOneGuest={cfaOneGuest}
            addToFavorites={addToFavorites}
            currentFavoriteNames={currentFavoriteNames}
            favoriteActionSuccessful={favoriteActionSuccessful}
            favoritesError={favoritesError}
            favoritesLoading={favoritesLoading}
            initiateEditOrder={initiateEditOrder}
            loadingMore={loadingMore}
            loadMorePastOrders={getMorePastOrders}
            masqueradeGuestUnselected={masqueradeGuestUnselected}
            noMorePastOrdersToLoad={noMorePastOrdersToLoad}
            pastAndFavoriteOrders={pastAndFavoriteOrders}
            pastAndFavoritesLoading={pastAndFavoritesLoading}
            pastOrdersLoading={pastAndFavoritesLoading}
            pastOrFavoriteError={pastOrFavoriteError}
            removeFromFavorites={removeFromFavorites}
            resetFavoriteActionErrors={resetFavoriteActionErrors}
            suggestedFavoriteName={suggestedFavoriteName}
            updateFavoriteName={updateFavoriteName}
          />
        ))}
      </Section>
      )}
    </>
  );
}

Guest.propTypes = {
  addToFavorites: PropTypes.func.isRequired,
  cfaOneGuest: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
  disableGuestFields: PropTypes.bool,
  disableGuestPhone: PropTypes.bool,
  editMode: PropTypes.bool,
  favoriteActionSuccessful: PropTypes.bool,
  favoritesError: PropTypes.string,
  favoritesLoading: PropTypes.bool,
  getMorePastOrders: PropTypes.func.isRequired,
  guestSearch: PropTypes.func.isRequired,
  guestSearchEmail: PropTypes.func.isRequired,
  guestSearchPhone: PropTypes.func.isRequired,
  guestSelected: PropTypes.func.isRequired,
  initiateEditOrder: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool,
  masqueradeGuestUnselected: PropTypes.func.isRequired,
  noMorePastOrdersToLoad: PropTypes.bool,
  pastAndFavoriteOrders: PropTypes.arrayOf(PropTypes.any),
  pastAndFavoritesLoading: PropTypes.bool,
  pastOrFavoriteError: PropTypes.bool,
  removeFromFavorites: PropTypes.func.isRequired,
  resetFavoriteActionErrors: PropTypes.func.isRequired,
  searchIsLoading: PropTypes.bool,
  searchResults: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  suggestedFavoriteName: PropTypes.string,
  updateFavoriteName: PropTypes.func.isRequired,
  isEmptyCfaGuestPhoneField: PropTypes.bool,
  closeEmptyCfaPhoneFieldWarning: PropTypes.func,
};

Guest.defaultProps = {
  cfaOneGuest: '',
  currentFavoriteNames: [],
  disableGuestFields: false,
  disableGuestPhone: false,
  editMode: false,
  favoriteActionSuccessful: false,
  favoritesError: null,
  favoritesLoading: false,
  loadingMore: false,
  noMorePastOrdersToLoad: false,
  pastAndFavoriteOrders: [],
  pastAndFavoritesLoading: false,
  pastOrFavoriteError: false,
  searchIsLoading: false,
  searchResults: [],
  suggestedFavoriteName: '',
  isEmptyCfaGuestPhoneField: false,
  closeEmptyCfaPhoneFieldWarning: () => {},
};

function mapStateToProps(state) {
  return {
    cfaOneGuest: selectCfaOneGuestCFAId(state),
    currentFavoriteNames: selectCurrentFavoriteNames(state),
    disableGuestFields: selectDisableGuestFields(state),
    disableGuestPhone: selectDisableGuestPhone(state),
    editMode: selectEditMode(state),
    favoriteActionSuccessful: selectFavoriteActionSuccessful(state),
    favoritesError: selectFavoritesError(state),
    favoritesLoading: selectFavoritesLoading(state),
    loadingMore: selectLoadingMore(state),
    noMorePastOrdersToLoad: selectNoMorePastOrdersToLoad(state),
    pastAndFavoriteOrders: selectPastAndFavoriteOrders(state),
    pastAndFavoritesLoading: selectPastAndFavoritesLoading(state),
    pastOrFavoriteError: selectPastOrFavoriteError(state),
    searchIsLoading: selectSearchIsLoading(state),
    searchResults: selectSearchResults(state),
    suggestedFavoriteName: selectFavoriteSuggestedName(state),
    isEmptyCfaGuestPhoneField: selectEmptyCfaOneGuestPhone(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...actions, ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Guest);
