import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { contains, keys } from 'ramda';
import ooe from '../../constants';
import { keys as orderKeys } from '../../reducers/order';
// eslint-disable-next-line import/no-cycle
import { Icon } from '../index';
import {
  capitalizeFirstLetter,
} from '../../util/format';

function ErrorMessage({
  errors,
  dismissError,
}) {
  function errorMessage(error) {
    return capitalizeFirstLetter(error.replace(ooe.DISMISSIBLE, ''));
  }
  function errorClassName(error) {
    const className = error.split(':')[0].toLowerCase();
    return ['error', 'warning'].includes(className) ? className : 'error';
  }
  return (
    <StyledErrorMessage>
      {keys(errors)
        .filter((errorKey) => (typeof errors[errorKey] === 'string'))
        .filter((errorKey) => errors[errorKey] !== 'SyntaxError: Unexpected end of JSON input')
        .map((errorKey) => (
          <div className={errorClassName(errors[errorKey])} key={errorKey}>
            <div className="icon-error">
              <Icon>{errorClassName(errors[errorKey])}</Icon>
              {errorMessage(errors[errorKey])}
            </div>
            {contains(ooe.DISMISSIBLE, errors[errorKey])
            && (
            <button
              className="close-button"
              onClick={() => {
                /* istanbul ignore if */
                if (errorKey === orderKeys.EDIT_ORDER) {
                  // eslint-disable-next-line no-alert
                  if (window.confirm('Are you sure you want to stop editing this order?')) {
                    return dismissError(errorKey);
                  }
                  return false;
                }
                return dismissError(errorKey);
              }}
            >
              <Icon className="close">close</Icon>
            </button>
            ) }
          </div>
        ))}
    </StyledErrorMessage>
  );
}

const StyledErrorMessage = styled.div`
  position: sticky;
  top: 82px;
  width: 100%;
  z-index: 1000;
  
  & > div {
    font: ${(props) => props.theme.smallBoldFont};
    margin: 7px;
    padding: 7px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  & .error {
    color: ${(props) => props.theme.error};
    border: 1px solid ${(props) => props.theme.error};
    background-color: #ffe8e6;
  }  
  
  & .warning {
    color: ${(props) => props.theme.warning};
    border: 1px solid ${(props) => props.theme.warning};
    background-color: #fffcee;
  }

  & .icon-error {
    flex: 40;
  }
  
  & .icon-error svg {
    width: 19px;
    float: left;
    max-height: 20px;
    margin: 0 5px;
  }
  
  & .close-button {
    flex: 1;
    background: transparent;
    border: transparent;
    width: 25px;
  }
  
  & .close-button svg {
    fill: ${(props) => props.theme.outline};
    margin: 0;
    width: 20px;
    height: 20px;
  }
  
  & .close-button:hover {
    cursor: pointer;
  }
`;

ErrorMessage.propTypes = {
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  dismissError: PropTypes.func,
};

ErrorMessage.defaultProps = {
  errors: {},
  dismissError: () => {},
};

export default ErrorMessage;
