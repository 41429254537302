import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from '@cfacorp/cowponents';
import ooeConstants from '../constants';
import { selectUserName, actions as userActions, selectLocationNumber } from '../reducers/user';
import { logPageView } from '../services/analytics';

export function More({
  userName,
  logoutUser,
  locationNumber,
}) {
  const emailDetails = ooeConstants.MAILTO_STRING(locationNumber);
  return (
    <StyledMore>
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href={ooeConstants.HELPFUL_LINKS.NEED_HELP}
        onClick={() => logPageView('more/Need Help')}
      >
        Need Help?
      </a>
      <NavLink to={{
        pathname: '/more/dashboard',
        title: 'Customer Dashboard',
      }}
      >
        <div className="link">Customer Dashboard</div>
      </NavLink>
      <SectionHeader />
      <div className="user">{userName}</div>
      <button className="link sign-out" onClick={logoutUser}>Sign Out</button>
      <SectionHeader />
      <div className="user">Helpful Links:</div>
      <div className="helpful-links">
        <a className="link" href={ooeConstants.HELPFUL_LINKS.ABOUT_CMT} target="_blank" rel="noopener noreferrer" onClick={() => logPageView('more/About CMT')}>About CMT</a>
      </div>
      <div className="helpful-links">
        <a className="link" href={ooeConstants.HELPFUL_LINKS.USER_GUIDE} target="_blank" rel="noopener noreferrer" onClick={() => logPageView('more/User Guide')}>User Guide</a>
      </div>
      <div className="helpful-links">
        <a className="link" href={ooeConstants.HELPFUL_LINKS.FAQ} target="_blank" rel="noopener noreferrer" onClick={() => logPageView('more/Frequently Asked Questions')}>Frequently Asked Questions</a>
      </div>
      <div className="helpful-links">
        <a className="link" href={emailDetails} rel="noopener noreferrer" onClick={() => logPageView('more/Give Feedback')}>Give Feedback</a>
      </div>
      <SectionHeader />
      <div className="version-number">{`Version ${ooeConstants.APP_VERSION}`}</div>
    </StyledMore>
  );
}

const StyledMore = styled(Section)`
  & .user, .version-number {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0 10px;
  }
  & .link {
    margin: 10px;
    padding: 0;
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.primary};
  }
  & .helpful-links {
    margin: 10px 0;
  }
  & a {
    text-decoration: none;
  }
  & .sign-out {
    background-color: ${(props) => props.theme.secondary};
    border: none;
  }
  & .link:hover {
    cursor: pointer;
  }
`;

More.propTypes = {
  userName: PropTypes.string.isRequired,
  logoutUser: PropTypes.func.isRequired,
  locationNumber: PropTypes.string,
};

More.defaultProps = {
  locationNumber: '',
};

function mapStateToProps(state) {
  return {
    userName: selectUserName(state),
    locationNumber: selectLocationNumber(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(More);
