import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, Flex, Box,
} from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import {
  EditCartItem,
  Modifiers,
} from '../index';
import open from '../Icon/icons/open.svg';
import close from '../Icon/icons/close.svg';
import { formatPrice } from '../../util/format';
import { ensureValueIsInteger, enterKeyPress } from '../../util/validate';
import ooeConstants from '../../constants';

class CartItem extends PureComponent {
  constructor(props) {
    super(props);
    const { editMode, item } = props;
    this.state = { editMode, quantity: item.quantity || 0 };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
  }

  handleQuantityChange(e) {
    const quantity = ensureValueIsInteger(e);
    this.setState({ quantity });
  }

  toggleEditMode() {
    const { editMode } = this.state;
    const { toggleEditMode } = this.props;
    this.setState({ editMode: !editMode });
    toggleEditMode(editMode);
  }

  render() {
    const {
      item,
      addModifier,
      updateModifierQuantity,
      deleteItem,
      updateSpecialInstructions,
      updateSideItem,
      updateDessertItem,
      addToCart,
      updateQuantity,
      makePromoFree,
      removePromoFree,
      isAdmin,
      isVca,
      showPromoFreeMaxQtyWarning,
    } = this.props;

    const { editMode, quantity: stateQuantity } = this.state;

    const onChangeHandler = (e) => {
        this.handleQuantityChange(e);
        if (e.target.value > 0) updateQuantity(+e.target.value);
    };

    const onBlurHandler = (e) => {
      if (e.target.value < 1) updateQuantity(+e.target.value);
    };

    const {
      tag, quantity, name, items, price, sideItems, selectedSide, dessertItems, selectedDessert, id, promoFree,
    } = item;
    const maxQty = (promoFree && showPromoFreeMaxQtyWarning) ? stateQuantity : 999;
    return (
      <StyledCartItem>
        <div className={editMode ? 'edit-mode' : ''}>
          <Flex className="item-box" justifyContent="space-between" mb="10px" key={tag}>
            <Box>
              <Input
                pl="7px"
                height="39px"
                m="0"
                width="50px"
                textAlign="center"
                value={stateQuantity}
                type="number"
                max={maxQty}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onKeyDown={(e) => enterKeyPress(e.keyCode, updateQuantity, stateQuantity)}
                data-cy={`quantity-${tag}`}
              />
              {!promoFree && <div className="max-warning" />}
              {promoFree && showPromoFreeMaxQtyWarning && <div className="max-promo-free-warning" />}
            </Box>
            <Flex
              flex="1"
              alignItems="center"
              onClick={this.toggleEditMode}
              className="click-wrapper"
              role="presentation"
            >
              <Box width="50%" m="0 20px" data-cy={`name-${tag}`}>
                {name}
              </Box>
              <Box width="30%" data-cy={`${item.tag}-price`}>
                {formatPrice(price)}
              </Box>
              <Box width="20%" data-cy={`${item.tag}-promofree`}>
                {promoFree && (
                  <div className="promo-free-indicator">Promo Free</div>
                )}
              </Box>
              <Box>
                <Button className="edit-button" data-cy={`edit-${tag}`}>
                  {editMode ? (
                    <img src={close} alt="Close Edit" />
                  ) : (
                    <img src={open} alt="Open Edit" />
                  )}
                </Button>
              </Box>
            </Flex>
          </Flex>
          <Modifiers item={item} side={selectedSide} dessert={selectedDessert} />
          {editMode && (
            <EditCartItem
              cartItem={item}
              itemTag={tag}
              freeSauces={item.freeSauces}
              specialInstructions={item.specialInstructions}
              editableItems={items}
              addToCart={addToCart}
              addModifier={addModifier}
              updateSpecialInstructions={updateSpecialInstructions}
              deleteItem={deleteItem}
              updateModifierQuantity={updateModifierQuantity}
              quantity={quantity}
              sideItems={sideItems}
              selectedSide={selectedSide}
              dessertItems={dessertItems}
              selectedDessert={selectedDessert}
              updateSideItem={updateSideItem}
              updateDessertItem={updateDessertItem}
              toggleEditMode={this.toggleEditMode}
              id={id}
              makePromoFree={makePromoFree}
              removePromoFree={removePromoFree}
              isPromoFree={promoFree}
              isAdmin={isAdmin}
              isVca={isVca}
            />
          )}
        </div>
      </StyledCartItem>
    );
  }
}

const StyledCartItem = styled.div`
  border-bottom: 2px dashed ${(props) => props.theme.disabled};
  padding: 20px 0 15px 0;
  font: ${(props) => props.theme.regularTextFont};
  & .item-box {
    position: relative;
  }

  & .max-warning,
  & .max-promo-free-warning {
    position: absolute;
    bottom: -17px;
    left: 0;
  }

  input:invalid + .max-warning:after,
  & .max-promo-free-warning:after {
    content: "999 max";
    font: ${(props) => props.theme.smallBoldFont};
    color: ${(props) => props.theme.error};
    padding-left: 2px;
  }

  & .max-promo-free-warning:after {
    content: "Total QTY of promo free items must be less than ${ooeConstants.MAX_PROMO_FREE_ITEM_QTY}";
  }

  & .edit-button {
    height: 39px;
    border: none;
    outline: none;
    background-color: ${(props) => props.theme.secondary};
  }

  & .edit-button img {
    width: 20px;
  }

  & textarea {
    padding: 7px 10px;
  }

  & .click-wrapper:hover {
    color: ${(props) => props.theme.accent};
    cursor: pointer;
    font: ${(props) => props.theme.regularBoldFont};
  }
`;

CartItem.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  deleteItem: PropTypes.func.isRequired,
  addModifier: PropTypes.func.isRequired,
  updateSpecialInstructions: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func,
  updateModifierQuantity: PropTypes.func.isRequired,
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  addToCart: PropTypes.func,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isAdmin: PropTypes.bool,
  isVca: PropTypes.bool,
  showPromoFreeMaxQtyWarning: PropTypes.bool,
};

CartItem.defaultProps = {
  toggleEditMode: () => {},
  updateQuantity: () => {},
  editMode: false,
  addToCart: () => {},
  makePromoFree: () => {},
  removePromoFree: () => {},
  isAdmin: false,
  isVca: false,
  showPromoFreeMaxQtyWarning: false,
};

export default CartItem;
