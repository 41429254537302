import styled from 'styled-components/macro';

const Toolbar = styled.div`
  font-family: ${props => props.theme.fontFamily};
  background-color: ${props => props.theme.background};
  font-size: 1em;
  width: 100%;
  min-width: 100%;
  height: 60px;
  min-height: 50px;
`;

export default Toolbar;
