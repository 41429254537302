import { NavLink } from 'react-router-dom';
import { Badge } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import { Icon } from '../index';

function CartLink({
  icon,
  cartItemCount,
  total,
  loading,
  dataCy,
}) {
  return (
    <StyledCartLink>
      <NavLink to={{ state: { modal: true } }}>
        <Badge
          className="badge"
          badgeContent={cartItemCount}
          data-cy={dataCy}
        >
          <Icon>{icon}</Icon>
          { loading
            ? <div className="total loading">Calculating</div>
            : <div className="total" data-cy="total">{total}</div> }
        </Badge>
      </NavLink>
    </StyledCartLink>
  );
}

const StyledCartLink = styled.div`
  position: relative;
  min-width: 55px;
  border-radius: 2px;

  & a {
    text-decoration: none;
  }
  & svg {
    fill: ${(props) => props.theme.primary};
  }
  & .badge {
    z-index: 200;
    display: unset;
  }
  & .badge > span {
    bottom: 32px;
    top: auto;
  }

  & .badge > div {
    top: 3px;
  }

  & .total {
    position: absolute;
    right: 10px; 
    bottom: 3px;
    color: ${(props) => props.theme.primary};
    font: ${(props) => props.theme.smallTextFont};
  }
  & .loading {
    right: -3px;
    color: ${(props) => props.theme.disabled};
  }
`;

CartLink.propTypes = {
  icon: PropTypes.string.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  total: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
};

CartLink.defaultProps = {
  dataCy: '',
};

export default CartLink;
