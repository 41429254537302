/* eslint-disable react/forbid-prop-types */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Box, Flex,
} from '@cfacorp/cowponents';
// eslint-disable-next-line import/no-cycle
import AddressWarningModal from '../Modal/AddressWarningModal';
import PastDeliveryAddresses from '../PastDeliveryAddresses/PastDeliveryAddresses';
import DestinationFormHeader from './DestinationFormHeader';

class DestinationForm extends PureComponent {
  autocompleteAddress() {
    const { deliveryAddress, autocompleteAddress } = this.props;
    const {
      streetNumber,
      route,
      subpremise,
      locality,
      administrativeAreaLevelOne,
      postalCode,
    } = deliveryAddress;
    const addressToValidate = `${streetNumber} ${route} ${subpremise} ${locality} ${administrativeAreaLevelOne} ${postalCode}`;
    if (streetNumber !== '' && route !== '' && postalCode !== '') {
      autocompleteAddress(addressToValidate);
    }
  }

  render() {
    const {
      updateUserAddress,
      deliveryAddress,
      showWarningModal,
      closeWarningModal,
      validateError,
      deliveryRange,
      availableLocations,
      updateUserLocation,
      lookupLocation,
      getPastDeliveryAddresses,
      pastOrders,
      pastDeliveryAddresses,
      pastDeliveryAddressesLoading,
      noPastDeliveryAddresses,
      autocompleteAddress,
      clearUserAddressFields,
      validateDeliveryRangeIsLoading,
      validateAddressIsLoading,
    } = this.props;
    const {
      streetNumber,
      route,
      subpremise,
      locality,
      administrativeAreaLevelOne,
      postalCode,
    } = deliveryAddress;
    return (
      <Flex flexWrap="wrap" flexDirection="column">
        <AddressWarningModal
          isOpen={showWarningModal}
          close={closeWarningModal}
          error={validateError}
          availableLocations={availableLocations}
          updateUserLocation={updateUserLocation}
          lookupLocation={lookupLocation}
        />
        <DestinationFormHeader
          deliveryRange={deliveryRange}
          clearUserAddressFields={clearUserAddressFields}
          deliveryAddress={deliveryAddress}
          validateError={validateError}
          validateAddressIsLoading={validateAddressIsLoading}
          validateDeliveryRangeIsLoading={validateDeliveryRangeIsLoading}
        />
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 6]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="Str Num (Req)"
              type="text"
              data-cy="address"
              maxLength="5"
              value={streetNumber == null ? '' : streetNumber}
              onBlur={() => this.autocompleteAddress('streetNumber')}
              onChange={(e) => updateUserAddress(e.target.value, 'streetNumber')}
            />
          </Box>
          <Box width={[1, 1 / 3]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="Street Address (Required)"
              type="text"
              data-cy="address"
              maxLength="40"
              value={route == null ? '' : route}
              onBlur={() => this.autocompleteAddress('route')}
              onChange={(e) => updateUserAddress(e.target.value, 'route')}
            />
          </Box>
          <Box width={[1, 1 / 2]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="Apartment / Suite"
              type="text"
              data-cy="aptNo"
              maxLength="40"
              value={subpremise == null ? '' : subpremise}
              onBlur={() => this.autocompleteAddress('subpremise')}
              onChange={(e) => updateUserAddress(e.target.value, 'subpremise')}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="City"
              type="text"
              data-cy="city"
              value={locality == null ? '' : locality}
              onBlur={() => this.autocompleteAddress('locality')}
              onChange={(e) => updateUserAddress(e.target.value, 'locality')}
            />
          </Box>
          <Box width={[1, 1 / 4]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="State"
              type="text"
              data-cy="state"
              value={administrativeAreaLevelOne == null ? '' : administrativeAreaLevelOne}
              onBlur={() => this.autocompleteAddress('administrativeAreaLevelOne')}
              onChange={(e) => updateUserAddress(e.target.value, 'administrativeAreaLevelOne')}
            />
          </Box>
          <Box width={[1, 1 / 4]}>
            <Input
              width="calc(100% - 14px)"
              placeholder="Zip (Required)"
              type="text"
              data-cy="zip"
              value={postalCode == null ? '' : postalCode}
              onBlur={() => this.autocompleteAddress('postalCode')}
              onChange={(e) => updateUserAddress(e.target.value, 'postalCode')}
            />
          </Box>
        </Flex>
        {pastOrders.length > 0 && (
          <PastDeliveryAddresses
            getPastDeliveryAddresses={getPastDeliveryAddresses}
            pastDeliveryAddresses={pastDeliveryAddresses}
            pastDeliveryAddressesLoading={pastDeliveryAddressesLoading}
            noPastDeliveryAddresses={noPastDeliveryAddresses}
            updateUserAddress={updateUserAddress}
            autocompleteAddress={autocompleteAddress}
          />
        )}
      </Flex>
    );
  }
}

DestinationForm.propTypes = {
  updateUserAddress: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  autocompleteAddress: PropTypes.func,
  validateError: PropTypes.objectOf(PropTypes.string),
  showWarningModal: PropTypes.bool,
  closeWarningModal: PropTypes.func,
  deliveryRange: PropTypes.number,
  availableLocations: PropTypes.arrayOf(PropTypes.object),
  updateUserLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
  getPastDeliveryAddresses: PropTypes.func,
  pastOrders: PropTypes.arrayOf(PropTypes.object),
  pastDeliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  pastDeliveryAddressesLoading: PropTypes.bool,
  noPastDeliveryAddresses: PropTypes.bool,
  clearUserAddressFields: PropTypes.func,
  validateDeliveryRangeIsLoading: PropTypes.bool,
  validateAddressIsLoading: PropTypes.bool,
};

DestinationForm.defaultProps = {
  deliveryAddress: {},
  validateError: null,
  showWarningModal: false,
  closeWarningModal: () => {},
  deliveryRange: 0,
  availableLocations: [],
  updateUserLocation: () => {},
  lookupLocation: () => {},
  autocompleteAddress: () => {},
  getPastDeliveryAddresses: () => {},
  pastOrders: [],
  pastDeliveryAddresses: [],
  pastDeliveryAddressesLoading: false,
  noPastDeliveryAddresses: false,
  clearUserAddressFields: () => {},
  validateDeliveryRangeIsLoading: false,
  validateAddressIsLoading: false,
};

export default DestinationForm;
