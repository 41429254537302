/* eslint-disable react/forbid-prop-types */
import { Component } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from './util/history';
import {
  Footer,
  UserLocation,
  CartLink,
  RightDesktop,
  Icon,
  Navigation,
  NavTab,
  Tabs,
  AddNewCard,
  Logo,
  ErrorMessage,
  Overlay,
  MoreTab,
  Eula,
  DeviceStatusMessage,
} from './components';
import {
  selectIsDeviceOnline,
  selectDisplayMessage,
} from './reducers/device';
import ooeConstants from './constants';
import theme from './theme';
import ConnectedMenu from './containers/Menu';
import ConnectedGuest from './containers/Guest';
import ConnectedEventDetails from './containers/EventDetails';
import ConnectedPayment from './containers/Payment';
import ConnectedCart from './containers/Cart';
import Dashboard from './containers/Dashboard';
import ConnectedSubmit from './containers/Submit';
import ConnectedFloatingMenu from './containers/FloatingMenu';
import More from './containers/More';
import { actions as menuActions } from './reducers/menu';
import { actions as formActions } from './reducers/form';
import {
  actions as userActions,
  selectUserLocations,
  selectUserName,
  selectLocationNumber,
  selectAppLoadingComplete,
  selectUserHasAgreedToEula,
  selectEulaText,
  selectOktaTokenError,
  selectShouldBypassBusinessRules,
  selectIsUserLoggedIn,
  selectLocationDropdownDisabled,
  isAdminUser as getIsAdminUserFromState,
  selectAllStoreNamesAndNumbers,
  selectShowHardcodedEulaText,
  selectDefaultLocation,
} from './reducers/user';
import { logPageView } from './services/analytics';
import {
  actions as guestActions,
} from './reducers/guest';
import { actions as dashboardActions } from './reducers/dashboard';
import { selectCartItemsLength } from './reducers/cart';
import {
  actions as orderActions,
  selectDestination,
  selectOrderIsLoading,
} from './reducers/order';
import { selectTaxAndTotal, selectErrorsAndWarnings } from './reducers';
import InitialLoad from './components/InitialLoad/InitialLoad';
import { getQueryVariable } from './util/routerHelpers';
import FullScreenError from './components/ErrorMessage/FullScreenError';

export class App extends Component {
  constructor(props) {
    super(props);
    this.previousLocation = props.location;
  }

  componentDidMount() {
    const { search, pathname, hash } = this.previousLocation;
    const routeToStore = `${pathname}${search}`;
    let storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);

    if (!storedRoute) {
      localStorage.setItem(ooeConstants.ROUTE_TO_REDIRECT_TO, routeToStore);
    }

    const { getTokenFromStorage } = this.props;
    getTokenFromStorage();

    storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    if ((storedRoute) && hash) {
      browserHistory.replace(storedRoute);
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    }

    if (getQueryVariable('phoneNumber')) {
      const { setCustomerPhoneNumber } = this.props;
      setCustomerPhoneNumber(getQueryVariable('phoneNumber'));
    }

    if (getQueryVariable('callId')) {
      const callSource = getQueryVariable('callSource') || ooeConstants.VCA_INKTEL;
      const { addExternalOrderId } = this.props;
      addExternalOrderId(getQueryVariable('callId'), callSource);
    }

    /* istanbul ignore next */
    browserHistory.listen((location) => {
      logPageView(location.pathname);
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = prevProps;
    const { history } = this.props;

    if (history.action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = prevProps.location;
    }
  }

  render() {
    const {
      cartItemCount,
      location,
      cartTotal,
      cartLoading,
      destination,
      userName,
      locations,
      updateUserLocation,
      selectedLocation,
      appLoadingComplete,
      history,
      errors,
      logoutUser,
      userHasAgreedToEula,
      eulaText,
      oktaError,
      throwFullScreenError,
      dismissError,
      isDeviceOnline,
      displayDeviceMessage,
      userIsLoggedIn,
      locationDropdownDisabled,
      shouldBypassBusinessRules,
      toggleBypass,
      lookupLocation,
      isAdminUser,
      allStoreNamesAndNumbers,
      addUserToAcknowledgement,
      isHardcodedEulaShown,
      defaultLocation,
    } = this.props;

    const { pathname } = location;
    const isModal = !!(
      location.state
      && location.state.modal
    );
    const isCardModal = !!(
      location.state
      && location.state.cardModal
    );

    /* istanbul ignore next */
    if (oktaError && userIsLoggedIn && (window.location.pathname !== '/error')) {
      throwFullScreenError();
    }

    const goBackState = {
      modal: false,
      cardModal: false,
      cartItemId: null,
    };

    return (
      <ThemeProvider theme={theme}>
        <>
          { (isModal || isCardModal)
            && <Overlay goBack={() => history.push({ state: goBackState })} /> }
          { appLoadingComplete && !userHasAgreedToEula && !window.Cypress
            && <Eula addUserToEula={addUserToAcknowledgement} isHardcodedEulaShown={isHardcodedEulaShown} eulaText={eulaText} logoutUser={logoutUser} /> }
          { (appLoadingComplete || (window.location.pathname === '/error'))
            && (
            <div style={{ minHeight: '97vh' }}>
              { /* istanbul ignore next */ }
              { displayDeviceMessage && <DeviceStatusMessage isDeviceOnline={isDeviceOnline} /> }
              <Navigation
                pathname={pathname}
                rightMobile={(
                  <CartLink
                    icon="bag"
                    cartItemCount={cartItemCount}
                    total={cartTotal}
                    loading={cartLoading}
                  />
                )}
                rightDesktop={
                    !oktaError
                      ? (
                        <RightDesktop
                          icon="bag"
                          userName={userName}
                          cartItemCount={cartItemCount}
                          total={cartTotal}
                          locations={locations}
                          loading={cartLoading}
                          updateLocation={updateUserLocation}
                          selectedLocation={selectedLocation}
                          locationDropdownDisabled={locationDropdownDisabled}
                          logoutUser={logoutUser}
                          shouldBypassBusinessRules={shouldBypassBusinessRules}
                          toggleBypass={toggleBypass}
                          isAdminUser={isAdminUser}
                          lookupLocation={lookupLocation}
                          allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                          defaultLocation={defaultLocation}
                        />
                      ) : null
                  }
                leftDesktop={<Logo />}
                leftMobile={
                    !oktaError
                      ? (
                        <UserLocation
                          locationDropdownDisabled={locationDropdownDisabled}
                          userName={userName}
                          updateLocation={updateUserLocation}
                          locations={locations}
                          selectedLocation={selectedLocation}
                          lookupLocation={lookupLocation}
                          allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                          defaultLocation={defaultLocation}
                        />
                      )
                      : null
                  }
              >
                <Tabs>
                  <NavTab
                    title="Guest"
                    icon={<Icon>man</Icon>}
                    to="/"
                    activeOnlyWhenExact
                  />
                  <NavTab
                    title="Details"
                    icon={<Icon>calendar</Icon>}
                    to="/event"
                    activeOnlyWhenExact
                  />
                  <NavTab
                    title="Menu"
                    icon={<Icon>sandwich</Icon>}
                    to="/menu"
                    activeOnlyWhenExact
                  />
                  <NavTab
                    title="Payment"
                    icon={<Icon>payment</Icon>}
                    to="/payment"
                    activeOnlyWhenExact
                  />
                  <MoreTab
                    title="More"
                    location={selectedLocation}
                    icon={<Icon>menu</Icon>}
                    to="/more"
                  />
                </Tabs>
              </Navigation>
              { errors && <ErrorMessage errors={errors} destination={destination} dismissError={dismissError} /> }
              <Switch location={isModal ? this.previousLocation : location}>
                <Route exact path="/" component={ConnectedGuest} />
                <Route exact path="/event" component={ConnectedEventDetails} />
                <Route exact path="/menu" component={ConnectedMenu} />
                <Route exact path="/payment" component={ConnectedPayment} />
                <Route exact path="/submit" component={ConnectedSubmit} />
                <Route exact path="/more/dashboard" component={Dashboard} />
                <Route exact path="/more" component={More} />
                <Route exact path="/error" component={FullScreenError} />
              </Switch>
              <AddNewCard history={history} visible={isCardModal} />
              <ConnectedFloatingMenu />
              <ConnectedCart visible={isModal} cartItemId={location.state && location.state.cartItemId} />
            </div>
            ) }

          { (!appLoadingComplete && (window.location.pathname !== '/error')) && <InitialLoad /> }
          <Footer>{`Version ${ooeConstants.APP_VERSION}`}</Footer>
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  addExternalOrderId: PropTypes.func.isRequired,
  setCustomerPhoneNumber: PropTypes.func.isRequired,
  getTokenFromStorage: PropTypes.func.isRequired,
  userName: PropTypes.string,
  cartItemCount: PropTypes.number.isRequired,
  cartTotal: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  cartLoading: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ])).isRequired,
  updateUserLocation: PropTypes.func,
  selectedLocation: PropTypes.string,
  appLoadingComplete: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  logoutUser: PropTypes.func,
  userHasAgreedToEula: PropTypes.bool,
  addUserToAcknowledgement: PropTypes.func.isRequired,
  eulaText: PropTypes.string,
  oktaError: PropTypes.bool,
  throwFullScreenError: PropTypes.func,
  destination: PropTypes.string,
  dismissError: PropTypes.func,
  isDeviceOnline: PropTypes.bool,
  displayDeviceMessage: PropTypes.bool,
  shouldBypassBusinessRules: PropTypes.bool,
  toggleBypass: PropTypes.func.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  locationDropdownDisabled: PropTypes.bool,
  lookupLocation: PropTypes.func,
  isAdminUser: PropTypes.bool,
  allStoreNamesAndNumbers: PropTypes.arrayOf(PropTypes.object),
  isHardcodedEulaShown: PropTypes.bool,
  defaultLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

App.defaultProps = {
  userName: '',
  cartLoading: false,
  cartTotal: '$0.00',
  updateUserLocation: () => {},
  selectedLocation: '',
  appLoadingComplete: false,
  errors: {},
  logoutUser: () => {},
  userHasAgreedToEula: true,
  eulaText: '',
  oktaError: false,
  throwFullScreenError: () => {},
  destination: 'Delivery',
  dismissError: () => {},
  isDeviceOnline: true,
  displayDeviceMessage: false,
  locationDropdownDisabled: false,
  lookupLocation: () => {},
  isAdminUser: false,
  shouldBypassBusinessRules: true,
  allStoreNamesAndNumbers: [],
  isHardcodedEulaShown: false,
  defaultLocation: false,
};

function mapStateToProps(state) {
  return {
    cartItemCount: selectCartItemsLength(state),
    cartTotal: selectTaxAndTotal(state),
    cartLoading: selectOrderIsLoading(state),
    destination: selectDestination(state),
    userName: selectUserName(state),
    locations: selectUserLocations(state),
    selectedLocation: selectLocationNumber(state),
    appLoadingComplete: selectAppLoadingComplete(state),
    errors: selectErrorsAndWarnings(state),
    userHasAgreedToEula: selectUserHasAgreedToEula(state),
    eulaText: selectEulaText(state),
    oktaError: selectOktaTokenError(state),
    isDeviceOnline: selectIsDeviceOnline(state),
    displayDeviceMessage: selectDisplayMessage(state),
    userIsLoggedIn: selectIsUserLoggedIn(state),
    locationDropdownDisabled: selectLocationDropdownDisabled(state),
    shouldBypassBusinessRules: selectShouldBypassBusinessRules(state),
    isAdminUser: getIsAdminUserFromState(state),
    allStoreNamesAndNumbers: selectAllStoreNamesAndNumbers(state),
    isHardcodedEulaShown: selectShowHardcodedEulaText(state),
    defaultLocation: selectDefaultLocation(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...menuActions,
    ...userActions,
    ...guestActions,
    ...formActions,
    ...orderActions,
    ...dashboardActions,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
