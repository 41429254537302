import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from '@cfacorp/cowponents';
import PaperGoodsToggle from './PaperGoodsToggle';

const PaperGoodsOptions = (
  {
    setPaperGoodsOptions,
    paperGoodsOptions,
  },
) => {
  const {
    plates, cutlery, napkins, cups,
  } = paperGoodsOptions;
  const [isPaletsToggled, setIsPaletsToggled] = useState(plates);
  const [isCutleryToggled, setIsCutleryToggled] = useState(cutlery);
  const [isNapkinsToggled, setIsNapkinsToggled] = useState(napkins);
  const [isCupsToggled, setIsCupsToggled] = useState(cups);

  useEffect(() => {
    setIsPaletsToggled(plates === undefined ? true : plates);
    setIsCutleryToggled(cutlery === undefined ? true : cutlery);
    setIsNapkinsToggled(napkins === undefined ? true : napkins);
    setIsCupsToggled(cups === undefined ? true : cups);
  }, [plates, cutlery, napkins]);

  const togglePaperGoodsHandler = utilType => {
    if (utilType === 'plates') setIsPaletsToggled(prev => !prev);
    if (utilType === 'cutlery') setIsCutleryToggled(prev => !prev);
    if (utilType === 'napkins') setIsNapkinsToggled(prev => !prev);
    if (utilType === 'cups') setIsCupsToggled(prev => !prev);
  };

  const setPaperGoodsOptionsHandler = (id, value) => {
    setPaperGoodsOptions(id, value);
  };

  return (
    <StyledPaperGoodsOptions>
      <Box>
        <Flex flexDirection="column">
          <PaperGoodsToggle label="Plates" id="plates" onTogglePaperGoods={togglePaperGoodsHandler} isToggledPaperGoods={isPaletsToggled} onSetPaperGoodsOptions={setPaperGoodsOptionsHandler} />
          <PaperGoodsToggle label="Cutlery" id="cutlery" onTogglePaperGoods={togglePaperGoodsHandler} isToggledPaperGoods={isCutleryToggled} onSetPaperGoodsOptions={setPaperGoodsOptionsHandler} />
          <PaperGoodsToggle label="Napkins" id="napkins" onTogglePaperGoods={togglePaperGoodsHandler} isToggledPaperGoods={isNapkinsToggled} onSetPaperGoodsOptions={setPaperGoodsOptionsHandler} />
          <PaperGoodsToggle label="Cups" id="cups" onTogglePaperGoods={togglePaperGoodsHandler} isToggledPaperGoods={isCupsToggled} onSetPaperGoodsOptions={setPaperGoodsOptionsHandler} />
        </Flex>
      </Box>
    </StyledPaperGoodsOptions>
  );
};

export const StyledPaperGoodsOptions = styled('div')`
  text-align: center;
  padding: 20px;
  max-width: 400px;
`;

PaperGoodsOptions.propTypes = {
  setPaperGoodsOptions: PropTypes.func,
  paperGoodsOptions: PropTypes.objectOf(PropTypes.bool),
};

PaperGoodsOptions.defaultProps = {
  setPaperGoodsOptions: () => {},
  paperGoodsOptions: {},
};

export default PaperGoodsOptions;
