import { Component } from 'react';
import Modal from 'react-modal';
import { Button } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { AreYouSure } from '../index';
import constants from '../../constants';

class Eula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      checked: false,
      confirm: false,
    };
    Modal.setAppElement('body');
  }

  handleAcceptClick() {
    const { addUserToEula, isHardcodedEulaShown } = this.props;
    if (isHardcodedEulaShown) {
      addUserToEula(constants.LOCAL_STORAGE_EULA);
      this.setState({ modalIsOpen: false });
    } else {
      addUserToEula(constants.EULA);
      this.setState({ modalIsOpen: false });
    }
  }

  handleDeclineClick() {
    const { logoutUser } = this.props;
    logoutUser();
    this.setState({ modalIsOpen: false });
  }

  toggleCheckbox() {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  }

  toggleConfirm() {
    const { confirm } = this.state;
    this.setState({ confirm: !confirm });
  }

  render() {
    const { className, eulaText } = this.props;
    const { checked, modalIsOpen, confirm } = this.state;
    const contentClassName = `${className}__content`;
    const overlayClassName = `${className}__overlay`;
    return (
      <div className={className}>
        <Modal
          portalClassName={className}
          className={contentClassName}
          overlayClassName={overlayClassName}
          isOpen={modalIsOpen}
          contentLabel="End User License Agreement"
          shouldCloseOnOverlayClick={false}
        >
          <h2 className="title">
            Confidentiality Notice:
            <br />
            Chick-fil-A Catering Management Tool
          </h2>
          {/*  eslint-disable-next-line */}
          <div dangerouslySetInnerHTML={{ __html: eulaText }} />
          { confirm
            ? (
              <AreYouSure
                message="Are you sure? You will not be able to access this application if you decline the agreement."
                confirm={() => this.toggleConfirm()}
                confirmText="Cancel"
                decline={() => this.handleDeclineClick()}
                declineText="Yes, Decline Agreement"
              />
            )
            : (
              <>
                <div className="checkbox">
                  <label htmlFor="agreeCheckbox">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      checked={checked}
                      onChange={() => this.toggleCheckbox()}
                    />
                    &quot;I have read, understand and agree to
                    the terms and conditions of this Notice.&quot;
                  </label>
                </div>
                <div className="buttons">
                  <Button
                    variant="transparent"
                    p="0.5em 1em"
                    m="0.5em"
                    bg="inherit"
                    border="none"
                    color="textColor"
                    onClick={() => this.toggleConfirm()}
                  >
                    Decline
                  </Button>
                  <Button
                    disabled={!checked}
                    onClick={() => this.handleAcceptClick()}
                    p="0.5em 1em"
                    m="0.5em"
                  >
                    Accept
                  </Button>
                </div>
              </>
            ) }
        </Modal>
      </div>
    );
  }
}

const StyledEula = styled(Eula)`
  &__overlay {
    position: absolute;
    overflow: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  &__content {
    top: 0;
    transform: translate(50%, 20%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    padding: 20px;
    font-size: 14px;
    font-family: ${(props) => props.theme.fontFamily};
    max-width: 50%;

    & h2, .buttons {
      text-align: center;
    }
    & p, .checkbox {
      margin: 20px;
    }
    & label {
      display: flex;
      align-items: center;
    }
    & label:hover {
      cursor: pointer;
    }
    & input {
      margin: 10px;
    }
    & .are_you_sure {
      text-align: center;
    }
    & .are_you_sure h3 {
      margin-bottom: 0;
    }
    & .are_you_sure p {
      margin: 10px auto; 
      font-weight: bold;
      max-width: 80%;
    }
  }
  
  @media(max-width: ${(props) => props.theme.phone}) {
    &__content {
      top: 0;
      left: 0;
      margin-right: 0;
      max-width: 100%;
      transform: none;
    }
  }
`;

Eula.propTypes = {
  className: PropTypes.string.isRequired,
  addUserToEula: PropTypes.func.isRequired,
  eulaText: PropTypes.string.isRequired,
  logoutUser: PropTypes.func.isRequired,
  isHardcodedEulaShown: PropTypes.bool,
};

Eula.defaultProps = {
  isHardcodedEulaShown: false,
};

export default StyledEula;
