import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import { Icon } from '../index';

function DashboardError(props) {
  const { detailViewErrors } = props;
  return detailViewErrors.map((errorMessage) => errorMessage && (
    <StyledDashboardError key={errorMessage}>
      <Icon className="icn">error</Icon>
      <div data-cy="dashboard-error">{errorMessage}</div>
    </StyledDashboardError>
  ));
}

/* istanbul ignore next */
const StyledDashboardError = styled.div`
  font: ${(props) => props.theme.smallBoldFont};
  margin: 10px;
  padding: 0 7px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.error};
  border: 1px solid ${(props) => props.theme.error};
  background-color: #ffe8e6;

  & .icn {
    min-width: 17px;
  }

  & svg {
    width: 17px;
    margin: 0 5px 0 0;
  }
`;

DashboardError.propTypes = {
  detailViewErrors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])).isRequired,
};

DashboardError.defaultProps = {};

export default DashboardError;
