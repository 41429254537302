import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
import { contains } from 'ramda';
import { FeatureFlagService } from '@cfacorp/web-pos-services';
// eslint-disable-next-line import/no-cycle
import {
  DashboardActionButton,
  AreYouSure,
  Icon,
  OrderExportExcel,
} from '../index';
import constants from '../../constants';

const showEditOrderButton = () => FeatureFlagService.variation('edit-order', false);
const showResendEmailButton = () => FeatureFlagService.variation('resend-payment-email', false);

export class DashboardActionsComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      showConfirm: false,
      confirmToShow: {},
    };
    this.toggleConfirmMessage = this.toggleConfirmMessage.bind(this);
    this.declineConfirm = this.declineConfirm.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleResendPaymentEmail = this.handleResendPaymentEmail.bind(this);
    this.handleEditOrder = this.handleEditOrder.bind(this);
  }

  handleCancelOrder() {
    const { cancelOrder, orderDetails } = this.props;
    const { id, cfaId } = orderDetails;
    cancelOrder(id, cfaId);
    this.setState({ showConfirm: false });
  }

  handleResendPaymentEmail() {
    const { resendPaymentEmail, orderDetails } = this.props;
    const { id, cfaId } = orderDetails;
    resendPaymentEmail(id, cfaId);
    this.setState({ showConfirm: false });
  }

  handleEditOrder() {
    const {
      initiateEditOrder,
      guestDetails,
      orderDetails,
    } = this.props;
    initiateEditOrder(orderDetails, guestDetails);
    this.setState({ showConfirm: false });
  }

  toggleConfirmMessage(type) {
    const { orderDetails } = this.props;
    const { showConfirm } = this.state;
    const { email } = orderDetails;
    const confirmTypes = {
      cancelConfirm: {
        message: constants.GET_DASHBOARD_CANCEL_MESSAGES.message,
        confirm: this.handleCancelOrder,
        confirmText: constants.GET_DASHBOARD_CANCEL_MESSAGES.confirmText,
        decline: this.declineConfirm,
        declineText: constants.GET_DASHBOARD_CANCEL_MESSAGES.declineText,
      },
      resendEmailConfirm: {
        message: `${constants.GET_DASHBOARD_RESEND_MESSAGES.message} ${email}?`,
        confirm: this.handleResendPaymentEmail,
        confirmText: constants.GET_DASHBOARD_RESEND_MESSAGES.confirmText,
        decline: this.declineConfirm,
        declineText: constants.GET_DASHBOARD_RESEND_MESSAGES.declineText,
      },
    };
    this.setState({
      showConfirm: !showConfirm,
      confirmToShow: confirmTypes[type],
    });
  }

  declineConfirm() {
    this.setState({
      showConfirm: false,
      confirmToShow: {},
    });
  }

  render() {
    const {
      dashboardActionLoading,
      tooLateToCancelOrEdit,
      orderDetails,
      paymentEmailResent,
      isPOSOrder,
      isErrorStatus,
      restaurantContactDetails,
    } = this.props;
    const { email, status } = orderDetails;
    const cancelled = contains(status, constants.CANCELLED);
    const showResendEmail = status === constants.PAYMENT_PENDING;
    const disable = tooLateToCancelOrEdit || isPOSOrder || isErrorStatus;
    const { showConfirm, confirmToShow } = this.state;
    const {
      message,
      confirm,
      confirmText,
      decline,
      declineText,
    } = confirmToShow;
    if (cancelled) {
      return (
        <DashboardActions>
          <div className="action-message">
            <Icon>error</Icon>
            {constants.GET_DASHBOARD_CANCEL_MESSAGES.cancelled}
          </div>
        </DashboardActions>
      );
    } if (paymentEmailResent) {
      return (
        <DashboardActions>
          <div className="action-message email">
            <Icon>success</Icon>
            {`${constants.GET_DASHBOARD_RESEND_MESSAGES.emailSent} ${email}`}
          </div>
        </DashboardActions>
      );
    } if (dashboardActionLoading) {
      return (
        <DashboardActions>
          <Spinner />
        </DashboardActions>
      );
    }
    return (
      <DashboardActions>
        {showConfirm
          ? (
            <AreYouSure
              message={message}
              confirm={confirm}
              confirmText={confirmText}
              decline={decline}
              declineText={declineText}
            />
          )
          : (
            <>
              {showEditOrderButton() && (
                <DashboardActionButton
                  className="edit-order"
                  icon="notepad"
                  buttonText="Edit Order"
                  onClick={this.handleEditOrder}
                  disabled={disable}
                />
              )}
              { showResendEmailButton() && showResendEmail && (
                <DashboardActionButton
                  className="payment-email"
                  data-cy="resend-button"
                  icon="email"
                  buttonText="Resend Payment Email"
                  onClick={() => this.toggleConfirmMessage('resendEmailConfirm')}
                  disabled={disable}
                />
              ) }
              <DashboardActionButton
                className="cancel-order"
                icon="delete"
                buttonText="Cancel Order"
                onClick={() => this.toggleConfirmMessage('cancelConfirm')}
                disabled={disable}
              />
              <OrderExportExcel
                orderDetails={orderDetails}
                restaurantContactDetails={restaurantContactDetails}
              />
            </>
          ) }
      </DashboardActions>
    );
  }
}

const DashboardActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  background-color: ${(props) => props.theme.background};
  
  & .action-message {
    height: 98px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  
  & .action-message svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }
  
  & .loading {
    width: 134px;
    height: 98px;
    align-self: center;
  }
  
  & .loading img {
    margin: 28px;
  }
  
  @media(max-width: 600px) {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`;

DashboardActionsComponent.propTypes = {
  tooLateToCancelOrEdit: PropTypes.bool,
  dashboardActionLoading: PropTypes.bool,
  cancelOrder: PropTypes.func,
  orderDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  resendPaymentEmail: PropTypes.func.isRequired,
  paymentEmailResent: PropTypes.bool,
  isPOSOrder: PropTypes.bool,
  isErrorStatus: PropTypes.bool,
  initiateEditOrder: PropTypes.func,
  guestDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
};

DashboardActionsComponent.defaultProps = {
  tooLateToCancelOrEdit: false,
  dashboardActionLoading: false,
  cancelOrder: () => {},
  orderDetails: {},
  guestDetails: {},
  paymentEmailResent: false,
  isPOSOrder: false,
  isErrorStatus: false,
  initiateEditOrder: () => {},
  restaurantContactDetails: {},
};
export default DashboardActionsComponent;
