import { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Input } from '@cfacorp/cowponents';

class ValidatedInput extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== '') {
      this.setState({ error: false });
    }
  }

  onInput() {
    const { value } = this.props;
    if (value !== '') {
      this.setState({ error: false });
    }
  }

  validateAndBlur() {
    const { onBlur, value } = this.props;
    if (value === '') {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
    onBlur();
  }

  render() {
    const {
      placeholder,
      type,
      value,
      onChange,
      dataCy,
    } = this.props;
    const { error } = this.state;
    return (
      <StyledInput>
        {error
          ? (
            <Input
              width="calc(100% - 14px)"
              autoComplete="new-password"
              className="error"
              placeholder={placeholder}
              type={type}
              data-cy={dataCy}
              onBlur={() => this.validateAndBlur()}
              onInput={() => this.onInput()}
              onChange={onChange}
              value={value}
            />
          )
          : (
            <Input
              width="calc(100% - 14px)"
              autoComplete="new-password"
              placeholder={placeholder}
              type={type}
              data-cy={dataCy}
              onBlur={() => this.validateAndBlur()}
              onInput={() => this.onInput()}
              onChange={onChange}
              value={value}
            />
          )}
        {error && <span className="error-message">Required Field</span>}
      </StyledInput>
    );
  }
}

const StyledInput = styled.div`  
  & .error {
    border: 2px solid ${(props) => props.theme.error};
  }
  
  & .error-message {
    color: ${(props) => props.theme.error};
    font-size: 12px;
    font-weight: bold;
    float: left;
    margin-left: 12px;
  }
`;

ValidatedInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  dataCy: PropTypes.string,
};

ValidatedInput.defaultProps = {
  placeholder: '',
  type: 'text',
  onBlur: () => {},
  onChange: () => {},
  dataCy: '',
};

export default ValidatedInput;
