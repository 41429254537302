/* eslint-disable react/forbid-prop-types */
import { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button, Section } from '@cfacorp/cowponents';

import {
  CartItem,
  EmptyCart,
  SlideModal,
  Totals,
} from '../components';
import { actions, selectShowMaxPromoFreeItemCountWarning, selectPromoFreeActive } from '../reducers/cart';
import { selectCartWithPrices, selectTaxAndTotal } from '../reducers';
import { selectFormattedSubTotalAmount, selectFormattedTaxAmount, selectOrderIsLoading } from '../reducers/order';
import { isAdminUser, isVcaUser } from '../reducers/user';

const StyledCartSection = styled(Section)`
  padding-top: 15px !important;
  & .all-totals {
    padding-top: 15px;
  }
`;

const CheckoutButton = styled(Button)`
  min-width: 50%;
  margin: 40px auto;
  display: block;
`;

export class Cart extends PureComponent {
  constructor() {
    super();
    this.ensureActiveItemVisible = this.ensureActiveItemVisible.bind(this);
  }

  componentDidMount() {
    this.ensureActiveItemVisible();
  }

  componentDidUpdate(prevProps) {
    // only scroll into view if the active item changed last render
    /* istanbul ignore if */
    const { cartItemId } = this.props;
    if (cartItemId !== prevProps.cartItemId) {
      this.ensureActiveItemVisible();
    }
  }

  ensureActiveItemVisible() {
    /* istanbul ignore if */
    if (this.activeNode && this.activeNode.scrollIntoView) {
      this.activeNode.scrollIntoView({ block: 'center' });
    }
  }

  renderCartItem = (item) => {
    const {
      updateQuantity,
      cartItemId,
      updateSpecialInstructions,
      addModifier,
      updateModifierQuantity,
      deleteItem,
      updateSideItem,
      updateDessertItem,
      addToCart,
      makePromoFree,
      removePromoFree,
      isAdmin,
      isVca,
      showPromoFreeMaxQtyWarning,
    } = this.props;
    const { id } = item;
    const active = id === cartItemId;
    const itemProps = {
      item,
      items: item.items,
      addModifier,
      updateModifierQuantity,
      editMode: active,
      updateSpecialInstructions: (instr) => updateSpecialInstructions(id, instr),
      updateQuantity: (qty) => updateQuantity(id, qty),
      deleteItem: () => deleteItem(id),
      updateSideItem,
      updateDessertItem,
      addToCart,
      id,
      makePromoFree: () => makePromoFree(id),
      removePromoFree: () => removePromoFree(id),
      isAdmin,
      isVca,
      showPromoFreeMaxQtyWarning,
    };
    const props = { key: id };
    /* istanbul ignore if */
    if (active) {
      props.ref = (node) => {
        this.activeNode = node;
      };
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div {...props} key={id + active}><CartItem {...itemProps} /></div>;
  }

  render() {
    const {
      cartItems,
      subTotal,
      taxAndTotal,
      orderLoading,
      visible,
      promoFreeActive,
      taxAmount,
    } = this.props;

    return (
      <SlideModal
        title="Cart"
        visible={visible}
        right={(
          <NavLink to={{
            state: {
              modal: false,
              cartItemId: null,
            },
          }}
          >
            <Button fontSize="15px" className="close-cart" variant="transparent" secondary data-cy="done">Close</Button>
          </NavLink>
        )}
      >
        {cartItems.length > 0
          ? (
            <StyledCartSection>
              {cartItems.map(this.renderCartItem)}
              <Totals
                subTotal={subTotal}
                taxAndTotal={taxAndTotal}
                isLoading={orderLoading}
                promoFreeActive={promoFreeActive}
                taxAmount={taxAmount}
              />
              <NavLink to={{ pathname: '/payment', state: { modal: false } }} style={{ textDecoration: 'none' }}>
                <CheckoutButton className="check-out-button" data-cy="check-out-button" variant="primary">Check Out</CheckoutButton>
              </NavLink>
            </StyledCartSection>
          )
          : <EmptyCart className="empty-cart" message="Cart Empty" />}
      </SlideModal>
    );
  }
}

Cart.propTypes = {
  updateQuantity: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  addModifier: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  updateModifierQuantity: PropTypes.func.isRequired,
  updateSpecialInstructions: PropTypes.func.isRequired,
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.any),
  subTotal: PropTypes.string,
  cartItemId: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: PropTypes.bool,
  visible: PropTypes.bool,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isAdmin: PropTypes.bool,
  isVca: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  showPromoFreeMaxQtyWarning: PropTypes.bool,
  taxAmount: PropTypes.string,
};

Cart.defaultProps = {
  cartItems: [],
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: false,
  cartItemId: null,
  visible: false,
  makePromoFree: () => {},
  removePromoFree: () => {},
  isAdmin: false,
  isVca: false,
  promoFreeActive: false,
  showPromoFreeMaxQtyWarning: false,
  taxAmount: '',
};

function mapStateToProps(state) {
  return {
    cartItems: selectCartWithPrices(state),
    subTotal: selectFormattedSubTotalAmount(state),
    taxAndTotal: selectTaxAndTotal(state),
    orderLoading: selectOrderIsLoading(state),
    isAdmin: isAdminUser(state),
    isVca: isVcaUser(state),
    promoFreeActive: selectPromoFreeActive(state),
    showPromoFreeMaxQtyWarning: selectShowMaxPromoFreeItemCountWarning(state),
    taxAmount: selectFormattedTaxAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
