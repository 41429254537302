import moment from 'moment';
import { ofType } from 'redux-observable';
import {
  filter,
  switchMap,
  take,
  mapTo,
  tap,
  ignoreElements,
} from 'rxjs/operators';
import {
  initialize,
  destroy,
  change,
} from 'redux-form';
import { of } from 'rxjs';
import ReduxStore from '../util/reduxStore';
import normalizePhone from '../util/normalizePhone';
import { types as guestTypes } from '../reducers/guest';
import { selectBypassBusinessRules, types as userTypes } from '../reducers/user';
import { actions, types } from '../reducers/form';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import constants from '../constants';

export const ResetDateFormValues = (action$) => action$
  .pipe(
    ofType(
      types.RESET_DATE_FORM_VALUES,
    ),
    tap(() => {
      /* istanbul ignore next */
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.DETAILS, 'date', ''));
    }),
    ignoreElements(),
  );

export const ResetTimeFormValues = (action$) => action$
  .pipe(
    ofType(
      types.RESET_TIME_FORM_VALUES,
    ),
    tap(() => {
      /* istanbul ignore next */
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.DETAILS, 'time', ''));
    }),
    ignoreElements(),
  );

export const SetCustomerPhoneNumber = (action$) => action$
  .pipe(
    ofType(
      types.SET_CUSTOMER_PHONE_NUMBER,
    ),
    tap((action) => {
      /* istanbul ignore next */
      const { phoneNumber } = action;
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.GUEST, 'phone', normalizePhone(phoneNumber)));
    }),
    ignoreElements(),
  );

export const ResetDateTimeForLocationChange = (action$, store) => action$
  .pipe(
    ofType(
      orderTypes.CHANGE_DESTINATION,
      userTypes.UPDATE_USER_LOCATION,
    ),
    // Do not reset date and time if user has bypassBusinessRules
    filter(() => {
      const state = store.value;
      return !selectBypassBusinessRules(state);
    }),
    switchMap(() => ([
      actions.resetTimeFormValues(),
      actions.resetDateFormValues(),
    ])),
  );

export const ClearTime = (action$, store) => action$
  .pipe(
    ofType(orderTypes.DATE_CHANGED),
    // Do not clear time slots when date changes if user has bypassBusinessRules
    filter(() => {
      const state = store.value;
      return !selectBypassBusinessRules(state);
    }),
    mapTo(actions.resetTimeFormValues()),
  );

export const EditOrder = (action$) => action$
  .pipe(
    ofType(
      orderTypes.INITIATE_EDIT_ORDER,
    ),
    // Wait for an API key so that subsequent requests don't fail
    switchMap((action) => {
      if (action.reorder !== true) {
        return action$
          .pipe(
            ofType(guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS),
            take(1),
            mapTo(action),
          );
      }
      return of(action);
    }),
    switchMap(({ guest = {}, order = {}, reorder = false }) => {
      const {
        first,
        last,
        firstName,
        lastName,
        phone,
        email,
      } = guest;
      const {
        paperGoods,
        guestCount,
        specialInstructions,
        promiseDateTime,
        payment,
        status,
        deliveryAddress,
        destination,
        secondaryContact,
        cateringReason,
      } = order;
      const showSpecialInstructions = reorder ? '' : specialInstructions;
      const { paymentType } = payment || {};
      let selectedMethod;
      if (status === constants.PAYMENT_PENDING) {
        /* istanbul ignore next */
        selectedMethod = constants.REQUEST_PAYMENT;
      } else if (paymentType === constants.GET_PAYMENT_TYPES.ACCOUNT) {
        selectedMethod = constants.CREDIT;
      } else if (paymentType === constants.GET_PAYMENT_TYPES.TO_BE_COLLECTED) {
        selectedMethod = constants.DEFER;
      }
      const date = reorder ? null : moment(promiseDateTime);
      const time = reorder ? null : moment(promiseDateTime).format('HH:mm');
      const actionsToDispatch = [
        destroy(
          constants.GET_FORM_TYPES.GUEST,
          constants.GET_FORM_TYPES.DETAILS,
          constants.GET_FORM_TYPES.PAYMENT_METHOD,
        ),
        // Update guest form values
        initialize(constants.GET_FORM_TYPES.GUEST, {
          email,
          phone,
          first: (first || firstName),
          last: (last || lastName),
        }, { updateUnregisteredFields: true }),
        // Update details form values
        initialize(constants.GET_FORM_TYPES.DETAILS, {
          date,
          time,
          paperGoods,
          guestCount,
          specialInstructions: showSpecialInstructions,
          cateringReason,
        }, { updateUnregisteredFields: true }),
      ];
      if (secondaryContact) {
        actionsToDispatch.push(initialize(constants.GET_FORM_TYPES.SECONDARY_CONTACT, {
          firstName: secondaryContact?.firstName,
          lastName: secondaryContact?.lastName,
          phoneNumber: secondaryContact?.phoneNumber,
        }, { updateUnregisteredFields: true }));
      }
      /* istanbul ignore if */
      if (!reorder) {
        // Select correct payment method
        actionsToDispatch.push(initialize(constants.GET_FORM_TYPES.PAYMENT_METHOD, {
          selectedMethod,
        }, { updateUnregisteredFields: true }));
      }
      // Only validate address if destination is Delivery and a delivery Address is available
      if (destination === constants.DELIVERY && deliveryAddress) {
        const {
          addressLine1,
          addressLine2,
          city,
          state,
        } = deliveryAddress;
        let addressToValidate = `${addressLine1} ${addressLine2} ${city} ${state}`;
        if (addressLine2 === null || addressLine2 === '' || addressLine2 === undefined) {
          addressToValidate = `${addressLine1} ${city} ${state}`;
        }
        actionsToDispatch.push(orderActions.autocompleteAddress(addressToValidate));
      }
      return actionsToDispatch;
    }),
  );

export const DestroyForms = (action$) => action$
  .pipe(
    ofType(orderTypes.EXIT_EDIT_ORDER),
    switchMap(() => [
      destroy('paymentMethod'),
      destroy('details'),
      destroy('guest'),
      destroy('secondaryContact'),
    ]),
  );

export default [
  ResetDateFormValues,
  ResetTimeFormValues,
  SetCustomerPhoneNumber,
  ResetDateTimeForLocationChange,
  ClearTime,
  EditOrder,
  DestroyForms,
];
