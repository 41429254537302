import { PureComponent } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Button } from '@cfacorp/cowponents';

class AreYouSure extends PureComponent {
  render() {
    const {
      message,
      confirm,
      confirmText,
      decline,
      declineText,
    } = this.props;
    return (
      <StyledAreYouSure>
        <div className="text">
          {message}
        </div>
        <div>
          <Button variant="transparent" fontSize={2} color="textColor" m="0.5em" p="0.5em 1em" onClick={() => decline()}>{declineText}</Button>
          <Button m="0.5em" p="0.5em 1em" fontSize={2} onClick={() => confirm()}>{confirmText}</Button>
        </div>
      </StyledAreYouSure>
    );
  }
}

const StyledAreYouSure = styled.div`
  width: calc(100% - 20px);
  padding: 10px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & .text {
   font: ${(props) => props.theme.regularBoldFont};
  }
`;

AreYouSure.propTypes = {
  message: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  decline: PropTypes.func.isRequired,
  declineText: PropTypes.string.isRequired,
};

export default AreYouSure;
