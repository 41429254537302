import moment from 'moment';
import { isEmpty, sort } from 'ramda';
import ooeConstants from '../constants';
import { isObjWithEmptyStrings } from './utils';

export function validateDestination(values) {
  const deliveryAddress = values.deliveryAddress === undefined ? {} : values.deliveryAddress;
  const errors = { deliveryAddress: {} };
  // TODO: consolidate this
  if (!deliveryAddress.addressLine1) {
    errors.deliveryAddress.addressLine1 = 'Required Field';
  }
  if (!deliveryAddress.city) {
    errors.deliveryAddress.city = 'Required Field';
  }
  if (!deliveryAddress.state) {
    errors.deliveryAddress.state = 'Required Field';
  }
  if (!deliveryAddress.zip) {
    errors.deliveryAddress.zip = 'Required Field';
  }
  return errors;
}

export function validateDetails(values) {
  const errors = {};
  if (!values.time) {
    errors.time = 'Required Field';
  }
  if (!values.date) {
    errors.date = 'Required Field';
  }
  if (values.guestCount && Number.isNaN(Number(values.guestCount))) {
    errors.guestCount = 'Please enter a number';
  }
  return errors;
}

export function validateSecondaryContact(values) {
  const areEmptyValues = isObjWithEmptyStrings(values);
  const errors = {};
  if (!isEmpty(values) && !areEmptyValues) {
    if (!values.firstName) errors.firstName = 'Required Field';
    if (!values.lastName) errors.lastName = 'Required Field';
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Required Field';
    } else if (values.phoneNumber && values.phoneNumber.length < 12) {
      errors.phoneNumber = 'Invalid Phone Number';
    }
  }
  return errors;
}

export const phoneRegex = /^(\+[1-9][0-9]*\s?)?([ 0-9()-]+(\s|$)){3}/i;

export function validateGuest(values) {
  const errors = {};
  if (!values.first) {
    errors.first = 'Required Field';
  }
  if (!values.last) {
    errors.last = 'Required Field';
  }
  if (!values.email) {
    errors.email = 'Required Field';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.phone) {
    errors.phone = 'Required Field';
  } else if (values.phone && !phoneRegex.test(values.phone)) {
    errors.phone = 'Please enter valid phone number';
  }
  return errors;
}

export function validateTaxId(values) {
  const errors = {};
  if (!values.taxId) {
    errors.taxId = 'Required Field';
  } else if (values.taxId && !/^\d{2}[-]\d{7}$/i.test(values.taxId)) {
    errors.taxId = 'Please enter Tax ID in format xx-xxxxxxx';
  }
  return errors;
}

export function isExpired(card) {
  let expMonth = card.expirationMonth;
  if (card.expirationMonth.length < 2) {
    expMonth = `0${card.expirationMonth}`;
  }
  const lastDayofMonth = moment(`${expMonth}${card.expirationYear}`, 'MMYY').daysInMonth();
  const expirationDate = moment(`${expMonth}${lastDayofMonth}${card.expirationYear}`, 'MMDDYY').utc().add(1, 'days');
  const now = moment().utc();
  if (expirationDate.isBefore(now)) {
    return true;
  }
  return false;
}

export function ensureValueIsInteger(e) {
  let value = Number(e.target.value);
  if (Number(value) < 0) {
    value = 0;
  }
  return value.toFixed(0);
}

export function validateGuestCount(value) {
  if (value > 9999) {
    return 9999;
  }
  if (value < 0) {
    return 0;
  }
  return value;
}

export const isCaramel = subItem => subItem.tag === ooeConstants.CARAMEL_SAUCE;

export function enterKeyPress(key, action, value = null) {
  if (key === 13) {
    action(value);
  }
}

export function exactFavoriteMatches(currentNames, nameToCheck) {
  return currentNames.filter(currentName => currentName.trim() === nameToCheck.trim());
}

export function favoriteNameMatchesList(currentNames, generatedName) {
  const sortByNumber = (a, b) => {
    const numberA = Number(a.match(ooeConstants.FIND_NUMBER_REGEX));
    const numberB = Number(b.match(ooeConstants.FIND_NUMBER_REGEX));
    return numberA - numberB;
  };
  const nameRegex = RegExp(`^(${generatedName})( \\d+){0,1}$`);
  return sort(sortByNumber, currentNames.filter(favName => favName && favName.match(nameRegex)));
}
