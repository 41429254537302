import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex } from '@cfacorp/cowponents';
import { Field, reduxForm } from 'redux-form';
import { useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import {
  VaultedCards, Defer, Radio,
} from '../index';
import { validateTaxId } from '../../util/validate';
import NewPayment from './NewPayment';
import ooe from '../../constants';

export function PaymentOptions(props) {
  const {
    vaultedCards,
    cardsLoading,
    paymentMethod,
    change,
    // taxExempt,
    validateZip,
    zipLoading,
    cardSelected,
    hideNewPaymentOption,
    validateZipSuccess,
    hidePayLater,
    allItemsPromoFree,
  } = props;
  let { selectedMethod } = paymentMethod;
  useEffect(() => {
    if (!cardsLoading && !selectedMethod) {
      selectedMethod = vaultedCards.length > 0 ? ooe.CREDIT : ooe.DEFER;
      change('selectedMethod', selectedMethod);
    } else if (!selectedMethod && !allItemsPromoFree && vaultedCards.length > 0) {
      selectedMethod = ooe.CREDIT;
      change('selectedMethod', selectedMethod);
    } else if (allItemsPromoFree) {
      selectedMethod = ooe.DEFER;
      change('selectedMethod', selectedMethod);
    }
  }, [allItemsPromoFree, cardsLoading]);
  return (
    <StyledPaymentOptions>
      <Flex flexDirection="column">
        { vaultedCards.length > 0 && !allItemsPromoFree
        && (
        <Box className="radio-option" xs="12">
          <Field
            label={ooe.CREDIT}
            id={ooe.CREDIT}
            name="selectedMethod"
            component={Radio}
            checked={selectedMethod === ooe.CREDIT}
          />
          { selectedMethod === ooe.CREDIT
          && (
          <VaultedCards
            cardsLoading={cardsLoading}
            vaultedCards={vaultedCards}
            validateZip={validateZip}
            zipLoading={zipLoading}
            cardSelected={cardSelected}
            validateZipSuccess={validateZipSuccess}
          />
          ) }
        </Box>
        ) }
        { !hideNewPaymentOption && !allItemsPromoFree
        && (
          <Box className="radio-option" xs="12">
            <Field
              className="new-payment"
              label={ooe.REQUEST_PAYMENT}
              id={ooe.REQUEST_PAYMENT}
              name="selectedMethod"
              component={Radio}
              checked={selectedMethod === ooe.REQUEST_PAYMENT}
            />
            { selectedMethod === ooe.REQUEST_PAYMENT
            && <NewPayment /> }
          </Box>
        )}
        { (!hidePayLater || allItemsPromoFree)
        && (
          <Box className="radio-option" xs="12">
            <Field
              name="selectedMethod"
              label={ooe.DEFER}
              id={ooe.DEFER}
              component={Radio}
              checked={selectedMethod === ooe.DEFER}
              className="pay-later"
            />
            { selectedMethod === ooe.DEFER
            && <Defer className="defer" /> }
          </Box>
        )}
        { /* HIDE TAX EXEMPT TOGGLE FOR NOW
        <Column xs="12">
          <Field name="taxExempt" component={Toggle} label="Tax Exempt?" />
          { taxExempt &&
          <Field
            name="taxId"
            component={ValidatedField}
            type="text"
            placeholder="Tax Id Number (xx-xxxxxxx)"
            maxLength="10"
          /> }
        </Column>
        */ }
      </Flex>
    </StyledPaymentOptions>
  );
}

const StyledPaymentOptions = styled.div`
  clear: both;
  & label {
    display: flex;
    width: 100%;
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }
  & .radio-option {
    margin: 5px;
    float: left;
    text-align: center;
    width: 100%;
  }
`;

PaymentOptions.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  cardsLoading: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  change: PropTypes.func.isRequired,
  // taxExempt: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool.isRequired,
  cardSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  hideNewPaymentOption: PropTypes.bool,
  validateZipSuccess: PropTypes.func,
  hidePayLater: PropTypes.bool,
  allItemsPromoFree: PropTypes.bool,
};

PaymentOptions.defaultProps = {
  vaultedCards: null,
  // taxExempt: false,
  hideNewPaymentOption: false,
  validateZipSuccess: () => {},
  hidePayLater: false,
  allItemsPromoFree: false,
};

export default reduxForm({
  form: ooe.GET_FORM_TYPES.PAYMENT_METHOD,
  destroyOnUnmount: false,
  validate: validateTaxId,
  initialValues: { paymentMethod: '' },
})(PaymentOptions);
