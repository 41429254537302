/* eslint-disable react/forbid-prop-types */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@cfacorp/cowponents';
import { FeatureFlagService } from '@cfacorp/web-pos-services';
// eslint-disable-next-line import/no-cycle
import { PastOrdersCard, FavoriteOrder } from '../index';

const showReorderButton = () => FeatureFlagService.variation('reorder', false);

export class PastOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      orderId: null,
      favoriteOrderId: null,
      favoriteName: '',
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleAddToFavorites = this.handleAddToFavorites.bind(this);
    this.handleRemoveFromFavorites = this.handleRemoveFromFavorites.bind(this);
    this.handleUpdateFavoriteName = this.handleUpdateFavoriteName.bind(this);
  }

  handleAddToFavorites(favoriteName) {
    const { addToFavorites } = this.props;
    const { orderId } = this.state;
    addToFavorites(orderId, favoriteName);
  }

  handleRemoveFromFavorites() {
    const { removeFromFavorites } = this.props;
    const { favoriteOrderId } = this.state;
    removeFromFavorites(favoriteOrderId);
  }

  handleUpdateFavoriteName(favoriteName) {
    const { updateFavoriteName } = this.props;
    const { favoriteOrderId } = this.state;
    updateFavoriteName(favoriteOrderId, favoriteName);
  }

  openModal(id, favoriteOrderId, name) {
    this.setState({
      modalIsOpen: true,
      orderId: id,
      favoriteOrderId,
      favoriteName: name,
    });
  }

  closeModal() {
    const { resetFavoriteActionErrors } = this.props;
    this.setState({ modalIsOpen: false });
    // Reset action errors so user can try again when modal is re-opened
    resetFavoriteActionErrors();
  }

  render() {
    const {
      pastAndFavoriteOrders,
      favoritesLoading,
      favoritesError,
      favoriteActionSuccessful,
      initiateEditOrder,
      customer,
      suggestedFavoriteName,
      currentFavoriteNames,
    } = this.props;
    const { modalIsOpen, favoriteOrderId, favoriteName } = this.state;

    if (pastAndFavoriteOrders.length === 0) {
      return null;
    }

    return (
      <Flex justifyContent={['center', 'end']} flexWrap="wrap" m="20px 0">
        {pastAndFavoriteOrders.map((order, idx) => (
          <PastOrdersCard
            /* eslint-disable-next-line */
            key={`${order.id}-${idx}`}
            order={order}
            showReorderButton={showReorderButton}
            handleReorderClick={() => initiateEditOrder(order, customer, true)}
            openFavoriteModal={this.openModal}
          />
        ))}
        {
          modalIsOpen
          && (
            <FavoriteOrder
              close={this.closeModal}
              isOpen={modalIsOpen}
              isFavorite={favoriteOrderId !== undefined}
              addToFavorites={this.handleAddToFavorites}
              removeFromFavorites={this.handleRemoveFromFavorites}
              favoritesLoading={favoritesLoading}
              favoritesError={favoritesError}
              setFavoriteName={favoriteName}
              updateFavoriteName={this.handleUpdateFavoriteName}
              favoriteActionSuccessful={favoriteActionSuccessful}
              suggestedFavoriteName={suggestedFavoriteName}
              currentFavoriteNames={currentFavoriteNames}
            />
          )
        }
      </Flex>
    );
  }
}

PastOrders.propTypes = {
  pastAndFavoriteOrders: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  updateFavoriteName: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  favoriteActionSuccessful: PropTypes.bool,
  resetFavoriteActionErrors: PropTypes.func,
  customer: PropTypes.objectOf(
    PropTypes.any,
  ).isRequired,
  initiateEditOrder: PropTypes.func.isRequired,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
};

PastOrders.defaultProps = {
  pastAndFavoriteOrders: [],
  favoritesLoading: false,
  favoritesError: null,
  favoriteActionSuccessful: false,
  resetFavoriteActionErrors: () => {},
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
};

export default PastOrders;
