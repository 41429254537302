/* eslint-disable react/forbid-prop-types */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  Button,
  SectionHeader,
  ChickenSpinner,
  Section,
} from '@cfacorp/cowponents';
import {
  DashboardTable,
  EmptyCart,
} from '../components';
import {
  selectOrders,
  actions as dashboardActions,
  selectOrderToView,
  selectLookupOrderIsLoading,
  selectDashboardActionLoading,
  selectOrdersAreLoading,
  selectDetailViewErrors,
  selectOrdersError,
  selectTooLateToCancelOrEdit,
  selectPaymentEmailResent,
  selectOrderHistory,
  selectIsPOSOrder,
  selectIsErrorStatus,
  selectDashboardPastOrderLoading,
  selectExtractOrdersDataLoading,
  selectExtractOrdersData,
  selectAllowExcelDownload,
  selectExportEntireDaysExcelError,
  selectSpecificDays,
} from '../reducers/dashboard';
import { actions as orderActions } from '../reducers/order';
import { selectBypassBusinessRules, selectLocationContactDetails } from '../reducers/user';
import DashboardHeader from '../components/DashboardHeader/DashboardHeader';

const StyledSectionHeader = styled(SectionHeader)`
  position: relative;
`;

const capitalize = ([first, ...rest]) => (first ? first.toUpperCase() + rest.join('') : '');

export class Dashboard extends PureComponent {
  constructor() {
    super();
    this.state = {
      type: 'upcoming',
    };
  }

  onChangeHandler(e) {
    this.setState({ type: e.target.value.toLowerCase() });
  }

  onCalendarOrdersType(type) {
    this.setState({ type });
  }

  renderOrders(type) {
    const {
      tooLateToCancelOrEdit,
      ordersAreLoading,
      ordersError,
      getOrders,
      orders,
      loadMorePastOrders,
      dashboardPastOrdersLoading,
    } = this.props;

    const renderableOrders = orders.filter((order) => {
      const diff = moment(order.promiseDateTime).diff(moment().startOf('day'));
      if (type === 'upcoming') {
        return diff >= 0;
      }
      return diff < 0;
    });
    const noOrders = orders.length === 0;

    if (ordersAreLoading) {
      return (
        <div style={{ margin: '20vh auto' }}>
          <ChickenSpinner />
        </div>
      );
    }
    if (ordersError) {
      return (
        <>
          <StyledSectionHeader>Oops!</StyledSectionHeader>
          <p>
            There was a problem retrieving
            {` ${type} `}
            orders. Please try again or contact support.
          </p>
          <Button onClick={getOrders}>Try Again</Button>
        </>
      );
    }
    if (noOrders) {
      return <EmptyCart className="empty-cart" message={`No ${capitalize(type)} Orders`} />;
    }
    return (
      <>
        <DashboardTable
          {...this.props}
          type={type}
          orders={renderableOrders}
          tooLateToCancelOrEdit={type === 'past' ? true : tooLateToCancelOrEdit}
          loadMorePastOrders={loadMorePastOrders}
          dashboardPastOrdersLoading={dashboardPastOrdersLoading}
        />
      </>
    );
  }

  render() {
    const {
      getOrders,
      getOrdersForSpecificDays,
      getDataForExcelExtraction,
      orders,
      excelExtractData,
      allowExcelDownload,
      downloadExcel,
      exportExcelOrdersLoading,
      excelExtractionError,
      specificDays,
    } = this.props;
    const { type: stateType } = this.state;
    const isUpcoming = stateType === 'upcoming';
    return (
      <Section>
        <DashboardHeader
          getOrders={getOrders}
          onSwitchTabs={e => this.onChangeHandler(e)}
          getOrdersForSpecificDays={getOrdersForSpecificDays}
          onCalendarOrdersType={type => this.onCalendarOrdersType(type)}
          getDataForExcelExtraction={getDataForExcelExtraction}
          orders={orders}
          excelExtractData={excelExtractData}
          allowExcelDownload={allowExcelDownload}
          downloadExcel={downloadExcel}
          exportExcelOrdersLoading={exportExcelOrdersLoading}
          excelExtractionError={excelExtractionError}
          specificDays={specificDays}
        />
        <div style={isUpcoming ? {} : { display: 'none' }}>{ this.renderOrders('upcoming') }</div>
        <div style={isUpcoming ? { display: 'none' } : {}}>{ this.renderOrders('past') }</div>
      </Section>
    );
  }
}
Dashboard.propTypes = {
  tooLateToCancelOrEdit: PropTypes.bool,
  ordersAreLoading: PropTypes.bool,
  ordersError: PropTypes.bool,
  getOrders: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
  loadMorePastOrders: PropTypes.func,
  dashboardPastOrdersLoading: PropTypes.bool,
  getOrdersForSpecificDays: PropTypes.func,
  getDataForExcelExtraction: PropTypes.func,
  exportExcelOrdersLoading: PropTypes.bool,
  allowExcelDownload: PropTypes.bool,
  downloadExcel: PropTypes.func,
  excelExtractData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  excelExtractionError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  specificDays: PropTypes.string,
};
Dashboard.defaultProps = {
  orders: [],
  ordersError: false,
  ordersAreLoading: false,
  tooLateToCancelOrEdit: false,
  getOrders: () => {},
  loadMorePastOrders: () => {},
  dashboardPastOrdersLoading: false,
  getOrdersForSpecificDays: () => {},
  getDataForExcelExtraction: () => {},
  exportExcelOrdersLoading: false,
  excelExtractData: [],
  allowExcelDownload: false,
  downloadExcel: () => {},
  excelExtractionError: false,
  specificDays: '',
};

function mapStateToProps(state) {
  return {
    orders: selectOrders(state),
    orderToView: selectOrderToView(state),
    lookupOrderDetailsIsLoading: selectLookupOrderIsLoading(state),
    restaurantContactDetails: selectLocationContactDetails(state),
    dashboardActionLoading: selectDashboardActionLoading(state),
    ordersAreLoading: selectOrdersAreLoading(state),
    ordersError: selectOrdersError(state),
    detailViewErrors: selectDetailViewErrors(state),
    bypassBusinessRules: selectBypassBusinessRules(state),
    tooLateToCancelOrEdit: selectTooLateToCancelOrEdit(state),
    paymentEmailResent: selectPaymentEmailResent(state),
    orderHistory: selectOrderHistory(state),
    isPOSOrder: selectIsPOSOrder(state),
    isErrorStatus: selectIsErrorStatus(state),
    dashboardPastOrdersLoading: selectDashboardPastOrderLoading(state),
    exportExcelOrdersLoading: selectExtractOrdersDataLoading(state),
    excelExtractData: selectExtractOrdersData(state),
    allowExcelDownload: selectAllowExcelDownload(state),
    excelExtractionError: selectExportEntireDaysExcelError(state),
    specificDays: selectSpecificDays(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dashboardActions, ...orderActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
