import { from, empty } from 'rxjs';
import OktaAuth from '@okta/okta-auth-js';
import ooeConstants from '../constants';
import requestFactory from '../util/requestFactory';
import handleErrors from '../util/handleErrors';
import { locationErrorMessages } from '../util/customerErrorMessages';
import { isRedirectToHttpsNecessary } from '../util/redirectToHttpsIfNecessary';
import { notifyBugsnag, leaveBreadcrumb } from './bugsnag';

const cfaTokenKey = ooeConstants.LOCAL_STORAGE_CFA_KEY;
const oktaTokenKey = ooeConstants.LOCAL_STORAGE_OKTA_KEY;

/* istanbul ignore next */ export function tokenToPayload(token) {
  const payload = token.split('.')[1];
  return JSON.parse(atob(payload));
}

const saveToLocalStorage = (token, key = cfaTokenKey) => {
  localStorage.setItem(key, JSON.stringify(token));
  return token;
};

export function refreshOktaToken(refreshToken) {
  const url = ooeConstants.OKTA_EXCHANGE_URL;

  // Build form
  const form = new FormData();
  form.append('grant_type', 'urn:ietf:params:oauth:grant-type:refresh_token');
  form.append('subject_token_type', 'urn:ietf:params:oauth:token-type:jwt');
  form.append('refresh_token', refreshToken);
  const mapper = (auth) => {
    const payload = tokenToPayload(auth.access_token);
    return { ...payload, accessToken: auth.access_token };
  };

  leaveBreadcrumb('Refresh Okta Token', {
    url,
    method: 'POST',
  });

  return fetch(url, {
    method: 'POST',
    body: form,
  })
    .then(handleErrors)
    .then(mapper)
    .then(saveToLocalStorage);
}

export function exchangeOktaToken(idToken) {
  /* istanbul ignore if */
  if (!idToken) throw new Error('No exchange token');
  const url = ooeConstants.OKTA_EXCHANGE_URL;

  // Build form
  const form = new FormData();
  form.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
  form.append('subject_token_type', 'urn:ietf:params:oauth:token-type:jwt');
  form.append('subject_token', idToken);
  const mapper = (auth) => {
    const payload = tokenToPayload(auth.access_token);
    return { ...payload, accessToken: auth.access_token, refreshToken: auth.refresh_token };
  };

  leaveBreadcrumb('Exchange Okta Token', {
    url,
    method: 'POST',
    idToken: idToken?.slice?.(-6) ?? 'Unknown',
  });

  return fetch(url, {
    method: 'POST',
    body: form,
  })
    .then(handleErrors)
    .then(mapper)
    .then(saveToLocalStorage);
}

export function getOktaToken({
  authClientFactory = (config) => new OktaAuth(config),
  location = window.location,
} = {}) {
  localStorage.removeItem(cfaTokenKey);
  localStorage.removeItem(oktaTokenKey);
  localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
  localStorage.removeItem(ooeConstants.EULA);

  const cy = window.Cypress;
  /* istanbul ignore if */
  if (cy) {
    const authClient = new OktaAuth(ooeConstants.OKTA_CONFIG);
    return authClient.signIn({
      username: cy.env('CYPRESS_USER'),
      password: cy.env('CYPRESS_PASS'),
    })
      .then((transaction) => {
        const { sessionToken } = transaction;
        return authClient.token.getWithPopup({
          sessionToken,
        });
      });
  }

  const config = ooeConstants.OKTA_CONFIG;
  const authClient = authClientFactory(config);

  leaveBreadcrumb('Get Okta Token', {
    config,
  });

  /* istanbul ignore next */
  const request = authClient.token.parseFromUrl()
    .then((token) => saveToLocalStorage(token, oktaTokenKey))
    .catch((error) => {
      leaveBreadcrumb('Get Okta Token Exception Handler', {
        config,
        error,
      });

      if (error.errorCode !== ooeConstants.OKTA_INVALID_ERROR) {
        if (isRedirectToHttpsNecessary(location)) {
          leaveBreadcrumb('Get Okta Token RedirectToHttpsNecessary is true', {
            location,
          });
          return empty();
        }
        leaveBreadcrumb('Get Okta Token returning authclient.token.getWithRedirect', {
          location,
        });
        return authClient.token.getWithRedirect();
      }

      notifyBugsnag(ooeConstants.BUGSNAG_ERRORCLASS_OMT_CONFIG, {
        context: 'Get Okta Token',
        info: {
          config,
          error,
        },
      });
      throw new Error(error);
    });

  return request;
}

export function logoutUser() {
  const config = ooeConstants.OKTA_CONFIG;
  const authClient = new OktaAuth(config);
  authClient.tokenManager.remove(cfaTokenKey);
  localStorage.removeItem(cfaTokenKey);
  localStorage.removeItem(ooeConstants.EULA);

  leaveBreadcrumb('Logout User', {
    config,
  });

  /* istanbul ignore next */
  const signOutRequest = authClient.signOut()
    .then(() => {
      window.location.href = ooeConstants.LOGOUT_LINK;
    })
    .catch((error) => {
      notifyBugsnag(ooeConstants.BUGSNAG_ERRORCLASS_OMT_CONFIG, {
        context: 'Logout User',
        info: {
          config,
          error,
        },
      });
      // eslint-disable-next-line
      console.error(err);
      window.location.href = ooeConstants.LOGOUT_LINK;
    });

  return from(signOutRequest);
}

export function lookupLocationsByNumber(locationNumbers, accessToken) {
  const locString = locationNumbers.join(',');
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/search?locationNumbers=${locString}`;

  const mapper = (locations) => {
    /* istanbul ignore next */
    if (locations && locations.length > 0) {
      return locations;
    }
    throw new Error(locationErrorMessages.NO_LOCATIONS);
  };
  return requestFactory({
    url,
    mapper,
    auth: {
      type: 'JWTBearer',
      apiKey: accessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Lookup Location by Number',
      info: {
        locationNumbers,
      },
    },
  });
}

export /* istanbul ignore next */ function fetchVcaLocations() {
  const baseUrl = ooeConstants.URL.CMT_CONFIG;
  const url = `${baseUrl}/1.0/storeNumbers`;
  const mapper = (jsonObj) => {
    const { VCAMiamiStoreNumbers } = jsonObj;
    return [...VCAMiamiStoreNumbers];
  };

  leaveBreadcrumb('Fetch VCA Locations', {
    url,
  });

  // TODO: move over to an observable fetch call
  const request = fetch(url)
    .then(handleErrors)
    .then(mapper);
  return from(request);
}

export function getStoreNamesAndNumbersFromApi(accessToken) {
  const baseUrl = ooeConstants.URL.LOCATIONS;
  const url = `${baseUrl}/locations/4.0/locationSummary`;

  return requestFactory({
    url,
    auth: {
      type: 'JWTBearer',
      apiKey: accessToken,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_LOCATIONS,
      context: 'Get Store Names And Numbers',
    },
  });
}

export function checkMessagesOnLoad(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return requestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Check Messages On Load for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Check Messages On Load',
      info: {
        type,
      },
    },
  });
}

export function getMessageText(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}`;
  return requestFactory({
    url,
    method: 'GET',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Get Message Text for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Get Message Text',
      info: {
        type,
      },
    },
  });
}

export function addUserToMessageAcknowledgement(apiKey, type) {
  const baseUrl = ooeConstants.URL.PROFILE;
  const url = `${baseUrl}/messagesAcknowledgements/1.0/${type}/acknowledgement`;
  return requestFactory({
    url,
    method: 'POST',
    maxRetries: 0,
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      breadcrumb: `Add User To Message Acknowledgement for type ${type}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_PROFILE,
      context: 'Add User To Message Acknowledgement',
      info: {
        type,
      },
    },
  });
}

export default {
  exchangeOktaToken,
  getOktaToken,
  refreshOktaToken,
  logoutUser,
  lookupLocationsByNumber,
  fetchVcaLocations,
  checkMessagesOnLoad,
  getMessageText,
  addUserToMessageAcknowledgement,
  getStoreNamesAndNumbersFromApi,
};
