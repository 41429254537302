import {
  drop, dropLast, isEmpty, join, keys, pathOr, pluck, values,
} from 'ramda';
import moment from 'moment-timezone';
import ooe from '../constants';

export function formatPrice(price) {
  if (Number.isNaN(price) || !price) {
    return '$0.00';
  }
  return `$${parseFloat(price).toFixed(2)}`;
}

export function formatModifierPrice(price = 0.0) {
  if (price === 0.0) {
    return 'Free';
  }
  return `$${parseFloat(price).toFixed(2)}`;
}

export function formatItemName(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  const abbreviations = ooe.NAME_ABBREVIATIONS;
  return keys(abbreviations).reduce((acc, key) => {
    const replaceWith = abbreviations[key];
    const re = new RegExp(key, 'gi');
    return acc.replace(re, replaceWith);
  }, name);
}

export function formatModifier(mod) {
  const { action, modifierType } = mod;
  const { name } = mod;
  if (!name || typeof name !== 'string') {
    return '';
  } if (action === 'REMOVE' || modifierType === 'RECIPE') {
    return `No ${name}`;
  }
  return `${formatItemName(name)}`;
}

export function formatEventSummaryDate(date) {
  const formattedDate = moment(date, ooe.MOMENT_INPUT_FORMAT.dateTime)
    .format(ooe.MOMENT_OUTPUT_FORMAT.eventSummaryDate);
  return date ? formattedDate : <span className="required">Date*</span>;
}

export function formatEventSummaryTime(time) {
  const formattedTime = moment(time, ooe.MOMENT_INPUT_FORMAT.time)
    .format(ooe.MOMENT_OUTPUT_FORMAT.eventSummaryTime);
  return time ? formattedTime : <span className="required">Time*</span>;
}

export function formatTimePickerTime(time) {
  return moment(time, ooe.MOMENT_INPUT_FORMAT.time)
    .format(ooe.MOMENT_OUTPUT_FORMAT.eventSummaryTime);
}

export function formatLongDate(dateTime) {
  return moment(dateTime, ooe.MOMENT_INPUT_FORMAT.dateTime)
    .format(ooe.MOMENT_OUTPUT_FORMAT.longDate);
}

export function formatShortDate(dateTime) {
  return moment(dateTime, ooe.MOMENT_INPUT_FORMAT.dateTimeZone)
    .format(ooe.MOMENT_OUTPUT_FORMAT.shortDate);
}

export function formatReadableDate(dateTime) {
  return moment(dateTime, ooe.MOMENT_INPUT_FORMAT.date)
    .format(ooe.MOMENT_OUTPUT_FORMAT.readableDate);
}

export function formatPaper(paperGoods) {
  return paperGoods ? 'Please include plates, cutlery, napkins and cups. ' : 'Please do not include plates, cutlery, napkins or cups. ';
}

export function formatPaperGoodsOptions(paperGoods) {
  if (isEmpty(paperGoods)) return '';
  if (!isEmpty(paperGoods) && Object.values(paperGoods).some(opt => opt === true)) {
    const paperGoodsArr = Object.keys(paperGoods).filter(k => paperGoods[k]);
    if (paperGoodsArr.length === 2) {
      paperGoodsArr.splice(1, 0, ' and ');
    } else if (paperGoodsArr.length === 3) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    } else if (paperGoodsArr.length === 4) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(3, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    }
  return `Please include ${paperGoodsArr.join('')}.`;
 }
 return 'Please do not include plates, cutlery, napkins or cups.';
}

export function formatGuests(guestCount) {
  return guestCount ? (
    <div>
      <span className="bold">Guest count: </span>
      {guestCount}
    </div>
  )
    : <div><span className="bold">Guest count: </span></div>;
}

export function formatCity(city) {
  return city ? `${city}, ` : <span className="required">City*</span>;
}

export function formatPOSDeliveryAddress(address) {
  return join(' ')(drop(1, dropLast(1, values(address))));
}

export function formatField(field, title) {
  return field ? `${field}` : (
    <span className="required">
      {title}
      *
    </span>
  );
}

function validateEmail(email) {
  // Email validation RegEx found on SO.
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const re = /^[+]?[0-9]{0,3}[\s-]?(\d{3})[\s-](\d{3})[\s-](\d{4})/;
  return re.test(String(phone).toLowerCase());
}

export function formatPhone(phone) {
  return validatePhone(phone) ? `${phone}` : <span className="required">Phone Number*</span>;
}

export function formatEmail(email) {
  return validateEmail(email) ? `${email}` : <span className="required">Email*</span>;
}

export function capitalizeFirstLetter(input) {
  if (!input) return '';
  const [first, ...rest] = input;
  return first ? first.toUpperCase() + rest.join('') : '';
}

export function titleCase(string = '') {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function formatTierName(tier) {
  if (tier === 'Prospect') {
    return '';
  }
  return tier;
}

export function isTooLateToCancelOrEdit(promiseTime, bypassBusinessRules, timeWindow = 2) {
  if (bypassBusinessRules) {
    return false;
  }
  return moment(promiseTime).utc().isBefore(moment().add(timeWindow, 'hours'));
}

export function generateTimeSlots(dateIsToday, timeZone) {
  const timeSlots = [];
  let startTime = moment().set({ hour: 0, minute: 0 });
  let endTime = moment().set({ hour: 23, minute: 59 });
  if (dateIsToday) {
    const now = moment();
    const remainder = 15 - (now.minute() % 15);
    startTime = moment().tz(timeZone).add(remainder, 'minutes');
    endTime = now.set({ hour: 23, minute: 59 });
  }
  while (startTime <= endTime) {
    timeSlots.push({
      available: true,
      time: moment(startTime).format(ooe.MOMENT_INPUT_FORMAT.time),
    });
    startTime.add(15, 'minutes');
  }
  return timeSlots;
}

export function generateDateSlots(date, availableDates, bypassBusinessRules) {
  if (bypassBusinessRules) {
    const endDate = moment(new Date()).add('500', 'days');
    return date.isAfter(endDate);
  }
  const newAvailableDates = pluck('businessDate')(availableDates);
  const formattedDate = moment(date).format(ooe.MOMENT_INPUT_FORMAT.businessDate);
  return newAvailableDates.indexOf(formattedDate) === -1;
}

const findVitaminValue = (vitamins, vitaminName) => ` ${vitamins.find((vit) => vit.name === vitaminName).dailyValue || 0}`;

export function getNutritionValues(nutrition, type) {
  const {
    calories,
    protein,
    carbs,
    sugar,
    cholesterol,
    sodium,
    calcium,
    iron,
    fiber,
    fat,
    vitamins,
  } = nutrition;
  const nutritionalValues = {
    calories: pathOr(0, ['total'], calories),
    totalFat: pathOr(0, ['total', 'amount', 'count'], fat),
    saturatedFat: pathOr(0, ['saturatedFat', 'amount', 'count'], fat),
    transFat: pathOr(0, ['transFat', 'amount', 'count'], fat),
    cholesterol: pathOr(0, ['amount', 'count'], cholesterol),
    sodium: pathOr(0, ['amount', 'count'], sodium),
    carbs: pathOr(0, ['amount', 'count'], carbs),
    fiber: pathOr(0, ['amount', 'count'], fiber),
    sugar: pathOr(0, ['amount', 'count'], sugar),
    protein: pathOr(0, ['amount', 'count'], protein),
    vitaminA: vitamins ? findVitaminValue(vitamins, 'A') : 0,
    vitaminC: vitamins ? findVitaminValue(vitamins, 'C') : 0,
    calcium: pathOr(0, ['dailyValue'], calcium),
    iron: pathOr(0, ['dailyValue'], iron),
  };
  return nutritionalValues[type];
}

export const formatRestaurantLocalTime = (timezone) => moment.tz(moment(), timezone).format(ooe.MOMENT_OUTPUT_FORMAT.timeAmPm);
