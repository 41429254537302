/* eslint-disable react/jsx-one-expression-per-line */
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import {
  actions as userActions,
  selectDeliveryRange,
  selectPickupLeadTimeStoreInfo,
  selectDeliveryLeadTimeStoreInfo,
  isAdminUser,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectInStateDeliveryOnly,
  selectMaxSameDayOrderTotalAmountCents,
  selectRestaurantHoursOfOperation,
  selectCateringDeliveryHoursOfOperation,
  selectLocationContactDetails,
  selectLocationTimezone,
} from '../reducers/user';
import Icon from '../components/Icon';
import StoreInformation from '../components/StoreInformation/StoreInformation';
import { logEvent } from '../services/analytics';
import ooeConstants from '../constants';
import { selectAllDayChickenMinis, selectStoreInfoSpecialMessage } from '../reducers';

export const FloatingMenu = (props) => {
  const {
    pickupLeadTime,
    deliveryLeadTime,
    deliveryRange,
    minDeliveryAmount,
    minPickupAmount,
    maxDeliveryAmount,
    maxPickupAmount,
    maxSameDayOrderTotalAmountCents,
    inStateDeliveryOnly,
    isAdmin,
    allDayChickenMinis,
    storeInfoSpecialMessage,
    restaurantWorkingHours,
    cateringDeliveryWorkingHours,
    locationContactDetails,
    locationTimezone,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const storeInfoRef = useRef();

  const { restaurantPhone } = locationContactDetails;

  const toggleStoreInfoHandler = e => {
    e.preventDefault();
    if (isActive === true) {
      setIsActive(false);
    } else {
      logEvent(ooeConstants.AMPLITUDE_EVENT_NAMES.SHOW_STORE_INFO);
      setIsActive(true);
    }
  };

  return (
    <StyledFloatingMenu className="styled-floating">
      <StyledFloatingMenuContent className={!isActive ? '' : 'expanded'}>
        <StoreInformation
          pickupLeadTime={pickupLeadTime}
          deliveryLeadTime={deliveryLeadTime}
          deliveryRange={deliveryRange}
          isActive={isActive}
          ref={storeInfoRef}
          minDeliveryAmount={minDeliveryAmount}
          minPickupAmount={minPickupAmount}
          maxDeliveryAmount={maxDeliveryAmount}
          maxPickupAmount={maxPickupAmount}
          maxSameDayOrderTotalAmountCents={maxSameDayOrderTotalAmountCents}
          inStateDeliveryOnly={inStateDeliveryOnly}
          isAdmin={isAdmin}
          allDayChickenMinis={allDayChickenMinis}
          storeInfoSpecialMessage={storeInfoSpecialMessage}
          restaurantWorkingHours={restaurantWorkingHours}
          cateringDeliveryWorkingHours={cateringDeliveryWorkingHours}
          restaurantPhone={restaurantPhone}
          className="store-info"
          locationTimezone={locationTimezone}
        />
      </StyledFloatingMenuContent>
      {isActive && (
        <div className="under-mask" role="presentation" onClick={toggleStoreInfoHandler} />
      )}
      <button
        className={!isActive ? 'storeInfoBtn' : 'storeInfoBtn active'}
        onClick={toggleStoreInfoHandler}
      >
        <Icon height="12" width="12" margin="0 5px -1px 0">
          {isActive ? 'info' : 'info-white'}
        </Icon>
        Store Info
      </button>
    </StyledFloatingMenu>
  );
};

const slideUp = keyframes`
   0% {  max-height: 0; padding: 0 10px; visibility: hidden; }
  100% { max-height: calc(100vh - 184px); visibility: visible; }
`;
const slideDown = keyframes`
   0% {  max-height: calc(100vh - 184px); visibility: hidden; }
  100% { max-height: 0px; padding: 0 10px; visibility: visible; }
`;

export const StyledFloatingMenuContent = styled('div')`
  font: ${props => props.theme.regularTextFont};
  position: absolute;
  bottom: 38px;
  right: 0;
  width: 330px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0px;
  background-color: ${props => props.theme.backgroundBlur};
  border-radius: 10px;
  border-bottom-right-radius: 0;

  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 250ms;
  animation-name: ${slideDown};
  overflow: hidden;
  &.expanded {
    animation-name: ${slideUp};
      box-shadow: 0 0 10px 3px #00000026;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    background-color: ${props => props.theme.background};
    width: 300px;
    font-size: 14px;
  }
`;

export const StyledFloatingMenu = styled('div')`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 202;
  & .storeInfoBtn {
    padding: 10px 20px;
    background-color: ${(props) => props.theme.primary};
    border-radius: 30px;
    color: #fff;
    font: ${(props) => props.theme.regularTextFont};
    border: none;
    transition: border, color, 0.2s ease-in-out;
    transition-delay: 0.25s;
    position: relative;
    cursor: pointer;
    box-shadow: 0 0 10px #737373;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .storeInfoBtn.active {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.primary};
    border-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transition: border, color, 0.2s ease-in-out;
    box-shadow: 0 7px 9px 1px #00000026;
  }
  & .under-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  & .badge {
    font: ${(props) => props.theme.regularTextFont};
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 30px;
    position: absolute;
    top: -15px;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    @media (max-width: ${(props) => props.theme.small}) {
      right: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    bottom: 100px;
    right: 20px;
  }
  @media (max-width: ${(props) => props.theme.small}) {
    bottom: 80px;
    right: 0px;
  }
`;

FloatingMenu.propTypes = {
  pickupLeadTime: PropTypes.number,
  deliveryLeadTime: PropTypes.number,
  deliveryRange: PropTypes.number,
  minDeliveryAmount: PropTypes.number,
  minPickupAmount: PropTypes.number,
  maxDeliveryAmount: PropTypes.number,
  maxPickupAmount: PropTypes.number,
  maxSameDayOrderTotalAmountCents: PropTypes.number,
  inStateDeliveryOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  allDayChickenMinis: PropTypes.string,
  storeInfoSpecialMessage: PropTypes.string,
  restaurantWorkingHours: PropTypes.arrayOf(PropTypes.string),
  cateringDeliveryWorkingHours: PropTypes.arrayOf(PropTypes.string),
  locationContactDetails: PropTypes.objectOf(PropTypes.string),
  locationTimezone: PropTypes.string,
};

FloatingMenu.defaultProps = {
  pickupLeadTime: 0,
  deliveryLeadTime: 0,
  deliveryRange: 0,

  minDeliveryAmount: 0,
  minPickupAmount: 0,
  maxDeliveryAmount: 0,
  maxPickupAmount: 0,
  maxSameDayOrderTotalAmountCents: 0,
  inStateDeliveryOnly: false,
  isAdmin: false,
  allDayChickenMinis: '',
  storeInfoSpecialMessage: '',
  restaurantWorkingHours: [],
  cateringDeliveryWorkingHours: [],
  locationContactDetails: {},
  locationTimezone: '',
};

function mapStateToProps(state) {
  return {
    pickupLeadTime: selectPickupLeadTimeStoreInfo(state),
    deliveryLeadTime: selectDeliveryLeadTimeStoreInfo(state),
    deliveryRange: selectDeliveryRange(state),
    isAdmin: isAdminUser(state),

    minDeliveryAmount: selectMinDeliveryAmount(state),
    minPickupAmount: selectMinPickupAmount(state),
    maxDeliveryAmount: selectMaxDeliveryAmount(state),
    maxPickupAmount: selectMaxPickupAmount(state),
    maxSameDayOrderTotalAmountCents: selectMaxSameDayOrderTotalAmountCents(state),
    inStateDeliveryOnly: selectInStateDeliveryOnly(state),
    allDayChickenMinis: selectAllDayChickenMinis(state),
    storeInfoSpecialMessage: selectStoreInfoSpecialMessage(state),
    restaurantWorkingHours: selectRestaurantHoursOfOperation(state),
    cateringDeliveryWorkingHours: selectCateringDeliveryHoursOfOperation(state),
    locationContactDetails: selectLocationContactDetails(state),
    locationTimezone: selectLocationTimezone(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FloatingMenu);
