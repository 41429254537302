/* eslint-disable react/forbid-prop-types */
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

function AddressAutocomplete({
  availableAddresses,
  selectAddress,
}) {
  return (
    <StyledAddressAutocomplete>
      {availableAddresses.map(address => (
        <div
          className="address-option"
          key={address.text}
          onKeyDown={() => {}}
          role="presentation"
          onClick={() => selectAddress(address)}
        >
          {address.text}
        </div>
      ))}
    </StyledAddressAutocomplete>
  );
}

const StyledAddressAutocomplete = styled.div`
  background-color: white;
  position: absolute;
  border: 1px solid ${props => props.theme.outline};
  border-top: none;
  margin: -10px 10px 10px 10px;
  width: calc(100% - 16px);
  
  & .address-search {
    position: relative;
  }
  
  & .address-option {
    border-bottom: 1px solid ${props => props.theme.outline};
    height: 47px;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0 10px 0;
    font-size: 12px;
  }
  
  & .address-option:hover {
    cursor: pointer;
  }
  
  & .address-option:last-child {
    border-bottom: none;
  }
`;

AddressAutocomplete.propTypes = {
  selectAddress: PropTypes.func.isRequired,
  availableAddresses: PropTypes.arrayOf(PropTypes.object),
};

AddressAutocomplete.defaultProps = {
  availableAddresses: [],
};

export default AddressAutocomplete;
