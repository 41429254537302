import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Box, Card } from '@cfacorp/cowponents';
import { formatReadableDate } from '../../util/format';
// eslint-disable-next-line import/no-cycle
import {
  Icon, OrderSummary, DashboardActionButton,
} from '../index';

const PastOrdersCard = ({
  order,
  showReorderButton,
  handleReorderClick,
  openFavoriteModal,
}) => {
  const {
    deliveryAddress,
    lineItems,
    destination,
    promiseDateTime,
    id,
    companyName,
    favoriteOrderId,
    name,
  } = order;
  const {
    addressLine1,
    addressLine2,
    city,
    state,
  } = deliveryAddress || {};
  return (
    <StyledPastOrdersCard>
      <div className="card-header">
        <Icon
          onClick={() => openFavoriteModal(id, favoriteOrderId, name)}
          className={favoriteOrderId ? 'favorite selected' : 'favorite'}
        >
          favorite
        </Icon>
        { favoriteOrderId ? name : (
          <>
            <div>{formatReadableDate(promiseDateTime)}</div>
            <div>{destination}</div>
          </>
        )}
      </div>
      {deliveryAddress && (
        <div className="card-delivery-address">
          <Box className="title">Delivery Address:</Box>
          <Box data-cy="guestAddressName">{companyName}</Box>
          <Box data-cy="guestAddressStreet">{addressLine1}</Box>
          <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
          <Box data-cy="guestAddressCityState">{`${city}, ${state}`}</Box>
        </div>
      )}
      <OrderSummary
        className="card-order-summary"
        cartItems={lineItems}
        showPrices={false}
        showModifiers={false}
      />
      {showReorderButton()
      && (
      <div className="card-footer">
        <DashboardActionButton
          className="reorder-action"
          icon="refresh"
          onClick={handleReorderClick}
          buttonText="Re-Order"
        />
        <DashboardActionButton
          className="favorite-action"
          icon="favorite-outline"
          onClick={() => openFavoriteModal(id, favoriteOrderId, name)}
          buttonText={favoriteOrderId ? 'Edit / Remove Favorite' : 'Add to Favorites'}
        />
      </div>
      )}
    </StyledPastOrdersCard>
  );
};

const StyledPastOrdersCard = styled(Card)`
  flex: 0 0 31.9%; 
  @media(max-width: ${(props) => props.theme.medium}) {
    flex: 0 0 47.5%;
  }
  @media(max-width: ${(props) => props.theme.phone}) {
    flex: 0 0 97%;
  }
  & .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    padding: 3px 0 10px;
    font: ${(props) => props.theme.smallBoldFont};
  }
  & .card-order-summary, .card-delivery-address {
    padding: 10px;
  }
  & .card-delivery-address, .card-order-summary div {
    font: ${(props) => props.theme.smallTextFont};
    line-height: 20px;
  }
  & .card-order-summary .title, .card-delivery-address .title  {
    font: ${(props) => props.theme.smallBoldFont};
  }
  & .card-footer {
    display: flex;
    margin-top: auto;
    justify-content: center;
    background-color: ${(props) => props.theme.background};
  }
  & .card-footer button {
    margin: 10px;
  }
  & .favorite {
    width: 25px;
    height: 20px;
    margin: 0;
    fill: ${(props) => props.theme.disabled};
  }
  & .favorite:hover {
    cursor: pointer;
  }
  & .selected {
    fill: ${(props) => props.theme.error};
  }
`;

PastOrdersCard.propTypes = {
  order: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  showReorderButton: PropTypes.func.isRequired,
  handleReorderClick: PropTypes.func,
  openFavoriteModal: PropTypes.func,
};

PastOrdersCard.defaultProps = {
  handleReorderClick: () => {},
  openFavoriteModal: () => {},
};

export default PastOrdersCard;
